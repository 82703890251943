import styled from '@mui/system/styled';
import { DialogContent, Stack, Select, Box } from '@mui/material';

export const LockBadge = styled(Box)({
  background: '#B3E5FC',
  color: '#0277BD',
  height: 23,
  width: 23,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 15,
  borderRadius: 150,
  '& svg': {
    fontSize: 16,
  },
});

export const Content = styled('div')({
  background: '#fff',
  // backgroundImage:
  //   'linear-gradient(#f8f8f8 .1em, transparent .1em), linear-gradient(90deg, #f8f8f8 .1em, transparent .1em)',
  // backgroundSize: '1.5em 1.5em',
  // minHeight: '520px',
});

export const CustomDrawerInnerCnt = styled('div')({
  position: 'relative',
  height: '100%',
  padding: 16,
});
export const CustomDrawerFooter = styled('div')({
  padding: 16,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  borderTop: '1px solid #E0E0E0',
});

export const Container = styled(Stack)({});

export const ItemContainer = styled(Stack)({
  background:
    'linear-gradient(90deg, rgba(151, 150, 240, 0.24) 0%, rgba(251, 199, 212, 0.24) 100%)',
  padding: '14px',
  borderRadius: '10px',
  position: 'relative',
  '& .stepCount ': {
    display: 'flex',
    minWidth: '20px',
    maxWidth: 30,
    height: '20px',
    padding: '0px 5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4E48FA',
    fontSize: '11px',
    fontWeight: 700,
    position: 'absolute',
    background: '#EBEAFF',
    borderRadius: '50px',
    border: '2px solid #fff',
    top: '50%',
    left: 0,
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
  },
  '& .type ': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 18,
      marginRight: 5,
    },
  },
});

export const TriggerAddIcon = styled('div')({
  color: '#fff',
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
  background:
    'linear-gradient(145deg, #493FEE 0%, #6868FE 38.02%, #A9A9FF 100%)',
});

export const ActionSelect = styled(Select)({});
export const CountCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 8,
  height: '100%',
  borderRight: '1px solid #cccccc',
  '& .stepCount ': {
    display: 'flex',
    minWidth: '20px',
    height: '20px',
    padding: '0px 5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4E48FA',
    fontSize: '11px',
    fontWeight: 700,
    background: '#EBEAFF',
    borderRadius: '50px',
  },
});

export const StepCounter = styled('span')({
  display: 'inline-flex',
  width: '20px',
  height: '20px',
  padding: '0px 7px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#4E48FA',
  fontSize: '11px',
  fontWeight: 700,
  background: '#CCCBFF',
  borderRadius: '50%',
});
