import { FC, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { Chip, Typography } from '@mui/material';
import { colors } from 'components/theme/constants';

import NumberTypePreview from 'components/common/jsxrender/checklistV2/builder/automations/numberTypePreview';
import LocationTypePreview from 'components/common/jsxrender/checklistV2/builder/automations/locationTypePreview';
import moment from 'moment';

interface IProps {
  type?: string;
  automation: any;
  field: any;
  checkListItems: any;
  onEdit(): void;
  fetchAndSetChecklistAutomations: () => void;
}

export const getConditionText = (condition) => {
  switch (condition) {
    case 'eq':
      return 'is equal to';
    case 'not':
      return 'is not equal to';
    case 'gt':
      return 'is greater than';
    case 'lt':
      return 'is less than';
    case 'gte':
      return 'is greater than or equal to';
    case 'lte':
      return 'is less than or equal to';
    case 'btw':
      return 'is between';
    case 'nbtw':
      return 'is not between';
  }
};
export const getAnswerView = (field, condition) => {
  switch (field?.type) {
    case 'multipleChoice':
    case 'dropdown': {
      const answerOption = field?.options?.find(
        (op) => op.id === condition?.value,
      );

      return answerOption ? (
        <Chip
          size="small"
          variant={field.type === 'multipleChoice' ? 'outlined' : 'filled'}
          label={answerOption.label}
          sx={{
            color:
              field.type === 'multipleChoice' ? answerOption.color : '#fff',
            borderColor: answerOption.color,
            backgroundColor:
              field.type === 'multipleChoice' ? '#fff' : answerOption.color,
            p: '0px',
          }}
        />
      ) : null;
    }
    case 'passFail': {
      const answerOption = field.options.find(
        (op) => op.value === condition?.value,
      );

      return answerOption ? (
        <Chip
          size="small"
          variant="outlined"
          label={answerOption.label}
          color={answerOption.type}
          sx={{
            p: '0px',
            '& .MuiChip-label': {
              p: '0px 10px',
              fontWeight: 500,
              fontSize: '13px',
            },
          }}
        />
      ) : null;
    }
    case 'textField': {
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={600}
        >
          {condition?.value}
        </Typography>
      );
    }

    case 'number':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'temperature':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'cost':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'procedure':
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={600}
        >
          Checked
        </Typography>
      );

    case 'dateTime':
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={700}
        >
          {moment(condition?.value).format(
            field.unit === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY - hh:mm A',
          )}
        </Typography>
      );

    case 'location':
      return <LocationTypePreview condition={condition} />;
  }
};
export const getAnswerText = (field, condition) => {
  switch (field?.type) {
    case 'multipleChoice':
    case 'dropdown': {
      const answerOption = field?.options?.find(
        (op) => op.id === condition?.value,
      );
      return answerOption ? answerOption.label : '';
    }
    case 'passFail': {
      const answerOption = field.options.find(
        (op) => op.value === condition?.value,
      );
      return answerOption ? answerOption.label : '';
    }
    case 'textField': {
      return condition?.value || '';
    }
    case 'number':
    case 'temperature':
    case 'cost': {
      // Assuming NumberTypePreview returns a number or formatted string
      return condition?.value?.toString() || '';
    }
    case 'procedure': {
      return 'Checked';
    }
    case 'dateTime': {
      return moment(condition?.value).format(
        field.unit === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY - hh:mm A',
      );
    }
    case 'location': {
      // Assuming LocationTypePreview returns a string or has a method to get text
      return condition?.value || '';
    }
    default:
      return '';
  }
};

export const getActionText = (action) => {
  const obj = {
    SHOW: 'Show',
    HIDE: 'Hide',
    showMultiple: 'Show Multiple',
    hideMultiple: 'Hide Multiple',
  };
  return obj[action] || action;
};
const ConditionsPreview: FC<IProps> = (props) => {
  const { automation, type = 'Step', checkListItems, onEdit } = props;

  const conditionField = useMemo(() => {
    return checkListItems[automation.contextId];
  }, []);
  return (
    <Stack
      direction="row"
      alignItems="center"
      borderRadius="5px"
      paddingY="2px"
      paddingX="2px"
      spacing={1}
      border="1px solid transparent"
      sx={{
        cursor: 'pointer',
        '&:hover': {
          borderColor: colors.primary.main,
        },
      }}
      onClick={onEdit}
    >
      <Typography fontWeight={600} fontSize="12px" color="#424242">
        {getActionText(automation.action)} this {type} If{' '}
        <span style={{ color: '#4E48FA', fontWeight: 700 }}>
          "{conditionField.description}"
        </span>{' '}
        {getConditionText(automation?.condition?.logic)}
      </Typography>
      {getAnswerView(conditionField, automation.condition)}{' '}
    </Stack>
  );
};

export default ConditionsPreview;
