import { createSvgIcon } from '@mui/material';

const SectionIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12.125 11.75H3.875M12.125 8.75H3.875M1.25 5.75H14.75M4.85 1.25H11.15C12.4101 1.25 13.0402 1.25 13.5215 1.49524C13.9448 1.71095 14.289 2.05516 14.5048 2.47852C14.75 2.95982 14.75 3.58988 14.75 4.85V11.15C14.75 12.4101 14.75 13.0402 14.5048 13.5215C14.289 13.9448 13.9448 14.289 13.5215 14.5048C13.0402 14.75 12.4101 14.75 11.15 14.75H4.85C3.58988 14.75 2.95982 14.75 2.47852 14.5048C2.05516 14.289 1.71095 13.9448 1.49524 13.5215C1.25 13.0402 1.25 12.4101 1.25 11.15V4.85C1.25 3.58988 1.25 2.95982 1.49524 2.47852C1.71095 2.05516 2.05516 1.71095 2.47852 1.49524C2.95982 1.25 3.58988 1.25 4.85 1.25Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SectionIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default SectionIcon;
