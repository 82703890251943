// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0px',
  overflowY: 'auto',
  flex: 1,
  [theme.breakpoints.down('md')]: {
    background: '#F2F5F9',
    margin: '0 -16px',
    padding: '8px 16px',
    minHeight: 'calc(100vh - 168px)',
  },
}));

export const TabsWrapper = styled(Stack)({
  gap: 2,
  marginBottom: 5,
  padding: '5px 0px 0px 0px',
});

export const MainContainer = styled(Box)({
  backgroundColor: '#F6F8FA',
  '& .innerContainer': {
    backgroundColor: '#FFFFFF',
    margin: '100px auto',
    width: 1200,
    minHeight: 1000,

    padding: 16,
    borderRadius: 16,
  },
  '& .header': {
    marginBottom: 50,
  },
});

export const TabContainer = styled(CustomButton)({
  borderRadius: 0,
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(33, 33, 33, 1)',
});
