import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)({
  backgroundColor: '#EBEAFF',
  border: '1px solid #CCCBFF',
  padding: '16px 20px',
  boxShadow: '0px 2px 4px 0px #0000000F',
  borderRadius: 8,
  gap: 12,
  display: 'grid',
  '& .selectionBoxCnt': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiSwitch-track': {
      backgroundColor: '#B0BEC5',
    },

    '& .title': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '21.86px',
      color: '#000000',
      marginBottom: 2,
    },

    '& .titleInfo': {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17.76px',
      color: '#212121',
    },
  },
  '& .selectBox': {
    borderRadius: 12,
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 4px 0px #0000000A',
    padding: '14px 16px',
    display: 'flex',
    alignItems: 'center',

    '& .iconCnt': {
      height: 36,
      width: 36,
      borderRadius: '10px',
      backgroundColor: '#FFEBEE',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        fill: '#EF5350',
        path: {
          stroke: '#EF5350',
        },
      },
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '19.12px',
      color: '#000000',
    },
    '& .envelopIcon': {
      fontSize: 17,
    },
  },
});
