import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  sx?: any;
}

const CustomDashboardIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M20.1667 9.99183V3.7585C20.1667 2.3835 19.58 1.8335 18.1225 1.8335H14.4192C12.9617 1.8335 12.375 2.3835 12.375 3.7585V9.99183C12.375 11.3668 12.9617 11.9168 14.4192 11.9168H18.1225C19.58 11.9168 20.1667 11.3668 20.1667 9.99183Z"
        stroke="#424242"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1667 18.2415V16.5915C20.1667 15.2165 19.58 14.6665 18.1225 14.6665H14.4192C12.9617 14.6665 12.375 15.2165 12.375 16.5915V18.2415C12.375 19.6165 12.9617 20.1665 14.4192 20.1665H18.1225C19.58 20.1665 20.1667 19.6165 20.1667 18.2415Z"
        stroke="#424242"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62504 12.0085V18.2418C9.62504 19.6168 9.03837 20.1668 7.58087 20.1668H3.87754C2.42004 20.1668 1.83337 19.6168 1.83337 18.2418V12.0085C1.83337 10.6335 2.42004 10.0835 3.87754 10.0835H7.58087C9.03837 10.0835 9.62504 10.6335 9.62504 12.0085Z"
        stroke="#424242"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62504 3.7585V5.4085C9.62504 6.7835 9.03837 7.3335 7.58087 7.3335H3.87754C2.42004 7.3335 1.83337 6.7835 1.83337 5.4085V3.7585C1.83337 2.3835 2.42004 1.8335 3.87754 1.8335H7.58087C9.03837 1.8335 9.62504 2.3835 9.62504 3.7585Z"
        stroke="#424242"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CustomDashboardIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M20.1667 9.99183V3.7585C20.1667 2.3835 19.58 1.8335 18.1225 1.8335H14.4192C12.9617 1.8335 12.375 2.3835 12.375 3.7585V9.99183C12.375 11.3668 12.9617 11.9168 14.4192 11.9168H18.1225C19.58 11.9168 20.1667 11.3668 20.1667 9.99183Z"
        stroke="#4E48FA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1667 18.2415V16.5915C20.1667 15.2165 19.58 14.6665 18.1225 14.6665H14.4192C12.9617 14.6665 12.375 15.2165 12.375 16.5915V18.2415C12.375 19.6165 12.9617 20.1665 14.4192 20.1665H18.1225C19.58 20.1665 20.1667 19.6165 20.1667 18.2415Z"
        stroke="#4E48FA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62504 12.0085V18.2418C9.62504 19.6168 9.03837 20.1668 7.58087 20.1668H3.87754C2.42004 20.1668 1.83337 19.6168 1.83337 18.2418V12.0085C1.83337 10.6335 2.42004 10.0835 3.87754 10.0835H7.58087C9.03837 10.0835 9.62504 10.6335 9.62504 12.0085Z"
        stroke="#4E48FA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62504 3.7585V5.4085C9.62504 6.7835 9.03837 7.3335 7.58087 7.3335H3.87754C2.42004 7.3335 1.83337 6.7835 1.83337 5.4085V3.7585C1.83337 2.3835 2.42004 1.8335 3.87754 1.8335H7.58087C9.03837 1.8335 9.62504 2.3835 9.62504 3.7585Z"
        stroke="#4E48FA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CustomDashboardIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  );
};
export default CustomDashboardIcon;
