// React
import { useContext, useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

// Utils
import xeniaApi from 'api/index';

// Context
import { SettingsContext } from 'components/TemplateDashboard/TemplateSettingsModal/context';

// Constants
import DIALOGS from 'utils/dialogIds';

interface PropTypes {
  updateTemplateCb?: any;
}

const usePatchTemplate = ({ updateTemplateCb }: PropTypes) => {
  const ctx = useContext(SettingsContext);
  const dispatch = useDispatch();
  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const rolesHash = useSelector(selectors.getRoles)?.hashRoles;
  const modalConfig = useSelector(selectors.getDialogState);
  const config = modalConfig?.[DIALOGS.TEMPLATE_SETTINGS];

  const template = ctx?.template as TemplateType;

  const getFileName = (url: string) => {
    const splits = url.split('/');
    return splits[splits.length - 1];
  };

  const getAccessorWithType = (accessor) => {
    const accessorId = accessor.AccessorId;
    const isUser = usersHash?.[accessorId];
    const isTeam = teamsHash?.[accessorId];
    const isLocation = locationsHash?.[accessorId];
    const isRole = rolesHash?.[accessorId];
    return isUser
      ? { ...accessor, ...isUser, type: 'user' }
      : isTeam
      ? { ...accessor, ...isTeam, type: 'team' }
      : isLocation
      ? { ...accessor, ...isLocation, type: 'location' }
      : isRole
      ? { ...accessor, ...isRole, type: 'role' }
      : {};
  };

  const templateAdmins = useMemo(() => {
    const admins = template?.ChecklistAccess?.filter(
      (t) => t.role === 'admin',
    ).map((a) => {
      return getAccessorWithType(a);
    });
    return admins?.length ? admins : ['all'];
  }, [template]);

  const templateSubmitters = useMemo(() => {
    const submitters = template?.ChecklistAccess?.filter(
      (t) => t.role === 'submitter',
    ).map((a) => {
      return getAccessorWithType(a);
    });
    return submitters?.length ? submitters : ['all'];
  }, [template]);

  const handleUploadToAws = async (logo: File[]) => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(logo);
    return response.filePath.map((path) => `${response.baseUrl}${path}`);
  };

  const callApi = async (data) => {
    const response = await xeniaApi.patchChecklist({
      checklistId: template.id,
      data,
    });
    if (response) {
      updateTemplateCb && updateTemplateCb(response.data);
      ctx?.handleOverrideTemplate && ctx?.handleOverrideTemplate(response.data);
      return response;
    }
    return null;
  };

  const handleSaveName = async (name: string) => {
    if (name !== template?.name) {
      await callApi({
        name,
      });
    }
  };

  const handleSaveIcon = async (icon) => {
    await callApi({
      icon,
    });
  };

  const toggleShowTimestamp = async (isShowTimestamp: boolean) => {
    await callApi({
      isShowTimestamp,
    });
  };
  const toggleShowStepAction = async (
    isShowCorrectiveActionInSteps: boolean,
  ) => {
    await callApi({
      isShowCorrectiveActionInSteps,
    });
  };
  const toggleAllowOnlyBluetoothTemperatureResponses = async (
    isAllowOnlyBluetoothTemperatureResponses: boolean,
  ) => {
    await callApi({
      isAllowOnlyBluetoothTemperatureResponses,
    });
  };

  const toggleScoring = async (isScoring: boolean) => {
    await callApi({
      isScoring,
      ...(!isScoring && {
        isShowScoreInProgress: false,
        isShowScoreInSteps: false,
      }),
    });
  };

  const toggleShowScoreInProgress = async (isShowScoreInProgress: boolean) => {
    await callApi({
      isShowScoreInProgress,
    });
  };

  const toggleShowScoreInSteps = async (isShowScoreInSteps: boolean) => {
    await callApi({
      isShowScoreInSteps,
    });
  };

  const uploadLogo = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const urls = await handleUploadToAws(acceptedFiles);
      await callApi({
        logo: urls[0],
      });
      return;
    }
    await callApi({
      logo: '',
    });
  };

  const uploadAttachments = async (acceptedFiles: File[]) => {
    const urls = await handleUploadToAws(acceptedFiles);
    await callApi({
      attachments: [...template.attachments, ...urls],
    });
  };

  const deleteAttachment = async (url: string) => {
    await callApi({
      attachments: template.attachments.filter((u) => u !== url),
    });
  };

  const handleSaveAdmins = async (admins: string[]) => {
    const prevAccess = template?.ChecklistAccess ?? [];
    const newChecklistAccess = [
      ...(template?.ChecklistAccess?.filter((t) => t.role === 'submitter').map(
        (s) => ({
          role: 'submitter',
          AccessorId: s.AccessorId,
        }),
      ) ?? []),
      ...admins.map((a) => ({ AccessorId: a, role: 'admin' })),
    ];
    ctx?.handleUpdateTemplate('ChecklistAccess', newChecklistAccess);
    const result = await callApi({
      access: newChecklistAccess,
    });
    if (!result) {
      ctx?.handleUpdateTemplate('ChecklistAccess', prevAccess);
    }
  };

  const handleSaveSubmitters = async (submitters: string[]) => {
    const prevAccess = template?.ChecklistAccess ?? [];
    const newChecklistAccess = [
      ...(template?.ChecklistAccess?.filter((t) => t.role === 'admin').map(
        (a) => ({ AccessorId: a.AccessorId, role: 'admin' }),
      ) ?? []),
      ...submitters.map((a) => ({ AccessorId: a, role: 'submitter' })),
    ];
    ctx?.handleUpdateTemplate('ChecklistAccess', newChecklistAccess);
    const result = await callApi({
      access: newChecklistAccess,
    });
    if (!result) {
      ctx?.handleUpdateTemplate('ChecklistAccess', prevAccess);
    }
  };

  const handleTogglePublicShare = async ({
    isPubliclyAccessible = false,
    isNameAndEmailRequired = false,
  }) => {
    const result = await callApi({
      isPubliclyAccessible,
      isNameAndEmailRequired,
    });
    return result;
  };

  const handleToggleQRCode = async ({ isQREnable = false }) => {
    const result = await callApi({
      isQREnable,
    });
    return result;
  };

  return {
    templateAdmins,
    templateSubmitters,
    getFileName,
    uploadLogo,
    handleSaveName,
    handleSaveIcon,
    uploadAttachments,
    handleSaveAdmins,
    handleSaveSubmitters,
    deleteAttachment,
    handleTogglePublicShare,
    handleToggleQRCode,
    toggleShowTimestamp,
    toggleShowStepAction,
    toggleAllowOnlyBluetoothTemperatureResponses,
    toggleScoring,
    toggleShowScoreInProgress,
    toggleShowScoreInSteps,
  };
};

export default usePatchTemplate;
