// Custom components
import { MemoizedAssigneeDropdownCmp } from 'pages/task/TaskListView/AssigneeDropdownCmp';
import CommentAction from 'components/Tasks/TasksList/CellRenderer/CommentsRenderer';
import PriorityDropdown from 'components/Dropdown/Task/Priority/TaskPriorityDropdown';
import CategoryDropdown from 'components/Dropdown/Task/Category/TaskCategoryDropdown';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import AssetsDropdown from 'components/Dropdown/Task/Asset/TaskAssetDropdown';
import StatusDropdown from 'components/Dropdown/Task/Status/TaskStatusDropdown';
import EditableDateCell from 'pages/task/TaskListView/EditableDateCell';
import DateRenderer from 'pages/task/TaskListView/DateRenderer';
import TaskActionDropdown from 'pages/task/TaskListView/TaskActionDropdown/TaskActionDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import CTypography from 'components/CTypography';

// Utilities
import {
  ActionsCellCnt,
  CellContentWrapper,
  CheckListCnt,
  PlannedTaskIdChip,
  ScheduleCnt,
} from 'pages/task/TaskListView/taskListView.style';
import {
  DottedContainer,
  DottedText,
} from 'components/Dropdown/Task/Location/taskLocationDropdown.style';
import {
  priorityDropdownData,
  statusDropdownData,
} from 'helper/task/dropdownData';
import RecurringIcon from 'components/Icons/recurringIcon';
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import Overdue from 'components/Tasks/TasksList/CellRenderer/OverdueRenderer';
import moment from 'moment-timezone';
import { TASK_STATUS } from 'utils/globalVariable';
import { getTimeZone } from 'utils/globalFunction';
import TimeTracking from 'components/Tasks/TasksList/CellRenderer/TimeTrackingRenderer';
import PhotosAttachment from 'components/Tasks/TasksList/CellRenderer/PhotoRenderer';
import AvatarPopover from 'components/AvatarPopover';
import TaskTemplateTile from 'components/Dropdown/Task/TaskTemplate';

const scheduleList = [...weeks, ...months];

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};
export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskListGrid?.columnApi?.getRowGroupColumns()?.[0]?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};
export const customIcons = {
  rowDrag:
    '<svg fill="rgba(0,0,0,0.36)" width="15px" height="15px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">\n' +
    '    <path d="M600 1440c132.36 0 240 107.64 240 240s-107.64 240-240 240-240-107.64-240-240 107.64-240 240-240Zm720 0c132.36 0 240 107.64 240 240s-107.64 240-240 240-240-107.64-240-240 107.64-240 240-240ZM600 720c132.36 0 240 107.64 240 240s-107.64 240-240 240-240-107.64-240-240 107.64-240 240-240Zm720 0c132.36 0 240 107.64 240 240s-107.64 240-240 240-240-107.64-240-240 107.64-240 240-240ZM600 0c132.36 0 240 107.64 240 240S732.36 480 600 480 360 372.36 360 240 467.64 0 600 0Zm720 0c132.36 0 240 107.64 240 240s-107.64 240-240 240-240-107.64-240-240S1187.64 0 1320 0Z" fill-rule="evenodd"/>\n' +
    '</svg>',
};
export const addTaskWithStatusRow = [
  {
    createData: {
      taskStatus: 'Open',
    },
    taskStatus: 'Open',
    id: 0,
    addTask: true,
    fullWidth: true,
  },
  {
    createData: {
      taskStatus: 'Missed',
    },
    taskStatus: 'Missed',
    id: 1,
    addTask: true,
    fullWidth: true,
  },
  {
    createData: {
      taskStatus: 'In Progress',
    },
    taskStatus: 'In Progress',
    id: 2,
    addTask: true,
    fullWidth: true,
  },
  {
    createData: {
      taskStatus: 'On Hold',
    },
    taskStatus: 'On Hold',
    id: 3,
    addTask: true,
    fullWidth: true,
  },
  {
    createData: {
      taskStatus: 'Completed',
    },
    taskStatus: 'Completed',
    id: 4,
    addTask: true,
    fullWidth: true,
  },
];
export const addTaskWithAssigneeRow = () => {
  const uniqueAssignees: any = [];
  globalThis?.taskListGrid?.api?.forEachNodeAfterFilter((t, i) => {
    if (t?.addTask) return;
    if (
      uniqueAssignees?.findIndex((a) => a?.AssignId == t?.data?.AssignId) < 0
    ) {
      uniqueAssignees.push({
        createData: {
          AssignId: t?.data?.AssignId ? t?.data?.AssignId : null,
        },
        AssignId: t?.data?.AssignId ? t?.data?.AssignId : null,
        Assigned: t?.data?.Assigned ? t?.data?.Assigned : null,
        Team: t?.data?.Team ? t?.data?.Team : null,
        id: t?.data?.AssignId || 0,
        addTask: true,
        fullWidth: true,
      });
    }
  });
  return uniqueAssignees;
};
export const cellRenderer = {
  id: (params) => {
    if (!params?.data) return;
    const { taskNumber } = params.data;
    return taskNumber ? (
      <span className="taskTitle">{taskNumber}</span>
    ) : (
      <PlannedTaskIdChip>Planned</PlannedTaskIdChip>
    );
  },
  timeTracking: (params) => {
    const { timeSpent } = params.data;
    if (!timeSpent) return null;
    return <TimeTracking timeSpent={timeSpent} />;
  },
  attachment: (params) => {
    return (
      <CellContentWrapper data-attribute="cell-click" style={{ width: '100%' }}>
        <PhotosAttachment task={params.data} />
      </CellContentWrapper>
    );
  },
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return (
      <CustomToolTip
        PopperProps={{
          sx: {
            pointerEvents: 'none',
            zIndex: 1300,
            '& .MuiTooltip-tooltip': {
              boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
              minWidth: 100,
              maxHeight: 300,
              overflowY: 'auto',
              borderRadius: '15px',
            },
          },
        }}
        title={
          <DottedContainer>
            <DottedText>{title}</DottedText>
          </DottedContainer>
        }
      >
        <span style={{ fontWeight: 600 }} className="boldTextOnHover">
          {title}
        </span>
      </CustomToolTip>
    );
  },
  project: (params) => {
    if (!params?.data) return;
    const { project } = params.data;
    return <span className="taskTitle">{project?.title || '-'}</span>;
  },
  scheduleName: (params) => {
    if (!params?.data) return;
    const { schedule } = params.data;
    return <span className="taskTitle">{schedule?.title || '-'}</span>;
  },
  description: (params) => {
    if (!params?.data) return;
    const { description } = params.data;
    return <span className="taskTitle">{description}</span>;
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery, intervalWeek } = params.data;
    let schedule = scheduleList?.find(
      (item) => item.id === recurringByEvery,
    )?.displayTitle;
    if (recurringByEvery === 'Week1' && intervalWeek?.length === 7) {
      schedule = 'Daily';
    }
    return schedule ? (
      <ScheduleCnt>
        <div className="innerCnt">
          <RecurringIcon />
          <CTypography>{schedule}</CTypography>
        </div>
      </ScheduleCnt>
    ) : (
      '-'
    );
  },
  templateAttached: (params, props) => {
    if (!params?.data) return;
    const { attachedCheckList } = params.data;
    const { icon, name } = attachedCheckList ?? {};
    const { onRowClick } = props;

    return (
      <CheckListCnt data-attribute="cell-click">
        {icon && name ? (
          <div
            className="templateAttached"
            onClick={() => {
              onRowClick(params?.data, 'checklist');
            }}
          >
            <ChecklistIcon icon={icon} style={{ minWidth: 24 }} />
            <CTypography>{name}</CTypography>
          </div>
        ) : (
          <span>-</span>
        )}
      </CheckListCnt>
    );
  },
  category: (params) => {
    if (!params?.data) return;
    const { ServiceId, ServiceType } = params.data;

    return (
      <CellContentWrapper data-attribute="cell-click">
        <CategoryDropdown
          selected={ServiceId || ServiceType?.id}
          taskData={params.data}
          viewType="tableView"
          editable
        />
      </CellContentWrapper>
    );
  },
  location: (params) => {
    if (!params?.data) return;
    const { Location } = params.data;

    return (
      <CellContentWrapper data-attribute="cell-click">
        <LocationsDropdown
          selected={Location?.id}
          taskData={params.data}
          viewType="tableView"
          editable
          isMulti={false}
        />
      </CellContentWrapper>
    );
  },
  asset: (params) => {
    if (!params?.data) return;
    const { Asset, Location } = params.data;

    return (
      <CellContentWrapper data-attribute="cell-click">
        <AssetsDropdown
          selected={Asset?.id}
          taskData={params.data}
          viewType="tableView"
          editable
          isMulti={false}
          location={Location?.id}
        />
      </CellContentWrapper>
    );
  },
  assignee: (row) => {
    if (!row?.data) return;
    return <MemoizedAssigneeDropdownCmp task={row.data} />;
  },
  status: (params, props) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }
    if (!params?.data) return;
    const { taskStatus } = params.data;
    const selectedStatus = statusDropdownData.find(
      (s) => s.label === taskStatus,
    );
    return (
      <div data-attribute="cell-click">
        <StatusDropdown
          viewType="tableView"
          selected={[selectedStatus]}
          task={params.data}
          filters={props?.filters}
          editable
          readOnly={taskStatus === 'Missed'}
        />
      </div>
    );
  },
  taskTemplate: (params) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }
    if (!params?.data) return;
    const { TaskTemplate } = params.data;
    return (
      <CellContentWrapper data-attribute="cell-click">
        <TaskTemplateTile taskTemplate={TaskTemplate} />
      </CellContentWrapper>
    );
  },

  priority: (params) => {
    if (!params?.data) return;
    const { priority } = params.data;
    const selectedPriority = priorityDropdownData.find(
      (s) => s.label === priority,
    );
    return (
      <PriorityDropdown
        viewType="tableView"
        selected={[selectedPriority]}
        task={params.data}
        editable
      />
    );
  },
  createdAt: (params) => {
    if (!params?.data) return;
    return <DateRenderer date={params?.data?.createdAt} />;
  },
  createdBy: (row) => {
    if (!row?.data) return;
    return row?.data?.Creator ? (
      <AvatarPopover data={row?.data?.Creator} />
    ) : null;
  },
  startDate: (params) => {
    if (!params?.data) return;
    return <EditableDateCell task={params?.data} dateKey={'startTime'} />;
  },
  dueDate: (params) => {
    if (!params?.data) return;
    return params?.data?.isOverDue && params?.data?.dueDate ? (
      <Overdue
        isMissed={params?.data?.taskStatus === 'Missed'}
        date={params?.data?.dueDate}
      />
    ) : (
      <EditableDateCell task={params?.data} dateKey={'dueTime'} />
    );
  },
  completedOn: (params) => {
    if (!params?.data) return;
    return <DateRenderer date={params?.data?.endTime} />;
  },
  completedBy: (row) => {
    if (!row?.data) return;
    return row?.node?.data?.taskStatus === 'Completed' &&
      row?.data?.Finisher ? (
      <AvatarPopover data={row?.data?.Finisher} />
    ) : null;
  },

  actionColumn: (params, props) => {
    if (!params?.data) return;
    const unreadCommentsCount = params?.data?.commentsCount?.unreadCount;
    const unreadCommentsCountValue =
      unreadCommentsCount > 9 ? '9+' : unreadCommentsCount;
    const task = params.data;
    const { onRowClick, taskActionDropdownProps } = props;

    return (
      <ActionsCellCnt data-attribute={'cell-click'}>
        <CustomToolTip title={'Comments'}>
          <span
            onClick={(e) => {
              onRowClick(params?.data?.id, 'comments');
            }}
          >
            {unreadCommentsCountValue ? (
              <CommentAction
                variant="unread"
                count={unreadCommentsCountValue}
              />
            ) : !params?.data?.commentsCount?.messages?.length ? (
              <CommentAction variant="add" />
            ) : (
              <CommentAction
                variant="read"
                count={params?.data?.commentsCount?.messages?.length || 0}
              />
            )}
          </span>
        </CustomToolTip>
        {task.taskStatus !== TASK_STATUS.Missed && (
          <span style={{ marginLeft: 15 }}>
            <TaskActionDropdown
              task={params?.data}
              {...taskActionDropdownProps}
            />
          </span>
        )}
      </ActionsCellCnt>
    );
  },
};
export const isRowGroupingEnabled = (taskColumns: any = null) => {
  const columnState = taskColumns?.length
    ? taskColumns
    : globalThis?.requestsListGrid?.columnApi?.getColumnState();
  // Check if row grouping is enabled on any column
  const isRowGroupingEnabled = columnState?.some((s) => s.rowGroup);

  return isRowGroupingEnabled;
};
export const groupByColumn = (taskColumns: any = null) => {
  const columnState = taskColumns?.length
    ? taskColumns
    : globalThis?.requestsListGrid?.columnApi?.getColumnState();
  // Check if row grouping is enabled on any column
  return columnState?.find((s) => s.rowGroup);
};
const rowDrag = function (params) {
  return !params.node.group;
};
//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  location: (params) => {
    if (!params.data) return null;
    const { Location } = params.data;
    if (!params?.node?.group && !Location) {
      return 'No Location';
    }
    const locationName = Location?.name || '-';
    return locationName;
  },
  project: (params) => {
    if (!params?.data) return;
    const { project } = params.data;
    return project?.title;
  },
  scheduleName: (params) => {
    if (!params?.data) return;
    const { schedule } = params.data;
    return schedule?.title;
  },
  attachment: (params) => {
    if (!params?.data) return;
    return params?.data?.attachment?.filter((url) =>
      /\.(jpe?g|png|gif)$/i.test(url),
    )?.length;
  },
  asset: (params) => {
    if (!params.data) return null;
    const { Asset } = params.data;
    if (!params?.node?.group && !Asset) {
      return 'No Asset';
    }
    const assetName = Asset?.name || '-';
    return assetName;
  },
  timeTracking: (params) => {
    if (!params?.data) return;
    const { timeSpent } = params.data;
    return timeSpent;
  },
  completedOn: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { endTime } = params.data;
    return endTime ? moment.tz(endTime, tz).format('MMM DD YYYY hh:mm a') : '';
  },
  createdAt: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { createdAt } = params.data;
    return moment.tz(createdAt, tz).format('MMM DD YYYY hh:mm a');
  },
  createdBy: (params) => {
    if (!params.data) return null;
    const { Creator } = params.data;
    return Creator?.fullName || '';
  },
  startDate: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { startTime } = params.data;
    const date = startTime;
    return startTime ? moment.tz(date, tz).format('MMM DD YYYY hh:mm a') : '';
  },
  dueDate: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { dueTime, isOverDue } = params.data;
    const overDue = isOverDue ? 'Overdue' : '';
    const date = dueTime || '';
    return isOverDue
      ? overDue
      : dueTime
      ? moment.tz(date, tz).format('MMM DD YYYY hh:mm a')
      : '';
  },
  cost: (params, currency) => {
    const cost = params?.data?.Cost;
    return cost ? `${currency?.symbol}${cost}` : '-';
  },
  assignee: (params) => {
    if (!params.data) return null;
    const { TaskAssignees } = params.data;
    if (
      !params?.node?.group &&
      (!TaskAssignees || TaskAssignees?.length === 0)
    ) {
      return 'Unassigned';
    }

    const names =
      TaskAssignees?.map((item) => {
        if (item?.Assigned) {
          return item?.Assigned?.firstName;
        }
        if (item?.Team) {
          return item?.Team?.name;
        }
        if (item?.Location) {
          return item?.Location?.name;
        }
        if (item?.Role) {
          return item?.Role?.id;
        }
        return '';
      }) ?? [];

    return names?.join(', ');
  },
  category: (params, props) => {
    if (!params.data) return null;
    const { ServiceId } = params.data;
    const { categoryList } = props;
    const selectedCategory = categoryList?.find((c) => c?.id == ServiceId);
    return selectedCategory?.type;
  },
  completedBy: (params) => {
    if (!params.data) return null;
    const { Finisher } = params.data;
    return Finisher?.fullName || '';
  },
};
export const columns: any = (props) => {
  const taskColumns = props?.taskColumns ?? null;
  return [
    {
      field: '',
      colId: 'task_checkbox',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: rowDrag,
      rowDragText: (params, dragItemCount) => {
        const suffix = params?.rowNode?.data?.title;
        return suffix;
      },
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 80,
      maxWidth: 80,
      resizable: false,
      lockPosition: true,
      cellStyle: { paddingLeft: 20, paddingRight: 20 },
    },
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      comparator: comparator.stringCompare,
      cellRenderer: cellRenderer.title,
      pinned: 'left',
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      colId: 'assigned_to',
      comparator: comparator.assignee,
      valueGetter: valueGetters.assignee,
      sortable:
        !isRowGroupingEnabled(taskColumns) ||
        groupByColumn(taskColumns)?.colId != 'assigned_to',
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location',
      comparator: comparator.stringCompare,
      cellRenderer: cellRenderer.location,
      valueGetter: valueGetters.location,
      sortable:
        !isRowGroupingEnabled(taskColumns) ||
        groupByColumn(taskColumns)?.colId != 'location',
    },
    {
      field: 'taskStatus',
      headerName: 'Status',
      colId: 'status',
      sortable:
        !isRowGroupingEnabled(taskColumns) ||
        groupByColumn(taskColumns)?.colId != 'status',
      rowGroup: true,
      comparator: comparator.status,
      cellRenderer: (params) => cellRenderer.status(params, props),
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
    },
    {
      field: 'dueTime',
      headerName: 'Due On',
      comparator: comparator.date,
      colId: 'end_date',
      sortable: true,
      valueGetter: valueGetters.dueDate,
      cellRenderer: cellRenderer.dueDate,
    },
    {
      field: 'endTime',
      headerName: 'Completed On',
      comparator: comparator.date,
      colId: 'completed_date',
      sortable: true,
      cellRenderer: cellRenderer.completedOn,
      valueGetter: (params) => valueGetters.completedOn(params),
    },
    {
      field: 'CompletedBy',
      headerName: 'Completed By',
      colId: 'completed_by',
      comparator: comparator.assignee,
      valueGetter: valueGetters.completedBy,
      sortable: true,
      cellRenderer: cellRenderer.completedBy,
    },
    {
      field: 'recurringByEvery',
      headerName: 'Schedule Frequency',
      colId: 'schedule frequency',
      comparator: comparator.stringCompare,
      sortable: true,
      cellRenderer: cellRenderer.schedule,
    },
    {
      field: 'attachedCheckList.name',
      headerName: 'Template Attached',
      colId: 'template_attached',
      sortable: true,
      comparator: comparator.stringCompare,
      cellRenderer: (params) => cellRenderer.templateAttached(params, props),
    },
    {
      field: 'asset',
      headerName: 'Asset',
      colId: 'asset',
      comparator: comparator.stringCompare,
      cellRenderer: cellRenderer.asset,
      valueGetter: valueGetters.asset,
      sortable:
        !isRowGroupingEnabled(taskColumns) ||
        groupByColumn(taskColumns)?.colId != 'asset',
    },
    {
      field: 'priority',
      headerName: 'Priority',
      colId: 'priority',
      sortable:
        !isRowGroupingEnabled(taskColumns) ||
        groupByColumn(taskColumns)?.colId != 'priority',
      comparator: comparator.priority,
      cellRenderer: cellRenderer.priority,
    },
    {
      field: 'attachment',
      headerName: 'Photos/Attachments',
      colId: 'attachment',
      sortable: false,
      valueGetter: (params) => valueGetters.attachment(params),
      cellRenderer: (params) => cellRenderer.attachment(params),
    },
    {
      field: 'TaskTemplate.templateTitle',
      headerName: 'Task Template',
      colId: 'taskTemplate',
      sortable: true,
      cellRenderer: cellRenderer.taskTemplate,
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      colId: 'created_at',
      comparator: comparator.date,
      sortable: true,
      cellRenderer: cellRenderer.createdAt,
      valueGetter: (params) => valueGetters.createdAt(params),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      colId: 'created_by',
      comparator: comparator.assignee,
      sortable: true,
      cellRenderer: cellRenderer.createdBy,
      valueGetter: (params) => valueGetters.createdBy(params),
    },
    {
      field: 'ServiceId',
      headerName: 'Category',
      colId: 'category',
      cellRenderer: cellRenderer.category,
      comparator: comparator.stringCompare,
      sortable: true,
      processCellCallback: (params) => valueGetters.category(params, props),
      valueGetter: (params) => valueGetters.category(params, props),
    },
    {
      field: 'Cost',
      headerName: 'Cost',
      colId: 'cost',
      sortable: false,
      valueGetter: (params) => valueGetters.cost(params, props?.currency),
    },
    {
      field: 'timeSpent',
      headerName: 'Time Tracked',
      colId: 'timeSpent',
      sortable: true,
      comparator: comparator.stringCompare,
      valueGetter: valueGetters.timeTracking,
      cellRenderer: cellRenderer.timeTracking,
    },
    {
      field: 'project',
      headerName: 'Project',
      colId: 'project',
      sortable: true,
      valueGetter: valueGetters.project,
      cellRenderer: cellRenderer.project,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      valueGetter: valueGetters.scheduleName,
      cellRenderer: cellRenderer.scheduleName,
    },
    {
      field: 'description',
      headerName: 'Description',
      colId: 'description',
      sortable: true,
      comparator: comparator.stringCompare,
      cellRenderer: cellRenderer.description,
    },
    {
      field: 'startTime',
      headerName: 'Start Date',
      comparator: comparator.date,
      colId: 'start_date',
      sortable: true,
      valueGetter: valueGetters.startDate,
      cellRenderer: cellRenderer.startDate,
    },
    {
      field: 'taskNumber',
      headerName: 'ID',
      colId: 'task_number',
      sortable: true,
      comparator: comparator.id,
      cellRenderer: cellRenderer.id,
    },
    {
      field: '',
      width: 112,
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      suppressMenu: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
      pinned: 'right',
      cellStyle: { paddingLeft: '7px', paddingRight: '7px' },
    },
  ];
};

export const comparator = {
  stringCompare: (a, b, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }
    return a
      ?.toString()
      ?.toLowerCase()
      ?.localeCompare(b?.toString()?.toLowerCase() || '', undefined, {
        numeric: true,
        sensitivity: 'base',
      });
  },
  date: (date1, date2, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }

    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  id: (id1, id2, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }

    return parseInt(id1, 10) - parseInt(id2, 10);
  },
  priority: (p1, p2, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }

    const p1Index = priorityDropdownData?.findIndex(
      (p) => p?.id?.toLowerCase() == p1?.toLowerCase(),
    );
    const p2Index = priorityDropdownData.findIndex(
      (p) => p?.id?.toLowerCase() == p2?.toLowerCase(),
    );

    return p1Index - p2Index;
  },
  assignee: (a, b, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }

    // If addTask is the same or both are false, sort by name
    const nameA = a ? a?.toLowerCase() : '';
    const nameB = b ? b?.toLowerCase() : '';

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0; // names are equal
  },
  status: (s1, s2, obj1, obj2, isDescending) => {
    // First, check if either of the rows has addTask: true
    if (obj1?.data?.addTask && !obj2?.data?.addTask) {
      return isDescending ? 1 : -1; // `a` should be placed above `b`
    }
    if (!obj1?.data?.addTask && obj2?.data?.addTask) {
      return isDescending ? -1 : 1; // `b` should be placed above `a`
    }
    const statusOrder = [
      'Open',
      'Missed',
      'In Progress',
      'On Hold',
      'Completed',
    ];

    return statusOrder.indexOf(s2) - statusOrder.indexOf(s1);
    // const s1Index = statusDropdownData?.findIndex(
    //   (s) => s?.id?.toLowerCase() == s1?.toLowerCase(),
    // );
    // const s2Index = statusDropdownData?.findIndex(
    //   (s) => s?.id?.toLowerCase() == s2?.toLowerCase(),
    // );
    //
    // return s1Index - s2Index;
  },
};
