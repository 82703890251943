// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import Header from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/HeaderV2';
import TemplateSubmissionPreview from 'components/TemplateSubmissionPreview';

// Styled
import { Column } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

// Utils
import SubmissionPhotos from '../photos';
import SubmissionActivityTimeline from '../Activity';
import { DropdownWrapper } from 'components/TemplateSubmissionPreview/Header/styled';
import Dropdown from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/Dropdown';
import { Box } from '@mui/material';
import CTabs from 'components/CTabs';

interface ActiveSubmissionLogPropTypes {
  activeSubmission: SubmissionReportType;
  template?: any;
  handleArchiveLogs?: (logs: any[]) => void;
  hideCorrectiveTask?: boolean;
  feedbackActions?: any;
  isReviewMode?: boolean;
  hideHeaderactions?: boolean;
  canReview?: boolean;
  forcedUpdate?: boolean;
  setForcedUpdate?: (data: boolean) => void;
  showReviewOptions?: boolean;
}

const TABS = [
  { id: 'SUBMISSION', label: 'Submission' },
  { id: 'FEEDBACK_ONLY', label: 'Feedback Only' },
  { id: 'PHOTOS', label: 'Photos' },
  { id: 'ACTIVITY', label: 'Activity' },
];

const panelPropsStyles = {
  sx: (theme) => ({
    padding: '8px 0px',
    [theme.breakpoints.down('md')]: {
      background: '#F2F5F9',
      margin: '0 -16px',
      padding: '8px 16px',
    },
  }),
};
const ActiveSubmissionLog = ({
  activeSubmission,
  template,
  handleArchiveLogs,
  hideCorrectiveTask = false,
  feedbackActions,
  isReviewMode = false,
  hideHeaderactions = false,
  canReview = false,
  forcedUpdate = false,
  showReviewOptions = false,
  setForcedUpdate,
}: ActiveSubmissionLogPropTypes) => {
  const [activeSubmissionItemsFiltered, setActiveSubmissionItemsFiltered] =
    useState<SubmissionReportType | undefined>(undefined);
  const [activeSubmissionItemsFilter, setActiveSubmissionItemsFilter] =
    useState<LogFilterTypes>('allItems');

  const handleFilterSubmissionLogItems = (
    filterName: 'allItems' | 'passed' | 'failed' | 'remaining' | 'completed',
  ) => {
    const active = activeSubmission ? { ...activeSubmission } : null;
    let newState: SubmissionReportType | undefined = undefined;
    if (active) {
      setActiveSubmissionItemsFilter(filterName);
      switch (filterName) {
        case 'allItems':
          setActiveSubmissionItemsFiltered(undefined);
          break;
        case 'passed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'pass') ||
                    (item.type !== 'passFail' && !!item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'failed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'fail') ||
                    (item.type !== 'passFail' && !item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'remaining':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !item.answers || item.type === 'header',
            ),
          };
          break;
        case 'completed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !!item.answers || item.type === 'header',
            ),
          };
          break;
        default:
          break;
      }
      setActiveSubmissionItemsFiltered(newState);
    }
  };

  const currentSubmission = useMemo(
    () => activeSubmissionItemsFiltered ?? activeSubmission,
    [
      activeSubmissionItemsFilter,
      activeSubmissionItemsFiltered,
      activeSubmission,
    ],
  );

  useEffect(() => {
    handleFilterSubmissionLogItems(activeSubmissionItemsFilter);
  }, [activeSubmission]);

  const tabsData = [
    {
      label: 'Submission',
      content: (
        <div>
          {currentSubmission?.id && (
            <TemplateSubmissionPreview
              hideCorrectiveTask={hideCorrectiveTask}
              activeSubmission={currentSubmission}
              handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
              activeSubmissionItemsFilter={activeSubmissionItemsFilter}
              log={currentSubmission}
              feedbackActions={feedbackActions}
              hideHeader={
                !currentSubmission?.TaskId &&
                currentSubmission?.status !== 'In Progress'
              }
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Feedback Only',
      content: (
        <div>
          {currentSubmission?.id && (
            <TemplateSubmissionPreview
              hideCorrectiveTask={hideCorrectiveTask}
              activeSubmission={currentSubmission}
              handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
              activeSubmissionItemsFilter={activeSubmissionItemsFilter}
              log={currentSubmission}
              isFeedbackOnlyLogs={true}
              feedbackActions={feedbackActions}
              hideHeader={true}
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Photos',
      content: (
        <div>
          {currentSubmission?.id && (
            <SubmissionPhotos
              submission={currentSubmission}
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Activity',
      content: (
        <div>
          {currentSubmission?.id && (
            <SubmissionActivityTimeline
              submission={currentSubmission}
              forcedUpdate={forcedUpdate}
              setForcedUpdate={setForcedUpdate}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
  ];

  return (
    <Column>
      <Header
        template={template}
        activeSubmission={currentSubmission}
        handleArchiveLogs={handleArchiveLogs}
        hideHeaderactions={hideHeaderactions}
        canReview={canReview}
        isReviewMode={isReviewMode}
        setForcedUpdate={setForcedUpdate}
        showReviewOptions={showReviewOptions}
      />

      {/* TODO: no idea about this check, discuss with talha , this is effecting submission details for in progress submission allover the app */}
      {!currentSubmission?.TaskId &&
      currentSubmission?.status !== 'In Progress' ? (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              '& .MuiTabs-root': {
                margin: '0 -16px',
              },
            },
          })}
        >
          <CTabs
            data={tabsData}
            labelProps={{
              sx: {
                textTransform: 'capitalize',
                fontSize: 14,
                padding: 8,
                height: 40,
              },
            }}
            component={
              <DropdownWrapper>
                <Dropdown
                  activeSubmission={activeSubmission}
                  handleFilterSubmissionLogItems={
                    handleFilterSubmissionLogItems
                  }
                  activeSubmissionItemsFilter={activeSubmissionItemsFilter}
                />
              </DropdownWrapper>
            }
          />
        </Box>
      ) : (
        <TemplateSubmissionPreview
          hideCorrectiveTask={hideCorrectiveTask}
          activeSubmission={currentSubmission}
          handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
          activeSubmissionItemsFilter={activeSubmissionItemsFilter}
          log={currentSubmission}
          feedbackActions={feedbackActions}
          hideHeader={
            !currentSubmission?.TaskId &&
            currentSubmission?.status !== 'In Progress'
          }
          isReviewMode={isReviewMode}
          canReview={canReview}
        />
      )}
    </Column>
  );
};

export default ActiveSubmissionLog;
