import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const ApprovalReportIcon = (props: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M6.75 9L8.25 10.5L11.625 7.125M6.90143 15.4507C7.14724 15.4182 7.39555 15.4848 7.59137 15.6356L8.49379 16.3281C8.7921 16.5573 9.20707 16.5573 9.50454 16.3281L10.4411 15.609C10.6161 15.4748 10.8369 15.4157 11.0552 15.4448L12.2268 15.599C12.5993 15.6481 12.9584 15.4407 13.1026 15.0932L13.5534 14.0032C13.6375 13.799 13.7992 13.6374 14.0033 13.5532L15.0932 13.1024C15.4407 12.9591 15.6482 12.5991 15.599 12.2266L15.4507 11.0974C15.4182 10.8516 15.4849 10.6033 15.6357 10.4075L16.3281 9.50499C16.5573 9.20666 16.5573 8.79167 16.3281 8.49418L15.609 7.55754C15.4749 7.38255 15.4157 7.16172 15.4449 6.94339L15.599 5.77176C15.6482 5.39927 15.4407 5.04011 15.0932 4.89595L14.0033 4.44513C13.7992 4.36096 13.6375 4.1993 13.5534 3.99514L13.1026 2.90517C12.9593 2.55768 12.5993 2.35018 12.2268 2.39935L11.0552 2.55351C10.8369 2.58351 10.6161 2.52435 10.442 2.39102L9.50537 1.67187C9.20707 1.44271 8.7921 1.44271 8.49463 1.67187L7.55804 2.39102C7.38305 2.52435 7.16224 2.58351 6.94392 2.55518L5.77235 2.40102C5.39988 2.35185 5.04075 2.55935 4.89659 2.90684L4.44663 3.99681C4.36164 4.20013 4.19998 4.3618 3.99667 4.44679L2.90676 4.89678C2.55929 5.04094 2.3518 5.4001 2.40097 5.77259L2.55512 6.94422C2.58345 7.16255 2.52429 7.38338 2.39097 7.55754L1.67186 8.49418C1.44271 8.79251 1.44271 9.20749 1.67186 9.50499L2.39097 10.4416C2.52512 10.6166 2.58428 10.8374 2.55512 11.0558L2.40097 12.2274C2.3518 12.5999 2.55929 12.9591 2.90676 13.1032L3.99667 13.554C4.20082 13.6382 4.36247 13.7999 4.44663 14.004L4.89743 15.094C5.04075 15.4415 5.40072 15.649 5.77319 15.5998L6.90143 15.4507Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'ApprovalReportIcon',
  );
  return <IconCmp viewBox={'0 0 18 18'} style={{ fill: 'none' }} {...props} />;
};
export default ApprovalReportIcon;
