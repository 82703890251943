import { useEffect } from 'react';

// Components
import FeedbackAttachments from './FeedbackAttachments';
import { CircularProgress, Stack } from '@mui/material';

// Styled
import {
  FeedbackAttachmentsUpperVariant,
  FeedbackWrapper,
  PostButton,
} from './styled';

// Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Third party
import { useDropzone } from 'react-dropzone';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import SendIcon from 'components/Icons/sendIcon';
import ClearIcon from '@mui/icons-material/Clear';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface PropTypes {
  feedbackText: string;
  posting: boolean;
  feedbackAttachments: any[];
  handleChange: (note: string) => void;
  handlePostNote: () => void;
  handleEditNote?: any;
  handleAddAttachment: (attachments: any[]) => void;
  handleDeleteAttachment: (index: number) => void;
  handleCancelFeedback: () => void;
  isEditing?: boolean;
  isReviewDialog?: boolean;
  feedbackEdit?: string | null;
}

const FeedbackInput = ({
  feedbackText,
  posting,
  feedbackAttachments,
  handlePostNote,
  handleEditNote,
  handleChange,
  handleAddAttachment,
  handleDeleteAttachment,
  handleCancelFeedback,
  isEditing,
  isReviewDialog,
  feedbackEdit,
}: PropTypes) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    disabled: posting,
  });
  const { isMobileDeviceMd } = useResponsive();

  useEffect(() => {
    handleAddAttachment(acceptedFiles);
  }, [acceptedFiles]);

  const isPostBtnDisabled = !feedbackText || posting;

  return (
    <FeedbackWrapper>
      {isReviewDialog && feedbackAttachments?.length > 0 && (
        <FeedbackAttachmentsUpperVariant>
          <FeedbackAttachments
            handleDeleteAttachment={(index) => handleDeleteAttachment(index)}
            attachments={feedbackAttachments}
          />
        </FeedbackAttachmentsUpperVariant>
      )}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          padding: '2px 10px',
          border: '1px solid #E0E0E0',
          borderRadius: '10px',
          marginTop: '10px',

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} disabled={posting} />
          <AttachFileIcon className="attachIcon" />
        </div>
        <CustomInput
          className="feedbackInput"
          sx={{
            marginBottom: 0,
            '& .MuiOutlinedInput-input': {
              fontSize: isMobileDeviceMd ? 16 : 13,
            },
          }}
          fieldProps={{
            placeholder: 'Add a feedback',
            value: feedbackText,
            onChange: handleChange,
            autoFocus: !isMobileDeviceMd,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                feedbackEdit ? handleEditNote() : handlePostNote();
              }
            },
          }}
        />
        {feedbackEdit && (
          <ClearIcon
            style={{ cursor: 'pointer', color: 'darkRed', marginRight: 4 }}
            onClick={handleCancelFeedback}
          />
        )}
        <PostButton
          onClick={!feedbackEdit ? handlePostNote : handleEditNote}
          sx={{
            ...(isPostBtnDisabled && {
              pointerEvents: 'none',
              opacity: 0.6,
            }),
          }}
        >
          {posting ? (
            <CircularProgress size={15} style={{ color: 'white' }} />
          ) : (
            <SendIcon />
          )}
        </PostButton>
      </Stack>

      {!isReviewDialog && (
        <Stack direction="row" alignItems="center" gap="10px">
          <FeedbackAttachments
            handleDeleteAttachment={(index) => handleDeleteAttachment(index)}
            attachments={feedbackAttachments}
          />
        </Stack>
      )}
    </FeedbackWrapper>
  );
};

export default FeedbackInput;
