import * as authSelectors from 'store/selectors/auth';
import * as userSelectors from 'store/selectors/users';
import * as tagSelectors from 'store/selectors/tag';
import * as serviceSelectors from 'store/selectors/service';
import * as propertyLayoutSelectors from 'store/selectors/propertyLayout';
import * as tasks from 'store/selectors/task';
import * as requests from 'store/selectors/requests';
import * as checklistV2 from 'store/selectors/checklistV2';
import * as messaging from 'store/selectors/messaging';
import * as notification from 'store/selectors/notification';
import * as common from 'store/selectors/common';
import * as loader from 'store/selectors/loader';
import * as timezone from 'store/selectors/timezone';
import * as workspace from 'store/selectors/workspace';
import * as taskTemplates from 'store/selectors/taskTemplates';
import * as dialogSelectors from 'store/selectors/dialog';
import * as appStateSelectors from 'store/selectors/appState';
import * as locationStateSelector from 'store/selectors/locations';
import * as topAlertSelectors from 'store/selectors/topAlert';
import * as billingStateSelector from 'store/selectors/billing';
import * as calendlySelector from 'store/selectors/calendly';
import * as paywallSelectors from 'store/selectors/paywall';
import * as configSelectors from 'store/selectors/userConfigs';
import * as savedOptionSelectors from 'store/selectors/savedOptions';
import * as sidebarConfigSelectors from 'store/selectors/sidebarConfigSelectors';
import * as workspaceConfigReducer from 'store/selectors/workspaceConfigReducer';
import * as teamsReducer from 'store/selectors/teams';
import * as locationsLevelsReducer from 'store/selectors/locationsLevels';
import * as projectsSelectors from 'store/selectors/projects';
import * as schedulesSelectors from 'store/selectors/schedules';
import * as flaggedItemsCategoriesSelector from 'store/selectors/flaggedItemsCategories';
import * as roleSelector from 'store/selectors/roles';
import * as workflowsSelector from 'store/selectors/workflows';
import * as navigation from 'store/selectors/navigation';

const selectors = {
  ...authSelectors,
  ...userSelectors,
  ...tagSelectors,
  ...taskTemplates,
  ...serviceSelectors,
  ...propertyLayoutSelectors,
  ...tasks,
  ...requests,
  ...checklistV2,
  ...messaging,
  ...notification,
  ...common,
  ...loader,
  ...timezone,
  ...workspace,
  ...dialogSelectors,
  ...appStateSelectors,
  ...locationStateSelector,
  ...topAlertSelectors,
  ...billingStateSelector,
  ...calendlySelector,
  ...paywallSelectors,
  ...configSelectors,
  ...savedOptionSelectors,
  ...sidebarConfigSelectors,
  ...workspaceConfigReducer,
  ...teamsReducer,
  ...locationsLevelsReducer,
  ...projectsSelectors,
  ...schedulesSelectors,
  ...flaggedItemsCategoriesSelector,
  ...roleSelector,
  ...workflowsSelector,
  ...navigation,
};

export default selectors;
