import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import { CustomModalWrapper } from 'components/AllDialogs/customizeLocationHierarcy/style';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from 'components/Icons/closeIcon';
import { ContentWrapper, FooterWrapper, HeaderWrapper } from './style';
import CustomButton from 'components/Button/CustomButton';
import { useEffect, useMemo, useState } from 'react';
import AssignToUsersAndTeams from 'components/TaskInputs/assignTo/usersAndTeams';

const ManageCompletionNotificationDialog = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const modalState =
    dialogState?.[DIALOGS.MANAGE_COMPLETION_NOTIFICATION_DIALOG];

  const { open, data } = modalState || {};
  const { confirmCallback, recipients } = data || {};

  const [selectedUsersAndTeams, setSelectedUsersAndTeams] = useState<string[]>(
    [],
  );

  const [selected, setSelected] = useState<any>(null);

  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const roles = useSelector(selectors.getRoles);
  const { hashRoles } = roles;

  const dispatch = useDispatch();

  useEffect(() => {
    if (recipients) {
      setSelected(recipients);

      const recipientsList = recipients?.map((recipient) => recipient?.id);
      setSelectedUsersAndTeams(recipientsList);
    }
  }, [recipients]);

  // Dispatch to close modal
  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.MANAGE_COMPLETION_NOTIFICATION_DIALOG,
        open: false,
      }),
    );
  };

  const handleUpdateAssignee = (data) => {
    setSelectedUsersAndTeams(data);
    const recipientsList: any = [];
    if (data?.length > 0) {
      for (let index = 0; index <= data?.length - 1; index++) {
        const people = usersHash?.[data?.[index]];
        if (people) {
          recipientsList?.push({
            id: data?.[index],
            type: 'user',
          });
          continue;
        }

        const team = teamsHash?.[data?.[index]];
        if (team) {
          recipientsList?.push({
            id: data?.[index],
            type: 'team',
          });
          continue;
        }

        const role = hashRoles?.[data?.[index]];
        if (role) {
          recipientsList?.push({
            id: data?.[index],
            type: 'role',
          });
          continue;
        }
      }
      setSelected(recipientsList);
      return;
    }
    setSelected(null);
  };

  const saveHandler = () => {
    confirmCallback?.(selected);

    handleClose();
  };

  return (
    <CustomModalWrapper handleClose={handleClose} open={open}>
      <HeaderWrapper
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="title">Send Notification</Typography>

        <IconButton className={'closeIcon'} onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      </HeaderWrapper>

      <ContentWrapper>
        <Typography className="title">Recipients</Typography>
        <AssignToUsersAndTeams
          handleChangeCallback={handleUpdateAssignee}
          currentSelected={selectedUsersAndTeams}
          hideUsersAndTeamsLocations={true}
          hideRoles={false}
          hideAll={true}
          hideUsersAndTeamsIcon={true}
          hideActions={true}
          popperProps={{
            disablePortal: false,
            style: {
              zIndex: 1300,
              width: 590,
            },
          }}
          placeholderUsersAndTeams="Select users, teams or roles"
        />
      </ContentWrapper>

      <FooterWrapper direction="row" alignItems="center" justifyContent="end">
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          color={'primary'}
          onClick={saveHandler}
          className="saveBtn"
        >
          Confirm
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default ManageCompletionNotificationDialog;
