// MUI components
import { Divider, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { HeaderContainer } from './styles';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import CloseIcon from 'components/Icons/closeIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useResponsive from 'utils/CustomHooks/useResponsive';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const MobileActionDropdown = (props) => {
  const { requestChangesHandler, rejectHandler, approveHandler } = props;

  const mobileActionOptions = [
    {
      label: 'Request Changes',
      id: 'request-changes',
    },
    {
      label: 'Reject',
      id: 'reject',
    },
    {
      label: 'Approve',
      id: 'approve',
    },
  ];

  const dispatch = useDispatch();

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'request-changes':
        requestChangesHandler();
        break;

      case 'reject':
        rejectHandler();
        break;
      case 'approve':
        approveHandler();
        break;
      default: {
        return;
      }
    }
  };

  return (
    <ActionDropdown
      popperProps={{ style: { width: 180 }, placement: 'bottom-end' }}
      options={mobileActionOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2, transform: 'rotate(90deg)' },
      }}
    >
      <MoreVertOutlinedIcon style={{ fontSize: 22 }} />
    </ActionDropdown>
  );
};

export default MobileActionDropdown;
