// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, TextField } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const FeedbackCollapseButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 4,
  alignItems: 'center',
  background: 'rgba(236, 239, 241, 1)',
  width: 'fit-content',
  padding: '4px 8px',
  color: 'white',
  cursor: 'pointer',
  borderRadius: 6,
  backgroundColor: '#6868FE',
  '& .feedbackIcon': {
    fontSize: 19,
    fill: 'none',
    marginRight: 1,
  },
  '& expansionIcon': {
    fontSize: 18,
    fill: 'white',
  },

  '& .title': {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '17.76px',
    color: 'white',
  },
}));

export const FeedbackTitle = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: 'rgba(66, 66, 66, 1)',
  margin: '5px 0px',
}));

export const UserName = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: 'rgba(0, 0, 0, 1)',
}));

export const FeedbackDateTime = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '15.03px',
  color: '#616161',
  paddingTop: 3,
}));

export const MessageBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(239, 242, 244, 1)',
  padding: '8px 12px',
  borderRadius: '12px',
  margin: '0px 0px 5px 25px',
  width: 'fit-content',

  '& .message': {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '19.12px',
    color: '#000000',
    'word-break': 'break-word',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    backgroundColor: 'transparent',
  },
}));

export const ResolveBox = styled(Box)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
  padding: '5px 6px',
  borderRadius: '6px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .label': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    color: 'rgba(0, 0, 0, 1)',
    paddingTop: 2,
  },

  '& .icon': {
    fontSize: 19,
    marginRight: 5,
    color: 'rgba(76, 175, 80, 1)',
  },
}));

export const ResolvedBox = styled(Box)(() => ({
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .label': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    color: 'rgba(76, 175, 80, 1)',
    paddingTop: 2,
  },

  '& .icon': {
    fontSize: 19,
    marginRight: 5,
    color: 'rgba(76, 175, 80, 1)',
  },
}));

export const UpdaterWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 3,
  alignItems: 'center',
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '13.66px',
}));

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
}));

export const AvatarAndNameWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const NoteText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginLeft: 36,
}));

export const AttachmentsCnt = styled(Box)(() => ({
  marginLeft: 36,
  marginTop: 4,
  '& div.attachment-wrap': {
    display: 'flex',
    gap: 10,
  },
  '& img': {
    width: 80,
    height: 64,
    borderRadius: 6,
    overflow: 'hidden',
    objectFit: 'cover',
    cursor: 'pointer',
  },
}));

export const UserInfoCnt = styled(Stack)({
  gap: 8,
  padding: '6px 10px',
  marginLeft: -10,
});

export const FeedbackWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px',
  gap: 8,
  width: '100%',

  '& .attachIcon': {
    cursor: 'pointer',
    height: 20,
    color: 'rgba(97, 97, 97, 1)',
  },

  '& input': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    color: '#000000',
  },
}));

export const PostButton = styled(Box)(() => ({
  borderRadius: 10,
  height: 34,
  minWidth: 34,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 4px 0px rgba(104, 104, 254, 0.3)',
  backgroundColor: 'rgba(104, 104, 254, 1)',
  cursor: 'pointer',
  '& svg': {
    fontSize: 21,
    '& path': {
      stroke: 'white',
    },
  },
}));

export const NoteTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(117, 117, 117, 1)',
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .attach-icon': {
    transform: 'rotate(45deg)',
    fontSize: 18,
    color: 'rgba(97, 97, 97, 1)',
    cursor: 'pointer',
  },
}));

export const ActionsButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

export const CancelButton = styled(CustomButton)(() => ({
  padding: '2px 10px',
  fontWeight: 500,
  fontSize: 13,
  color: 'rgba(97, 97, 97, 1)',
  height: 26,
}));

export const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px 6px 0px 0px',
  gap: 8,
  padding: '8px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  marginTop: 4,
  '& > div': {
    backgroundColor: 'white',
  },
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const SingleAttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  width: 'fit-content',
  '& .close-icon': {
    position: 'absolute',
    top: -4,
    right: -1,
    fontSize: 14,
    cursor: 'pointer',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: 'darkRed',
  },
}));

export const SingleAttachment = styled(Box)(() => ({
  width: 60,
  height: 44,
  borderRadius: 6,
  objectFit: 'cover',
  cursor: 'pointer',
  border: '1px solid #f0f4fe',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
}));

export const FeedbackAttachmentsUpperVariant = styled(Box)(() => ({
  '& > div:first-of-type': {
    flexWrap: 'wrap',
    background: '#eaeaea',
    border: '1px solid lightgray',
    justifyContent: 'center',
    padding: 10,
    borderRadius: 10,

    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

export const FeedbackInputCnt = styled(Box)(() => ({
  '& > div': {
    padding: '0px',
  },
}));
