// React and MUI Imports
import { FC, useEffect, useState } from 'react';

// Components Imports
import TabsList from './tabs';
import { ALL, LOCATIONS, PEOPLE, TEAMS, ROLES } from './constants';
import PeopleList from './renderers/people/people';
import TeamsList from './renderers/teams/teams';

// Styles
import { AssigneeDropdownContentCnt, Content } from './style';

// Utils Imports
import { sortUsers, sortTeams, sortLocations } from './utils';

import AllList from './renderers/all/all';
import LocationsList from './renderers/locations/locations';
import { useAssigneesList } from 'utils/CustomHooks/useAssigneesList';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import RolesList from './renderers/roles/roles';

interface ListObjectProps {
  allText?: string;
  showInlineSelection?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  hideActions?: boolean;
  selected?: string[];
  placeholder?: string;
  selectedListPlaceholder?: string;
  unselectedListPlaceholder?: string;
  showMeOnTop?: boolean;
  disableUnAuth?: boolean;
  partition?: boolean;
}

interface LocationsListObjectProps extends ListObjectProps {
  autoTagLocation?: boolean;
  selectOnly?: boolean;
}

// Interface
interface ListProps {
  all?: ListObjectProps;
  people?: ListObjectProps;
  teams?: ListObjectProps;
  locations?: LocationsListObjectProps;
  roles?: ListObjectProps;
}

// Props Interface
interface IProps {
  showTabs?: boolean;
  listProps?: ListProps;
  onSelectionCallback?: (data: {
    currentSelected: string | null;
    selectedList: {
      people?: string[];
      teams?: string[];
      roles?: string[];
      locations?: string[];
    };
    all: boolean;
  }) => void;
  displayOptions?: any;
  allSelected?: boolean;
  blacklistedIds?: string[];
}

// Component Main File
const AssigneeDropdownList: FC<IProps> = ({
  showTabs = true,
  listProps = {},
  onSelectionCallback,
  displayOptions = {
    all: true,
    people: true,
    teams: true,
    locations: true,
    roles: true,
  },
  allSelected = false,
  blacklistedIds = [],
}) => {
  const authorizedLocations = useSelector(selectors.getAuthorizedLocations);

  // Get Initial Selected Data to Show
  const getIntial = () => {
    if (displayOptions?.all) return ALL;
    if (displayOptions?.people) return PEOPLE;
    if (displayOptions?.teams) return TEAMS;
    if (displayOptions?.locations) return LOCATIONS;
    if (displayOptions?.roles) return ROLES;
  };

  const [selectedTab, setSelectedTab] = useState(getIntial());
  const [initialLoad, setInitialLoad] = useState(true);

  const { getUsersList, getTeamsList, getLocationsList, getRolesList } =
    useAssigneesList();
  const [data, setData] = useState({});

  // Fetch Data through Hook
  useEffect(() => {
    let users = [];
    let teams = [];
    let locations = [];
    let roles = [];

    if (displayOptions?.people) {
      users = getUsersList(blacklistedIds);
    }

    if (displayOptions?.teams) {
      teams = getTeamsList(blacklistedIds);
    }

    if (displayOptions?.locations) {
      locations = getLocationsList(
        blacklistedIds,
        listProps?.locations?.disableUnAuth,
      );
    }

    if (displayOptions?.roles) {
      roles = getRolesList(blacklistedIds);
    }

    setData({
      ...(displayOptions?.people && { people: sortUsers(users) }),
      ...(displayOptions?.teams && { teams: sortTeams(teams) }),
      ...(displayOptions?.locations && { locations: sortLocations(locations) }),
      ...(displayOptions?.roles && { roles: sortLocations(roles) }),
    });

    setInitialLoad(false);
  }, [blacklistedIds]);

  useEffect(() => {
    if (authorizedLocations && !initialLoad) {
      const locations = getLocationsList(blacklistedIds);
      setData({
        ...data,
        ...(displayOptions?.locations && {
          locations: sortLocations(locations),
        }),
      });
    }
  }, [authorizedLocations]);

  const tabChangeHandler = (option) => {
    setSelectedTab(option);
  };

  const getAllSelected = () => {
    const allSelected = {};
    for (const key in data) {
      const ids = data[key]?.map((item) => item?.id);
      allSelected[key] = ids;
    }

    return allSelected;
  };

  const changeHandler = (selectedOption, nowAllSelected, type) => {
    if (nowAllSelected) {
      return onSelectionCallback?.({
        currentSelected: null,
        selectedList: getAllSelected(),
        all: nowAllSelected,
      });
    }

    if (allSelected) {
      return onSelectionCallback?.({
        currentSelected: null,
        selectedList: {
          ...(displayOptions?.people && {
            people: type === PEOPLE ? [selectedOption] : [],
          }),
          ...(displayOptions?.teams && {
            teams: type === TEAMS ? [selectedOption] : [],
          }),
          ...(displayOptions?.locations && {
            locations: type === LOCATIONS ? [selectedOption] : [],
          }),
          ...(displayOptions?.roles && {
            roles: type === ROLES ? [selectedOption] : [],
          }),
        },
        all: nowAllSelected,
      });
    }

    const selectedList = listProps?.[type]?.selected;
    const isSelected = selectedList?.includes(selectedOption);

    return onSelectionCallback?.({
      currentSelected: selectedOption,
      selectedList: {
        ...(displayOptions?.people && {
          people: listProps?.people?.selected,
        }),
        ...(displayOptions?.teams && {
          teams: listProps?.teams?.selected,
        }),
        ...(displayOptions?.locations && {
          locations: listProps?.locations?.selected,
        }),
        ...(displayOptions?.roles && {
          roles: listProps?.roles?.selected,
        }),
        [type]: isSelected
          ? selectedList?.filter((item) => item !== selectedOption)
          : [...selectedList, selectedOption],
      },
      all: nowAllSelected,
    });
  };

  const changeHandlerLocations = (currentSelected, selectedList) => {
    return onSelectionCallback?.({
      currentSelected: currentSelected,
      selectedList: {
        ...(displayOptions?.people && {
          people: listProps?.people?.selected,
        }),
        ...(displayOptions?.teams && {
          teams: listProps?.teams?.selected,
        }),
        ...(displayOptions?.roles && {
          roles: listProps?.roles?.selected,
        }),
        ...(displayOptions?.locations && {
          locations: selectedList,
        }),
      },
      all: false,
    });
  };

  return (
    <>
      <AssigneeDropdownContentCnt>
        {showTabs && (
          <TabsList
            data={data}
            onChangeCallback={tabChangeHandler}
            displayOptions={displayOptions}
            selectedTabOption={selectedTab}
          />
        )}

        <Content>
          {selectedTab === PEOPLE && (
            <PeopleList
              data={data?.[PEOPLE]}
              allSelected={allSelected}
              listProps={listProps?.people}
              changeHandler={changeHandler}
            />
          )}

          {selectedTab === TEAMS && (
            <TeamsList
              data={data?.[TEAMS]}
              allSelected={allSelected}
              listProps={listProps?.teams}
              changeHandler={changeHandler}
            />
          )}

          {selectedTab === LOCATIONS && (
            <LocationsList
              data={data?.[LOCATIONS]}
              allSelected={allSelected}
              listProps={listProps?.locations}
              changeHandler={changeHandlerLocations}
            />
          )}

          {selectedTab === ROLES && (
            <RolesList
              data={data?.[ROLES]}
              allSelected={allSelected}
              listProps={listProps?.roles}
              changeHandler={changeHandler}
            />
          )}

          {selectedTab === ALL && (
            <AllList
              data={data}
              allSelected={allSelected}
              listProps={listProps?.all}
              changeHandler={changeHandler}
              changeHandlerLocations={changeHandlerLocations}
              displayOptions={displayOptions}
            />
          )}
        </Content>
      </AssigneeDropdownContentCnt>
    </>
  );
};

export default AssigneeDropdownList;
