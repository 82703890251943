import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt } from './style';
import DIALOGS from 'utils/dialogIds';

import selectors from 'store/selectors';
import { Box, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import RequestChangesIcon from 'components/Icons/requestChangesIcon';
import ApproveIcon from 'components/Icons/approveIcon';
import RejectIcon from 'components/Icons/rejectIcon';
import useResponsive from 'utils/CustomHooks/useResponsive';

const SubmissionApprovalSubmittedDialog = () => {
  const { isMobileDeviceMd } = useResponsive();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const { open, data } =
    dialogState?.[DIALOGS?.SUBMISSION_APPROVAL_SUBMITTED_DIALOG] ?? {};

  const { type, successCallback } = data || {};

  const handleClose = () => {
    successCallback?.();
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.SUBMISSION_APPROVAL_SUBMITTED_DIALOG,
      }),
    );
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: !!isMobileDeviceMd,
      }}
    >
      <ContentCnt>
        {type === 'Changes Requested' && (
          <>
            <RequestChangesIcon className="changeRequestedIcon" />
            <Typography className="title">Changes Requested</Typography>
            <Box className="descriptionContainer">
              <Typography className="description">
                Your request for changes has been sent to the submitter.
              </Typography>
              <Typography className="description">
                They will receive a notification.
              </Typography>
            </Box>
          </>
        )}

        {type === 'Approved' && (
          <>
            <ApproveIcon className="approveIcon" />
            <Typography className="title">Submission Approved</Typography>
            <Box className="descriptionContainer">
              <Typography className="description">
                Report is available for download.
              </Typography>
              <Typography className="description">
                Submitter has been notified.
              </Typography>
            </Box>
          </>
        )}

        {type === 'Rejected' && (
          <>
            <RejectIcon className="rejectIcon" />
            <Typography className="title">Submission Rejected</Typography>
            <Box className="descriptionContainer">
              <Typography className="description">
                Report is available for download.
              </Typography>
              <Typography className="description">
                Submitter has been notified.
              </Typography>
            </Box>
          </>
        )}
        <CustomButton
          className="closeBtn"
          variant="outlined"
          onClick={handleClose}
        >
          Close
        </CustomButton>
      </ContentCnt>
    </CustomDialog>
  );
};

export default SubmissionApprovalSubmittedDialog;
