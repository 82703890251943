import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#F6F8FA',
  '& .innerContainer': {
    margin: '0px auto 100px auto',
    backgroundColor: '#FFFFFF',
    maxWidth: 1000,
    width: '100%',
    minHeight: 1000,

    padding: 16,
    borderRadius: 16,
  },
  '& .header': {
    marginBottom: 50,
  },

  [theme.breakpoints.down('md')]: {
    backgroundColor: '#f4e4f5',
    '& .innerContainer': {
      margin: '0px auto 0',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      paddingBottom: 0,
      minHeight: 'calc(100vh - 60px)',
    },
  },
}));
