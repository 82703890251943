import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { CustomRolesDetailFormContext } from '../context';
import { HeaderContent, LayoutBox, MainCnt } from './style';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import CustomButton from 'components/Button/CustomButton';
import { Visibility } from '@mui/icons-material';
import HomeIcon2 from 'components/Icons/homeIcon2';
import ReportingIcon from 'components/Icons/ReportingIcon';
import CheckIcon from '@mui/icons-material/Check';
import actions from 'store/actions';
import BarChartIcon2 from 'components/Icons/barChartIcon2';
import TasksTemplateImg from 'public/assets/img/PulseDashboardPreview.png';
import DashboardDropdown from './dashboardDropdown/dashboardDropdown';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import { getPermissionsForRoles } from 'store/selectors/permissions';

const RoleHomeView = (props) => {
  const { isEdit, dashboards } = props;
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const { fetchCustomRoles } = useCustomRoles();
  const permissionsData = useSelector(getPermissionsForRoles);
  const { list: permissionsList } = permissionsData || {};

  const { state, updateState } = useContext(CustomRolesDetailFormContext);

  const { details, permissions } = state;

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  const handlePreview = (event) => {
    event.stopPropagation();
    const previewData = [
      {
        type: 'image/png',
        url: TasksTemplateImg.src || TasksTemplateImg,
        // url: 'public/assets/img/PulseDashboardPreview.png',
      },
    ];
    dispatch(
      actions.setAttachmentsPreviewData({
        index: 0,
        attachments: previewData,
      } as any),
    );
  };
  const handleUpdateHomeView = (view) => {
    const homePage = {
      ...(details?.homepage ?? {}),
      web: view,
      mobile: view === 'CUSTOM' ? (checkBox ? 'PULSE' : 'DEFAULT') : view,
    };
    updateState({
      details: {
        ...details,
        homepage: {
          ...homePage,
        },
      },
    });
  };

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setCheckBox(isChecked);

    // Only update mobile view if web view is CUSTOM
    if (details.homepage.web === 'CUSTOM') {
      updateState({
        details: {
          ...details,
          homepage: {
            ...(details?.homepage ?? {}),
            mobile: isChecked ? 'PULSE' : 'DEFAULT',
          },
        },
      });
    }
  };
  const handleUpdateDashboard = (dashboard, obj) => {
    const [type, id] = dashboard.split('::');
    const homePage = {
      ...(details?.homepage ?? {}),
      web: 'CUSTOM',
      mobile: checkBox ? 'PULSE' : 'DEFAULT',
      dashboard: {
        id: obj.id,
        name: obj.label,
        ...(obj.slug && { slug: obj.slug }),
        type: type,
      },
    };
    updateState({
      details: {
        ...details,
        homepage: {
          ...homePage,
        },
      },
    });
  };
  useEffect(() => {
    if (details?.homepage?.web === 'CUSTOM') {
      if (details?.homepage?.mobile === 'DEFAULT') {
        setCheckBox(false);
      } else {
        setCheckBox(true);
      }
    }
  }, []);
  const allowCustomDashboard = useMemo(() => {
    const reportingPermission = permissionsList.REPORTING.find(
      (x) => x.title === 'CAN_VIEW_REPORTING',
    );
    const isAllowedReporting = permissions?.includes(reportingPermission?.id);
    if (!isAllowedReporting && details?.homepage?.web === 'CUSTOM') {
      handleUpdateHomeView('DEFAULT');
    }

    return isAllowedReporting;
  }, [permissions]);
  return (
    <MainCnt
      sx={{
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
      }}
    >
      <HeaderContent>
        <Typography className="top">
          {isEdit ? 'Step 3 of 3' : 'Step 3 of 4'}
        </Typography>
        <Typography className="bottom">Home Page Layout</Typography>
      </HeaderContent>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="title" style={{ fontWeight: 500 }}>
            Whether it's for personal tasks, managing a single location, or
            keeping an eye on multiple locations. Pick a layout that gives you
            the insights and control you need for this role.
          </Typography>
          <LayoutBox
            selected={details?.homepage?.web === 'DEFAULT'}
            onClick={() => handleUpdateHomeView('DEFAULT')}
          >
            <div className="layoutIcon">
              <ReportingIcon />
            </div>
            <Stack direction={'column'} flex={1}>
              <Typography className="title">
                Default Views (Action Center on Web / Current Mobile Dashboard)
              </Typography>
              <Typography className="description">
                Quick actions oriented dashboard.
              </Typography>
            </Stack>
            <span className="checkBox">
              <CheckIcon />
            </span>
          </LayoutBox>
          <LayoutBox
            selected={details?.homepage?.web === 'PULSE'}
            onClick={() => handleUpdateHomeView('PULSE')}
          >
            <div className="layoutIcon">
              <HomeIcon2 />
            </div>
            <Stack direction={'column'} flex={1}>
              <Typography className="title">
                Location Pulse Dashboard
              </Typography>
              <Typography className="description">
                Dashboard tailored to managing updates and tasks for one
                specific location.
              </Typography>
            </Stack>
            <CustomButton
              className="openMobileBtn"
              sx={{
                color: '#4E48FA',
                height: 'fit-content',
                borderColor: '#4E48FA',
                svg: {
                  color: '#4E48FA',
                },
              }}
              variant="outlined"
              buttonType="grayWhite"
              onClick={(e) => handlePreview(e)}
              startIcon={<Visibility />}
            >
              <span>Preview</span>
            </CustomButton>
            <span className="divider"></span>
            <span className="checkBox">
              <CheckIcon />
            </span>
          </LayoutBox>
          <LayoutBox
            selected={details?.homepage?.web === 'CUSTOM'}
            onClick={() =>
              allowCustomDashboard && handleUpdateHomeView('CUSTOM')
            }
            sx={{
              display: 'block',
              ...(!allowCustomDashboard && { opacity: 0.7, cursor: 'default' }),
            }}
          >
            <Stack
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={'10px'}
              direction={'row'}
            >
              <div className="layoutIcon">
                <BarChartIcon2 sx={{ margin: '0px -3px' }} />
              </div>
              <Stack direction={'column'} flex={1}>
                <Typography className="title">
                  Xenia Preset / Custom Dashboard
                </Typography>
                <Typography className="description">
                  Xenia's Preset or Custom Dashboards to personalize your Home
                  Page experience.
                </Typography>
              </Stack>
              <span className="checkBox">
                <CheckIcon />
              </span>
            </Stack>
            <Stack
              ml={'50px'}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Stack maxWidth={'300px'} mt={'10px'}>
                <DashboardDropdown
                  data={dashboards}
                  selectedOption={
                    details?.homepage?.dashboard?.type
                      ? `${details?.homepage?.dashboard?.type}::${
                          details?.homepage?.dashboard?.slug ||
                          details?.homepage?.dashboard?.id
                        }`
                      : null
                  }
                  popperProps={{
                    placement: 'bottom-start',
                  }}
                  onChangeCallback={handleUpdateDashboard}
                />
              </Stack>
              <Divider sx={{ margin: '10px 0' }} />
              <Stack
                p={'15px'}
                alignItems={'center'}
                gap={'10px'}
                direction={'row'}
              >
                <CustomCheckbox
                  edge="start"
                  checked={checkBox}
                  disableRipple
                  disabled={
                    !allowCustomDashboard || details?.homepage?.web !== 'CUSTOM'
                  }
                  onChange={(event) => handleCheckbox(event)}
                />
                <Box>
                  <Typography className="title">
                    Show <b>"Location Pulse Dashboard"</b> on the mobile home
                    tab
                  </Typography>

                  <Typography className="description">
                    If left unchecked, the <b>'Default View (Action Center)'</b>{' '}
                    will be displayed on the mobile home tab.
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </LayoutBox>
        </Grid>
      </Grid>
    </MainCnt>
  );
};

export default RoleHomeView;
