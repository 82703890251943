// Styled
import { StyledCheckbox, FieldWrapper } from './styled';
import { FieldName } from '../styled';

// Types
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import { useContext } from 'react';
import { DashboardContext } from 'pages/TemplateDashboard/context';
import CreateCorrectiveTask from 'components/TemplatePreview/CreateCorrectiveTask';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface ProcedurePropTypes {
  item: SubmissionItemType;
  answers?: any | null;
  onChangeCb?: (value: boolean) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  error: any;
  isPreview: boolean;
}

const Procedure = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  error,
  isPreview,
}: ProcedurePropTypes) => {
  const { isMobileDeviceMd } = useResponsive();

  const handleClick = (e) => {
    onChangeCb && onChangeCb(!answers?.value);
  };

  const { template: _template } = useContext(DashboardContext) || {};

  const finalTemplate = templateLog?.Checklist || _template;
  return (
    <FieldWrapper
      className="procedure-item-type"
      hasnotes={item?.TaskChecklistItemNotes?.length !== 0}
      error={error?.length ? 'true' : ''}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack flex={1} mr={2} maxWidth={hideCorrectiveTask ? '100%' : '70%'}>
          <Box display="flex" alignItems="center" gap={1}>
            {isPreview && !answers?.value && (
              <IndeterminateCheckBoxIcon
                sx={{ color: 'rgba(158, 158, 158, 1)' }}
              />
            )}
            {(!isPreview || answers?.value) && (
              <StyledCheckbox
                disabled={!onChangeCb}
                color="success"
                checked={answers?.value}
                onChange={handleClick}
              />
            )}

            <FieldName>{item.description}</FieldName>
          </Box>
          {isPreview && !answers?.value && <Unanswered />}
        </Stack>
        {!isMobileDeviceMd &&
          finalTemplate?.isShowTimestamp &&
          item.completedAt && (
            <CompletedInfo user={item.Updater} time={item.completedAt} />
          )}
        {!hideCorrectiveTask && (
          <CreateCorrectiveTask
            item={item}
            answer={answers}
            templateLog={templateLog}
            visible={!(finalTemplate?.isShowTimestamp && item.completedAt)}
            onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
          />
        )}
      </Stack>
    </FieldWrapper>
  );
};

export default Procedure;
