// React
import { useEffect, useState, useContext } from 'react';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import { EmailTriggerType, NotificationsTriggerPayload } from '../types';

// Utils
import xeniaApi from 'api/index';

// Context
import { SettingsContext } from 'components/TemplateDashboard/TemplateSettingsModal/context';

const useEmailTriggers = () => {
  const ctx = useContext(SettingsContext);

  const template = ctx?.template as TemplateType;
  const [loading, setLoading] = useState(true);

  const fetchInitialData = async () => {
    const result = await xeniaApi.getTemplateEmailTriggers(template.id);
    ctx?.setEmailTriggers(result ? result.data : []);
    setLoading(false);
  };

  useEffect(() => {
    !ctx?.emailTriggers && fetchInitialData();
    ctx?.emailTriggers && setLoading(false);
  }, [template]);

  const addEmailTrigger = async (payload: NotificationsTriggerPayload) => {
    const result = await xeniaApi.addTemplateEmailTrigger({
      templateId: template.id,
      payload,
    });
    ctx?.setEmailTriggers(
      result
        ? [...(ctx?.emailTriggers as EmailTriggerType[]), result.data]
        : (ctx?.emailTriggers as EmailTriggerType[]),
    );
    return !!result;
  };

  const editEmailTrigger = async (
    payload: NotificationsTriggerPayload,
    triggerId: string,
  ) => {
    if (template) {
      const result = await xeniaApi.editTemplateEmailTrigger({
        templateId: template.id,
        payload,
        triggerId,
      });

      ctx?.setEmailTriggers(
        ctx?.emailTriggers
          ? ctx.emailTriggers.map((trigger) =>
              trigger.id === triggerId ? result.data : trigger,
            )
          : [],
      );
    }
  };

  const deleteEmailTrigger = async (triggerId: string) => {
    if (template) {
      await xeniaApi.deleteTemplateEmailTrigger({
        templateId: template.id,
        triggerId,
      });
      ctx?.setEmailTriggers(
        ctx?.emailTriggers
          ? ctx?.emailTriggers.filter((trigger) => trigger.id !== triggerId)
          : [],
      );
    }
  };

  return {
    loading,
    addEmailTrigger,
    editEmailTrigger,
    deleteEmailTrigger,
  };
};

export default useEmailTriggers;
