import actionsTypes from 'store/actionTypes';
import { Action } from 'models/index.m';

export const setRequestsListFilters = (data) => ({
  type: actionsTypes.SET_REQUESTS_LIST_FILTERS,
  payload: data,
});

export const setIsFiltersORSearchInRequests = (data: any): Action<any> => ({
  type: actionsTypes.SET_IS_FILTERS_OR_SEARCH_IN_REQUESTS,
  payload: data,
});
