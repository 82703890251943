import CustomDrawer from 'components/Drawer/drawer.cmp';
import { CustomDrawerInnerCnt } from './style';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import useResponsive from 'utils/CustomHooks/useResponsive';
import RequestDetail from 'pages/Requests/RequestDetail/RequestDetail';

function RequestDetailsDrawer({ open, data }) {
  const dispatch = useDispatch();
  const { isMobileDeviceMd } = useResponsive();

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'requestDetails',
      }),
    );
  };

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      size={isMobileDeviceMd ? 'full' : 'large'}
      disableEnforceFocus
    >
      <CustomDrawerInnerCnt>
        <RequestDetail data={data} />
      </CustomDrawerInnerCnt>
    </CustomDrawer>
  );
}

export default RequestDetailsDrawer;
