import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { DialogTitle, Divider, IconButton } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import EyeBallIcon from 'components/Icons/eyeBallIcon';

interface IProps {
  onClose(): void;
}

const VisibilityHeader: FC<IProps> = (props) => {
  const { onClose } = props;
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle sx={{ fontWeight: 700 }}>
          Conditional Visibility
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider />
    </>
  );
};

export default VisibilityHeader;
