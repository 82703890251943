import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getChecklistAutomations = async ({
  workspaceId,
  checklistId,
}: {
  workspaceId?: string;
  checklistId: string;
}) => {
  try {
    const _wsId = workspaceId || getHotelId();
    const automations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${_wsId}/checklists/${checklistId}/automations`,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
      '',
      false,
    );

    return automations?.data?.data;
  } catch (res) {
    return res;
  }
};
export const upsertAutomations = async (data) => {
  try {
    const workspaceId = getHotelId() as string;
    const automations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${workspaceId}/automations`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Automation saved successfully',
      false,
    );

    return automations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteAutomation = async (data) => {
  try {
    const workspaceId = getHotelId() as string;
    const automationRes = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${workspaceId}/automations`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Automation(s) deleted',
      false,
    );

    return automationRes?.data?.data;
  } catch (res) {
    return res;
  }
};

export const runNotificationAutomation = async (data: {
  item: any;
  logId: string;
  attachment: any | null;
  checklistDefaultLocationId: string | null;
}) => {
  const { item, logId, attachment, checklistDefaultLocationId } = data;
  try {
    const workspaceId = getHotelId() as string;
    const automationRes = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${workspaceId}/automations/checklistItem/${logId}/run`,
      {
        checklistItem: item,
        attachment: attachment,
        checklistDefaultLocationId: checklistDefaultLocationId,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return automationRes?.data?.data;
  } catch (res) {
    return res;
  }
};

// conditional visibility checklist  item
export const fetchConditionalVisbility = async ({ checklistId }) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/checklists/${checklistId}/checklist-item-settings`,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
      '',
      false,
    );

    return response?.data;
  } catch (res) {
    return res;
  }
};

export const createConditionalVisbility = async (checkListId, data) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/checklists/${checkListId}/checklist-item-settings`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      null,
      false,
    );

    return response?.data;
  } catch (res) {
    return res;
  }
};

export const updateConditionalVisbility = async (data, id) => {
  try {
    const automations = await callApi(
      config.REACT_APP_BASE_URL + `hotel/checklist-item-settings/${id}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Automation saved successfully',
      false,
    );

    return automations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteConditionalVisibility = async (data) => {
  const { itemId } = data;
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/checklist-item-settings/${itemId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Automation(s) deleted',
      false,
    );

    return response?.data;
  } catch (res) {
    return res;
  }
};
