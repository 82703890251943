// MUI components
import { Divider, Stack } from '@mui/material';
import { HeaderContainer } from './style';
import CustomButton from 'components/Button/CustomButton';
import SaveIcon from 'components/Icons/saveIcon';
import GlobeIcon from 'components/Icons/globeIcon';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';

const defaultInputStyle: React.CSSProperties = {
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32.02px',
  color: '#000000',
  paddingLeft: 0,
};
const mergedInputStyle = { ...defaultInputStyle };

const WorkflowBuilderHeader = ({
  cancelHandler,
  isPublished,
  data,
  updateTitle,
  isCreate,
  createHandler,
  saveChangesHandler,
  publishHandler,
  UnpublishHandler,
  isDirtyChangesForEdit,
  selectedChecklistId,
  selectedApprovalId,
}) => {
  return (
    <HeaderContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: 'unset !important',
          },
        }}
      >
        <CustomInput
          className="title"
          underlineInput={true}
          suppressErrorSpace={true}
          fieldProps={{
            autoFocus: true,
            value: data?.title?.name,
            inputProps: { style: mergedInputStyle },
            placeholder: 'Give your workflow a name',
            onChange: (e) => {
              updateTitle(e.target.value);
            },
          }}
          sx={{ width: '700px' }}
        />
        <Stack direction="row" alignItems="center" gap="10px">
          <CustomButton
            variant="text"
            className="cancelBtn"
            onClick={cancelHandler}
          >
            Cancel
          </CustomButton>

          <Divider
            orientation="vertical"
            sx={{ height: '30px', marginRight: '10px' }}
          />

          {isDirtyChangesForEdit && data?.checklist?.id && (
            <CustomButton
              variant="outlined"
              className="saveChangesBtn"
              startIcon={
                <SaveIcon
                  sx={{
                    path: {
                      stroke: '#4E48FA',
                      fill: 'white',
                    },
                  }}
                />
              }
              onClick={isCreate ? createHandler : saveChangesHandler}
            >
              Save Changes
            </CustomButton>
          )}
          {isPublished ? (
            <CustomButton
              variant="contained"
              className="publishBtn"
              startIcon={
                <DisabledGlobeIcon fontSize="small" style={{ color: 'fff' }} />
              }
              onClick={UnpublishHandler}
            >
              Unpublish
            </CustomButton>
          ) : (
            <CustomButton
              variant="contained"
              className="publishBtn"
              startIcon={
                <GlobeIcon fontSize="small" style={{ color: 'fff' }} />
              }
              onClick={
                isCreate
                  ? () => createHandler(true)
                  : () =>
                      publishHandler(
                        false,
                        selectedChecklistId,
                        selectedApprovalId,
                      )
              }
              disabled={!data?.checklist?.id}
            >
              Publish
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default WorkflowBuilderHeader;
