import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import {
  EditApiRequestType,
  InviteUser,
  ListAssignAdminBodyParam,
  ListBodyParam,
  ProfilePicInterFace,
  UserAdd,
  UserDelete,
} from 'models/index.m';
import { callApi, getHotelId, getItem } from 'utils/globalFunction';

/*******************
  @Purpose : Used for get user listing
  @Parameter :
  @Author : INIC
  ******************/
export const userListing = async (
  ListpostParam: ListBodyParam,
  type: string,
  hotelId: any = '',
): Promise<any> => {
  try {
    let customHeaders = {};
    if (hotelId) {
      customHeaders = {
        'workspace-id': hotelId,
      };
    }

    let apiEndpoint = API_ROUTES.userList;
    if (type === 'task') {
      apiEndpoint = API_ROUTES.userListTask;
    }
    const userList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: (getHotelId() as string) || hotelId,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      customHeaders,
    );

    if (userList !== null) {
      return userList.data ? userList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getUserMeta = async () => {
  try {
    const userMetaInfo = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.userMeta,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return userMetaInfo?.data?.data?.config?.config;
  } catch (res) {
    return res;
  }
};
export const updateUserMeta = async (data) => {
  try {
    const postObj = {
      config: data,
    };
    const userMetaInfo = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.userMeta,
      postObj,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return userMetaInfo?.data?.data?.config?.config;
  } catch (res) {
    return res;
  }
};

export const getUserConfigs = async () => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.configs,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data?.config;
  } catch (res) {
    return res;
  }
};

export const updateUserConfig = async (configData, type, isGlobal = false) => {
  try {
    const postObj = {
      config: configData,
      type,
      isGlobal,
    };
    const response = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.configs,
      postObj,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return response?.data?.data?.config;
  } catch (res) {
    return res;
  }
};

export const getAuthorizedTeams = async (customHotelId = '') => {
  const wsId = getHotelId() || customHotelId;
  try {
    const teams = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${wsId}/authorized/teams`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
    );
    return teams?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getTeams = async (customHotelId = '') => {
  const hotelId = getHotelId() || customHotelId;
  try {
    let customHeaders = {};
    if (customHotelId) {
      customHeaders = {
        'workspace-id': hotelId,
      };
    }

    const teams = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.teams + `/${hotelId}/teams`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      customHeaders,
    );
    return teams?.data?.data;
  } catch (res) {
    return res;
  }
};
export const createTeam = async (data) => {
  const { id, ...postData } = data;
  try {
    const hotelId = getHotelId() as string;
    const teams = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.teams + `/${hotelId}/teams`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Team created successfully',
      true,
    );
    return teams.data;
  } catch (res) {
    return res;
  }
};
export const editTeam = async (data) => {
  const { id, ...postData } = data;
  try {
    const hotelId = getHotelId() as string;
    const teams = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.teams + `/${hotelId}/teams/${id}`,
      postData,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Team updated successfully',
      true,
    );
    return teams.data;
  } catch (res) {
    return res;
  }
};
export const deleteTeam = async (id) => {
  try {
    const hotelId = getHotelId() as string;
    const teams = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.teams + `/${hotelId}/teams/${id}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Team deleted successfully',
      true,
    );
    return teams.data;
  } catch (res) {
    return res;
  }
};
/*******************
  @Purpose : Used for assign admin api call
  @Parameter :
  @Author : INIC
  ******************/
export const userAssignAdminApi = async (
  AssignAdminpostParam: ListAssignAdminBodyParam,
): Promise<any> => {
  try {
    const userAssignAdmin = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.userAssignAdmin +
        getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID),
      {
        ...AssignAdminpostParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userAssignAdmin !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
/*******************
  @Purpose : Used for get user listing
  @Parameter :
  @Author : INIC
  ******************/
export const userListingDepartmentWise = async (
  ListpostParam: any,
): Promise<any> => {
  try {
    const userListDepartment = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.userDepartmentList,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (userListDepartment !== null) {
      return userListDepartment.data ? userListDepartment.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new user
  @Parameter :
  @Author : INIC
  ******************/
export const addNewUser = async (postParam: UserAdd): Promise<any> => {
  try {
    const addService = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.addUser,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addService !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add user profile pic
  @Parameter :
  @Author : INIC
  ******************/
export const addNewUserProfileUpload = async (
  profiledata: ProfilePicInterFace | any,
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', profiledata.file);

  const userProfileResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.updateUserProfilePic,
    formData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );

  if (userProfileResponse !== null) {
    return userProfileResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used for edit.tsx existing service
  @Parameter :
  @Author : INIC
  ******************/
export const editUser = async (
  postParam: UserAdd,
  editId: number,
): Promise<any> => {
  try {
    const editUserData = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.editUser + editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );
    if (editUserData !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for remove user
  @Parameter :
  @Author : INIC
  ******************/
export const removeUser = async (removeData: UserDelete): Promise<any> => {
  try {
    const removeUser = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.removeUser + removeData.id,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeUser !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get user singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const userDetailSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const userSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.userDetailSingleListing +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (userSingleList !== null) {
      return userSingleList.data ? userSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used to get flatFile token
  @Parameter : embedType (USER, ROOM, FLOOR, AREA)
  @Author : Awais - Xenia
  ******************/
export const getFlatFileToken = async (embedType: string): Promise<any> => {
  try {
    const flatFileToken = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.getFlatFileToken +
        `?embedType=${embedType}`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (flatFileToken !== null) {
      return flatFileToken.data ? flatFileToken.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used to create users in bulk via flatfile
  @Parameter : usersData
  @Author : Awais - Xenia
  ******************/
export const createBulkUsers = async (
  usersData: any,
  _hotelId: any = null,
): Promise<any> => {
  const hotelId = _hotelId || getHotelId();
  const createUsersResponse = await callApi(
    config.REACT_APP_BASE_URL + `admin/hotels/${hotelId}/users`,
    usersData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    true,
  );
  if (createUsersResponse !== null) {
    return createUsersResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
 @Purpose : Started New User Management Design APIs
 @Author : Tauqeer Abbas
 ******************/

/*******************
 @Purpose : Used to Delete User
 @Parameter : userId
 @Author : Tauqeer Abbas
 ******************/
export const deleteUserTypeApi = async (userId: UserDelete): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const removeUser = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.base}${HotelId}/users/${userId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeUser !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for changing User type Admin/Normal User
 @Parameter :
 @Author : Tauqeer Abbas
 ******************/
export const updateUserTypeApi = async (
  userId: string,
  roleId: string,
): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const userAssignAdmin = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.base}${HotelId}/users/${userId}/roles/${roleId}/set`,
      null,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userAssignAdmin !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
// Update User Locations
export const updateUserLocations = async (
  userId: string,
  payload = {},
  successMessage = '',
): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}hotel/${HotelId}/locations/${userId}`,
      payload,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      successMessage,
      true,
    );

    if (response !== null) {
      return response?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for changing User status Active/Inactive
 @Parameter : userId, status
 @Author : Tauqeer Abbas
 ******************/
export const updateUserStatusApi = async (
  userId: string,
  status: 'Active' | 'Inactive',
): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const userStatus = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.base}${HotelId}/users/${userId}/status/${status}`,
      null,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userStatus !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Patch API to Update logged In user Profile data from my-settings
 @Parameter :
 @Author : Tauqeer Abbas
 ******************/
export const updateProfileApi = async (
  userId: string,
  values: any,
  picCdnPath: string,
): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const photo = picCdnPath === '' ? {} : { photo: picCdnPath };
    const userStatus = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.base}${HotelId}/users/${userId}`,
      {
        fullName: values.fullName,
        title: values.position,
        emailId: values.email,
        phoneNo: values.phone,
        defaultLocationId: values?.defaultLocationId,
        timezone: values?.timezone,
        ...photo,
        ...(values?.askAgain !== null && { askAgain: values?.askAgain }),
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userStatus !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for add profile picture from my setting view
 @Parameter :
 @Author : Tauqeer Abbas
 ******************/
export const userProfileUploadApi = async (file: any): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  const userProfileResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.updateUserProfilePic,
    formData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );

  if (userProfileResponse !== null) {
    return userProfileResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
 @Purpose : Invite New User
 @Parameter :
 @Author : Tauqeer Abbas
 ******************/
export const inviteNewUserApi = async (postParam: InviteUser): Promise<any> => {
  try {
    const res = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.inviteUser,
      {
        ...postParam,
        HotelId: postParam.hotelId || (getHotelId() as string),
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    return res || null;
  } catch (error) {
    return null;
  }
};

export const createInvitedUserApi = async (payload): Promise<any> => {
  return await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.createInvitedUser,
    payload,
    'post',
    false,
    false,
    '',
    '',
    false,
  );
};

export const resendInviteApi = async ({ userId }): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const addService = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.resendInvite(hotelId, userId),
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addService !== null) {
      return addService;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const verifyEmail = async (body: any): Promise<any> => {
  return await callApi(
    `${config.REACT_APP_BASE_URL}${API_ROUTES.verifyEmail}`,
    { ...body },
    'post',
    false,
    false,
    '',
    '',
    true,
  );
};

export const sendVerificationEmail = async (userId): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const addService = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.sendEmailVerification(hotelId, userId),
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addService !== null) {
      return addService;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const fixStreamUsers = async ({ streamUsers }): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const usersFix = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.fixHotelStreamUsers(hotelId),
      {
        streamUsers,
      },
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (usersFix !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const acceptInvite = async (wsId): Promise<any> => {
  const result = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.acceptInvite(wsId),
    null,
    'put',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );
  if (result !== null) {
    return result?.data?.data;
  } else {
    return null;
  }
};

export const declineInvite = async (wsId): Promise<any> => {
  const result = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.declineInvite(wsId),
    null,
    'delete',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );
  if (result !== null) {
    return result?.data?.data;
  } else {
    return null;
  }
};

export const getAuthorizedUsers = async (hotelId?: string): Promise<any> => {
  const wsId = hotelId || getHotelId();
  try {
    const users = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${wsId}/authorized/users`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (users?.data) {
      return users.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Update user password
 @Parameter :
 @Author : Kazim Hussain
 ******************/
export const updateUserPassword = async (payload: any): Promise<any> => {
  const wsId = getHotelId();
  try {
    const res = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${wsId}/setPassword`,
      payload,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    return res || null;
  } catch (error) {
    return null;
  }
};
export const updateUserProfile = async (
  userId: string,
  values: any,
): Promise<any> => {
  try {
    const HotelId = getHotelId() as string;
    const userStatus = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.base}${HotelId}/users/${userId}`,
      { ...values },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userStatus !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
