// MUI components
import { styled } from '@mui/material/styles';
import { Box, Switch } from '@mui/material';
import { Typography } from '@mui/material';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 0',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
}));

export const TemplateNameAndAttachmentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 12,
  width: '68%',
}));

export const TemplateName = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '800',
  lineHeight: '38px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
}));

export const AttachmentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  height: 36,
  borderRadius: 6,
  background: 'rgba(224, 224, 224, 1)',
  border: '1px solid rgba(189, 189, 189, 1)',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.4px',
  cursor: 'pointer',
  color: 'rgba(66, 66, 66, 1)',
  '& svg': {
    fontSize: 16,
  },
}));

export const DropdownWrapper = styled(Box)(() => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
}));

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
