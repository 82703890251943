import UserAvatar from './userAvatar';
import TeamAvatar from './teamAvatar';
import LocationAvatar from './locationAvatar';
import {
  NameContent,
  SeparatorLine,
  StyledAvatarCnt,
  Title,
  UserStatusChip,
  LocationIconCnt,
  AssigneeRow,
} from './style';
import { AvatarGroup, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import ChildItemIcon from 'components/Icons/childItemIcon';
import LocationIcon from 'components/Icons/locationIcon';
import useUsers from 'utils/CustomHooks/useUsers';
import { userStatusColors } from 'helper/user/userStatus';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';

const defaultProps = {
  isMenuToShow: true,
  width: 28,
  height: 28,
  max: 4,
};

interface IProps {
  data: any;
  assigneesProps?: any;
}

const AvatarsList = (props: IProps) => {
  const { data, assigneesProps } = props;

  const customAssigneesProps = { ...defaultProps, ...assigneesProps };
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);

  const { getUserAvailabilityStatus } = useUsers();

  const teamsList = useMemo(() => {
    return data
      ?.filter((item) => item?.Team)
      ?.map((item) => {
        return {
          ...item,
          Team: {
            ...item?.Team,
            members:
              teamsHash?.[item?.Team?.id]?.Members?.map(
                (item) => item?.MemberId,
              ) ?? [],
          },
        };
      });
  }, [data]);

  const locationsList = useMemo(() => {
    return data
      ?.filter((item) => item?.Location)
      ?.map((item) => {
        return {
          ...item,
          Location: {
            ...item?.Location,
            members:
              locationsHash?.[item?.Location?.id]?.Members?.map(
                (item) => item?.id,
              ) ?? [],
          },
        };
      });
  }, [data]);

  const usersList = useMemo(() => {
    return data
      ?.filter((item) => item?.Assigned)
      ?.map((item) => {
        return {
          ...item,
          Assigned: {
            ...item?.Assigned,
            status: getUserAvailabilityStatus(item?.Assigned?.id),
          },
        };
      });
  }, [data]);

  const rolesList = useMemo(() => {
    return data
      ?.filter((item) => item?.Role)
      ?.map((item) => {
        return {
          ...item,
          Role: {
            ...item?.Role,
          },
        };
      });
  }, [data]);

  return (
    <>
      <StyledAvatarCnt
        width={customAssigneesProps?.width}
        height={customAssigneesProps?.height}
        style={customAssigneesProps?.style}
      >
        <Tooltip
          arrow
          placement="bottom-start"
          PopperProps={{
            sx: {
              zIndex: 1300,
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'white !important',
                boxShadow:
                  'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                minWidth: 300,
                maxHeight: 300,
                overflowY: 'auto',
                borderRadius: '15px',
              },
            },
            ...customAssigneesProps?.popperProps,
          }}
          title={
            <div>
              {usersList?.length > 0 && <Title>People</Title>}
              {usersList?.map((item) => (
                <AssigneeRow
                  key={item?.Assigned?.id}
                  style={{ minHeight: 'unset' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ width: '100%' }}
                    gap="10px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <UserAvatar data={item?.Assigned} />
                      <NameContent>
                        {item?.Assigned?.firstName} {item.Assigned?.lastName}{' '}
                        {!item.Assigned?.firstName &&
                          !item.Assigned?.lastName &&
                          item?.Assigned?.emailId}
                      </NameContent>
                    </Stack>
                    <UserStatusChip
                      style={{
                        background:
                          userStatusColors?.[
                            item?.Assigned?.status?.currentStatus
                          ]?.background,
                      }}
                    >
                      {item?.Assigned?.status?.currentStatus}
                    </UserStatusChip>
                  </Stack>
                </AssigneeRow>
              ))}

              {usersList?.length > 0 && teamsList?.length > 0 && (
                <SeparatorLine />
              )}

              {teamsList?.length > 0 && <Title>Teams</Title>}
              {teamsList?.map((item) => (
                <AssigneeRow
                  key={item?.Team?.id}
                  style={{ minHeight: 'unset' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ width: '100%' }}
                    gap="10px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <TeamAvatar data={item?.Team} />
                      <NameContent>{item?.Team?.name}</NameContent>
                    </Stack>
                    <CustomAvatarGroup members={item?.Team?.members} />
                  </Stack>
                </AssigneeRow>
              ))}

              {locationsList?.length > 0 && teamsList?.length > 0 && (
                <SeparatorLine />
              )}

              {locationsList?.length > 0 && <Title>Locations</Title>}
              {locationsList?.map((item) => (
                <AssigneeRow key={item?.Location?.id}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ width: '100%' }}
                    gap="10px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      height={30}
                    >
                      {item?.Location?.ParentId ? (
                        <LocationIconCnt>
                          <ChildItemIcon
                            sx={{
                              fontSize: 20,
                              color: '#71777ac9',
                            }}
                          />
                        </LocationIconCnt>
                      ) : (
                        <LocationIconCnt>
                          <LocationIcon
                            sx={{
                              fontSize: 20,
                              color: '#78909C',
                            }}
                          />
                        </LocationIconCnt>
                      )}

                      <NameContent>{item?.Location?.name}</NameContent>
                    </Stack>
                    <CustomAvatarGroup members={item?.Location?.members} />
                  </Stack>
                </AssigneeRow>
              ))}

              {rolesList?.length > 0 && locationsList?.length > 0 && (
                <SeparatorLine />
              )}

              {rolesList?.length > 0 && <Title>Roles</Title>}
              {rolesList?.map((item) => (
                <AssigneeRow
                  key={item?.Role?.id}
                  style={{ minHeight: 'unset' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ width: '100%' }}
                    gap="10px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <TeamAvatar data={item?.Role} />
                      <NameContent>{item?.Role?.title}</NameContent>
                    </Stack>
                  </Stack>
                </AssigneeRow>
              ))}
            </div>
          }
        >
          <AvatarGroup
            max={customAssigneesProps?.max}
            className={
              data?.length > customAssigneesProps?.max ? 'assigneeCount' : ''
            }
            style={{
              cursor: 'pointer',
            }}
          >
            {data?.map((item) => {
              if (item?.Assigned) {
                return (
                  <UserAvatar
                    data={item?.Assigned}
                    width={customAssigneesProps?.width}
                    height={customAssigneesProps?.height}
                  />
                );
              }

              if (item?.Team) {
                return (
                  <TeamAvatar
                    data={item?.Team}
                    width={customAssigneesProps?.width}
                    height={customAssigneesProps?.height}
                  />
                );
              }

              if (item?.Role) {
                return (
                  <TeamAvatar
                    data={item?.Role}
                    width={customAssigneesProps?.width}
                    height={customAssigneesProps?.height}
                  />
                );
              }

              if (item?.Location) {
                return (
                  <LocationAvatar
                    data={item?.Location}
                    width={customAssigneesProps?.width}
                    height={customAssigneesProps?.height}
                  />
                );
              }
            })}
          </AvatarGroup>
        </Tooltip>
      </StyledAvatarCnt>
    </>
  );
};

export default AvatarsList;
