import React from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';

interface TaskTitleProps {
  value: string;
  placeholder?: string;
  inputHandlerCallback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  inputStyle?: React.CSSProperties;
}

const defaultInputStyle: React.CSSProperties = {
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32.02px',
  color: '#000000',
  paddingLeft: 0,
};

const TaskTitle: React.FC<TaskTitleProps> = ({
  value,
  placeholder,
  inputHandlerCallback,
  error,
  inputStyle,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputHandlerCallback?.(e);
  };

  const mergedInputStyle = { ...defaultInputStyle, ...inputStyle };

  return (
    <CustomInput
      underlineInput={true}
      suppressErrorSpace={true}
      error={error}
      fieldProps={{
        placeholder,
        inputProps: { style: mergedInputStyle },
        onChange: handleInputChange,
        value,
      }}
    />
  );
};

export default TaskTitle;
