// React
import { useState, Fragment, useEffect } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarDashboard.style';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Utils
import { getReports } from 'api/reporting';
import BarChartIcon2 from 'components/Icons/barChartIcon2';
import { sortBy } from 'lodash';
import { useLocation } from 'react-router-dom';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarDashboards = (props: PropTypes) => {
  const { sidebarOpen } = props;
  const currentPath = useLocation().pathname;

  const [expanded, setExpanded] = useState(false);

  const [predefinedReports, setPredefinedReports] = useState<any>([]);

  const handleClickAccordion = (
    event: React.SyntheticEvent,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  const customOrder = [
    'scheduled-work-summary-table',
    'daily-compliance-report',
    'schedule-report',
    'template-submissions',
    'template-submission',
    'approvals-report',
    'task-summary',
    'employee-productivity',
    'completed-work-report',
  ];

  useEffect(() => {
    if (currentPath?.includes('/dashboards/template')) {
      setExpanded(true);
    }
  }, []);

  const getReportsData = async () => {
    const reports = await getReports();
    if (reports) {
      const sorted = sortBy(reports, (item) => customOrder.indexOf(item?.slug));
      setPredefinedReports(sorted);
    }
  };

  useEffect(() => {
    getReportsData();
  }, []);

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ '& svg': { fontSize: '27px' } }}
          >
            <SidebarItem
              title={'Dashboards'}
              icon={<BarChartIcon2 sx={{ margin: '0px -3px' }} />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList predefinedReports={predefinedReports ?? []} />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper predefinedReports={predefinedReports ?? []} />
      )}
    </Fragment>
  );
};

export default SidebarDashboards;
