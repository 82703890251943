// React
import { useState } from 'react';

// Mui
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Collapse, Stack, Typography } from '@mui/material';

// Custom components
import Note from './Note';

import { NotesCollapseButton } from './styled';

// Types
import { TemplateNoteType } from 'pages/TemplateDashboard/context';
import { FeedbackCollapseButton } from '../StepFeedback/styled';
import FeedbackIcon from 'components/Icons/feedbackIcon';
import StepFeedback from '../StepFeedback';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface TemplateNotesPropTypes {
  notes: TemplateNoteType[];
  feedbacks?: any;
  id?: any;
  feedbackActions?: any;
  isEnableFeedback?: boolean;
  isReviewMode?: boolean;
  canReview?: boolean;
}

const TemplateNotes = ({
  notes,
  feedbacks,
  id,
  feedbackActions,
  isEnableFeedback,
  isReviewMode = false,
  canReview = false,
}: TemplateNotesPropTypes) => {
  const [expandedNotes, setExpandedNotes] = useState(false);
  const { isMobileDeviceMd } = useResponsive();
  const [expandedFeedback, setExpandedFeedback] = useState(isMobileDeviceMd);

  const toggleNotes = () => {
    setExpandedFeedback(false);
    setExpandedNotes(!expandedNotes);
  };

  const toggleFeedback = () => {
    setExpandedNotes(false);
    setExpandedFeedback(!expandedFeedback);
  };

  return (
    <>
      <Stack direction="row" gap={2}>
        {notes?.length > 0 && (
          <NotesCollapseButton onClick={(e) => toggleNotes()}>
            {expandedNotes ? <ExpandLess /> : <ExpandMoreIcon />}
            {expandedNotes ? 'Collapse' : `Notes (${notes.length})`}
          </NotesCollapseButton>
        )}

        {(isEnableFeedback || feedbacks?.length > 0) && (
          <FeedbackCollapseButton onClick={(e) => toggleFeedback()}>
            <FeedbackIcon className="feedbackIcon" />
            <Typography className="title">{`Feedback (${feedbacks?.length})`}</Typography>
            {expandedFeedback ? (
              <ExpandLess className="expansionIcon" />
            ) : (
              <ExpandMoreIcon className="expansionIcon" />
            )}
          </FeedbackCollapseButton>
        )}
      </Stack>

      <Collapse
        sx={{ marginTop: '8px' }}
        in={expandedNotes}
        timeout={'auto'}
        unmountOnExit
      >
        {notes.map((note) => (
          <Note note={note} key={note.id} />
        ))}
      </Collapse>

      <StepFeedback
        feedbacks={feedbacks}
        itemId={id}
        expanded={expandedFeedback}
        feedbackActions={feedbackActions}
        isReviewMode={isReviewMode}
        canReview={canReview}
      />
    </>
  );
};

export default TemplateNotes;
