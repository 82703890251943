import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const ContentCnt = styled(Box)(({ theme }) => ({
  width: 500,
  padding: '36px 24px 10px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30.05px',
    color: '#000000',
    marginBottom: 13,
  },

  '& .descriptionContainer': {
    marginBottom: 22,
  },
  '& .description': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '21.86px',
    color: '#212121',
    textAlign: 'center',
  },

  '& .closeBtn': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '26px',
    color: '#6868FE',
    width: '100%',
    marginBottom: 20,
    borderRadius: 8,
    border: '1px solid #6868FE',
  },

  '& .changeRequestedIcon': {
    color: '#FF9800',
    width: 70,
    height: 70,
    marginBottom: 13,
  },

  '& .approveIcon': {
    width: 70,
    height: 70,
    marginBottom: 13,
  },

  '& .rejectIcon': {
    width: 70,
    height: 70,
    marginBottom: 13,
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: 500,
    width: '100%',
    margin: 'auto',
    height: '100%',
    '& .title': {
      fontWeight: 700,
      fontSize: 18,
    },
    '& .changeRequestedIcon': {
      width: 56,
      height: 56,
    },
    '& .description': {
      fontWeight: 500,
      fontSize: 14,
    },
  },
}));
