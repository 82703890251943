// React
import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistAttachmentPopover from 'components/common/jsxrender/checklistV2/builder/checklistAttachmentPopover';
import Dropdown from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/Dropdown';

// Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Styled
import {
  StyledHeader,
  TemplateNameAndAttachmentWrapper,
  AttachmentBox,
  TemplateName,
  DropdownWrapper,
  CustomSwitch,
} from './styled';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useAttachments } from 'controller/useAttachments';

// Types
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';
import { Stack, Typography } from '@mui/material';
interface HeaderPropTypes {
  data:
    | {
        name: string;
        attachments: string[];
      }
    | undefined;
  isLog?: boolean;
  activeSubmission: any;
  handleFilterSubmissionLogItems: (filterName: LogFilterTypes) => void;
  activeSubmissionItemsFilter: LogFilterTypes;
  toggleConditionalSteps: boolean;
  setToggleConditionalSteps: (e: boolean) => void;
  canReview?: boolean;
}

const Header = ({
  data,
  isLog = false,
  activeSubmission,
  activeSubmissionItemsFilter,
  handleFilterSubmissionLogItems,
  toggleConditionalSteps,
  setToggleConditionalSteps,
  canReview = false,
}: HeaderPropTypes) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { templateAttachments } = useAttachments(data);

  return (
    <StyledHeader>
      <TemplateNameAndAttachmentWrapper>
        <TemplateName>{data?.name}</TemplateName>
        {data?.attachments && data?.attachments?.length > 0 && !isLog && (
          <CustomDropdown
            clickaway
            onClickawayCallback={() => setAnchorEl(null)}
            buttonRenderer={
              <AttachmentBox
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
              >
                <AttachFileIcon />
                {data.attachments.length}
              </AttachmentBox>
            }
            popperProps={{
              sx: {
                width: '512px',
                zIndex: 1400,
              },
              className: 'checklist-attachments',
              placement: 'bottom-start',
              anchorEl,
              open: Boolean(anchorEl),
              content: (
                <ChecklistAttachmentPopover
                  attachments={templateAttachments}
                  handleCloseAttachmentPopover={() => setAnchorEl(null)}
                  showPreviews
                />
              ),
            }}
          />
        )}
      </TemplateNameAndAttachmentWrapper>
      {/* show hide conditional steps */}
      <Stack
        direction="row"
        spacing={1}
        border={'1px solid #E0E0E0'}
        borderRadius={'8px'}
        padding={'0 6px'}
        alignItems={'center'}
        height={'38px'}
      >
        <Typography fontSize="13px" fontWeight={500}>
          Hidden Sections/steps
        </Typography>
        <CustomSwitch
          size="small"
          checked={toggleConditionalSteps}
          onChange={(e) => setToggleConditionalSteps(e.target.checked)}
        />
      </Stack>
      <DropdownWrapper>
        <Dropdown
          activeSubmission={activeSubmission}
          handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
          activeSubmissionItemsFilter={activeSubmissionItemsFilter}
        />
      </DropdownWrapper>
    </StyledHeader>
  );
};

export default Header;
