import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Dialog, Divider } from '@mui/material';

import { v4 as uuidV4 } from 'uuid';
import { cloneDeep, isEqual } from 'lodash';
import xeniaApi from 'api/index';
import CustomDrawer from 'components/Drawer/drawer.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { CustomDrawerFooter, CustomDrawerInnerCnt } from './styled';
import VisibilityHeader from './visibilityHeader';
import VisibilityContent from './visibilityContent';
import CustomButton from 'components/Button/CustomButton';
import { conditionalVisibilityInterface } from './utils/constants';

interface IProps {
  dialogId: string;
}

export const isConditionValid = (
  condition: conditionalVisibilityInterface,
): boolean => {
  if (!condition) return false;
  // Check if contextId is not null
  if (!condition.contextId) {
    return false;
  }
  // Check if condition has logic and value
  if (condition.context === 'isFilled') {
    if (
      !condition.condition ||
      !condition.condition.logic ||
      !condition.condition.value
    ) {
      return false;
    }
  }
  // Check if action has a value
  if (!condition.action) {
    return false;
  }
  // If all checks pass, return true
  return true;
};
const ChecklistVisibilityDrawer: FC<IProps> = (props) => {
  const { dialogId } = props;
  const dialogSlice = useSelector(selectors.getDialogState);
  const [loading, setLoading] = useState(false);
  const { isMobileDeviceMd } = useResponsive();
  const [automations, setAutomations] =
    useState<conditionalVisibilityInterface>();
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;
  const {
    entity,
    field,
    onClose,
    checklistId,
    ordreHash,
    conditions: conditionsData = [],
    checkListItems,
  } = dialogData || {};
  const isNewCondition = useMemo(() => {
    return !conditionsData;
  }, [dialogData]);
  useEffect(() => {
    if (conditionsData?.length) {
      setAutomations(conditionsData[0]);
    } else {
      const initialAutomation = {
        context: 'isFilled',
        contextId: null,
        condition: { logic: 'eq', value: '' },
        action: 'SHOW',
        itemIds: [field.id],
      };
      setAutomations(initialAutomation);
    }
  }, []);

  const onChange = useCallback(
    (value) => {
      setAutomations((prevAutomations) => {
        const temp = { ...prevAutomations, ...value };
        return temp;
      });
    },
    [setAutomations],
  );

  const handleSaveConditionalVisibility = async () => {
    setLoading(true);
    await xeniaApi.createConditionalVisbility(checklistId, {
      ...automations,
    });
    setLoading(false);
    onClose(automations);
  };

  const handleUpdateCondtionallyVisibility = async () => {
    setLoading(true);

    const payload = {
      itemId: automations?.itemIds,
      contextId: automations?.contextId,
      context: automations?.context,
      condition: automations?.condition,
      action: automations?.action,
    };
    await xeniaApi.updateConditionalVisbility(payload, automations?.id);
    setLoading(false);
    onClose(automations);
  };

  return (
    <CustomDrawer
      open={dialogState?.open}
      onClose={onClose}
      size={isMobileDeviceMd ? 'full' : 'medium'}
      disableEnforceFocus
    >
      <VisibilityHeader onClose={onClose} />
      <CustomDrawerInnerCnt>
        {automations && (
          <VisibilityContent
            automations={automations}
            checkListItems={checkListItems}
            entity={entity}
            field={field}
            ordreHash={ordreHash}
            onChange={onChange}
          />
        )}
      </CustomDrawerInnerCnt>
      <CustomDrawerFooter>
        <CustomButton onClick={onClose} variant={'outlined'}>
          Cancel
        </CustomButton>
        {isNewCondition ? (
          <CustomButton
            variant={'contained'}
            buttonLoader={loading}
            disabled={!automations || !isConditionValid(automations)}
            onClick={handleSaveConditionalVisibility}
          >
            Save
          </CustomButton>
        ) : (
          <CustomButton
            variant={'contained'}
            buttonLoader={loading}
            disabled={!automations || !isConditionValid(automations)}
            onClick={handleUpdateCondtionallyVisibility}
          >
            Update
          </CustomButton>
        )}
      </CustomDrawerFooter>
    </CustomDrawer>
  );
};

export default ChecklistVisibilityDrawer;
