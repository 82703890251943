// React
import { useState, useEffect, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import { getLocationsState } from 'store/selectors/locations';

// Components
import { Box, Stack, Divider, Typography } from '@mui/material';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import TaskDetailSkeleton from 'components/common/jsxrender/task/TaskDetailSkeleton';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import TaskDetailTabs from './Tabs';
import AvatarPopover from 'components/AvatarPopover';
import RequestActivityTimeline from 'components/RequestActivityTimeline';
import { HasPermission } from 'components/HasPermission';
import { AnonymousAvatar } from 'components/ChecklistPreview/styled';

// Utils
import { DATE_FORMAT, appRoutes } from 'utils/globalVariable';
import { formatDate, getLocationById, getTimeZone } from 'utils/globalFunction';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DateTime from 'utils/DateTime';
import { COLORS_LIST, PERMISSIONS } from 'utils/constants';
import { requestIcons } from 'pages/Requests/RequestListing/RequestListingItem';
import GlobeIcon from 'components/Icons/globeIcon';

// Hooks
import useRequests from 'pages/Requests/useRequests';
import useResponsive from 'utils/CustomHooks/useResponsive';

// Styles
import {
  TaskDetailCnt,
  RequestorInfoField,
  RequestorInfoFieldHeading,
  RequestorInfoFieldValue,
} from './TaskDetail.style';
import { CategoryChip } from 'components/Dropdown/Task/Category/taskCategoryDropdown.style';
import UserAvatar from 'components/Avatar';

function RequestDetailView({
  paddingTop,
  requestId,
  request,
  activeTab = 'detail',
}) {
  const dispatch = useDispatch();
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const locationsList = useSelector(getLocationsState);
  const tz: string = getTimeZone();
  const loaders = useSelector(selectors.getLoaders);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { getSingleRequest } = useRequests();

  const [selectedTab, setSelectedTab] = useState(activeTab);
  const [currentRequest, setCurrentRequest] = useState<any | null>(null);

  const channelId = `task_comments_${requestId}` as any;
  const currentCommentChannel = commentChannelsHash?.[channelId] || null;

  const {
    isMobileDeviceXs,
    isMobileDeviceMd,
    isMobileDeviceLg,
    isMobileDeviceXl,
  } = useResponsive();

  useEffect(() => {
    getSingleRequest(requestId).then((res) => {
      setCurrentRequest(res);
    });
  }, [requestId, request]);

  const formatTaskDates = (date) => {
    const fullFormat = 'MMM DD YYYY [at] hh:mm A';
    const timeOnly = '[at] hh:mm A';
    let dayString = '';

    const isToday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime().toTz(tz).toTzString(tz, DATE_FORMAT);
    const isYesterday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime()
        .toTz(tz)
        .transform({ subtract: [1, 'day'] })
        .toTzString(tz, DATE_FORMAT);

    if (isToday) {
      dayString = 'Today';
    } else if (isYesterday) {
      dayString = 'Yesterday';
    }
    return dayString.length
      ? `${dayString} ${formatDate(date, timeOnly, '', tz)}`
      : formatDate(date, fullFormat, '', tz);
  };

  const getFullName = (user, isTeam) => {
    return isTeam ? `${user.name}` : `${user?.firstName} ${user?.lastName}`;
  };

  const renderCreatorAndAssignee = (data, isTeam = false, isBold = true) => {
    if (data) {
      const name = request?.Creator?.fullName ?? 'Anonymous';
      if (request.isPublic)
        return (
          <Stack alignItems={'center'} direction={'row'} gap={'8px'}>
            <UserAvatar
              width={24}
              height={24}
              firstName={name}
              avatarSx={{
                backgroundColor: 'rgba(96,125,139,1)',
              }}
            />
            <div>{name}</div>
          </Stack>
        );
      return (
        <div className="avtar-wraper">
          <div className="mr-8" style={{ position: 'relative' }}>
            {isTeam ? (
              <TeamAvatar width={28} height={28} teamId={data?.id} />
            ) : (
              <AvatarPopover data={data} />
            )}
          </div>
          <div className={`${isBold ? 'font-weight-700' : ''}`}>
            {isTeam && <div>{getFullName(data, isTeam)}</div>}
          </div>
        </div>
      );
    }
  };

  const closeTaskDetail = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const handleViewLocationClick = () => {
    closeTaskDetail();
    const locationId =
      currentRequest?.Location?.id || currentRequest?.Asset?.Location?.id;
    const parentLocationId =
      currentRequest?.Location?.ParentId ||
      currentRequest?.Asset?.Location?.ParentId;
    const urlToNavigate = parentLocationId
      ? `/${parentLocationId}/sl/${locationId}`
      : `/${locationId}`;
    navigateWithWorkspaceUrl(appRoutes.locations + urlToNavigate);
  };
  const handleViewAssetClick = () => {
    closeTaskDetail();
    const urlToNavigate = currentRequest?.Asset?.ParentId
      ? `/${currentRequest?.Asset?.ParentId}/sa/${currentRequest?.Asset?.id}`
      : `/${currentRequest?.Asset?.id}`;
    navigateWithWorkspaceUrl(appRoutes.assets + urlToNavigate);
  };

  const getTaskDetailModel = () => {
    const { taskDetails } = loaders;
    const { Creator } = currentRequest ?? {};

    const selectedCategory = useMemo(() => {
      if (currentRequest?.ServiceType?.id) {
        return COLORS_LIST?.find(
          (item) => item?.color === currentRequest?.ServiceType?.color,
        );
      }
      return null;
    }, [currentRequest]);
    const selectedLocationObj = getLocationById(
      currentRequest?.Location?.id || currentRequest?.Asset?.Location?.id,
      locationsList,
    );
    const { color, background, icon, border } =
      requestIcons[currentRequest?.status] || {};

    const hasRequestorInfo = useMemo(() => {
      if (!Creator) return false;
      const { fullName, emailId, phoneNo } = Creator;
      return fullName || emailId || phoneNo;
    }, [currentRequest]);

    if (taskDetails?.isLoading) {
      return <TaskDetailSkeleton />;
    } else {
      return (
        <TaskDetailCnt>
          <div className="common-tab-wrap">
            <div style={{ paddingTop }}>
              <TaskDetailTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                currentRequest={currentRequest}
                currentCommentChannel={currentCommentChannel}
              />
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active">
                {selectedTab === 'detail' && (
                  <Stack>
                    <Stack>
                      <div className="details-wrap">
                        <div className="room-description">
                          <div className="info-item frame mb-4">
                            <div className="titlebox">
                              <div>Status</div>
                            </div>
                            <div className="info">
                              <div
                                className="requestStatus"
                                style={{
                                  border: `1px solid ${border}`,
                                  background: background,
                                }}
                              >
                                <span
                                  className={'statusIcon'}
                                  style={{ color }}
                                >
                                  {icon}
                                </span>
                                <span className="statusText" style={{ color }}>
                                  {currentRequest?.status}
                                </span>
                              </div>
                            </div>
                          </div>

                          {currentRequest?.description && (
                            <div className="info-item frame mb-4">
                              <div className="titlebox">
                                <div>Description</div>
                              </div>
                              <div className="info">
                                <div className="description-text">
                                  {currentRequest?.description}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="info-item frame mb-4">
                            <div className="titlebox">
                              <div>Created by</div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="item-value">
                                {renderCreatorAndAssignee(
                                  currentRequest?.Creator,
                                  false,
                                  true,
                                )}
                              </div>
                              <div className="mx-1">•</div>
                              <div>
                                {formatTaskDates(currentRequest?.createdAt)}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center mb-4">
                            {currentRequest?.Category && (
                              <div className="info-item frame">
                                <div className="titlebox">
                                  <div>Category</div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <CategoryChip
                                    bg={currentRequest?.Category?.color}
                                    color={selectedCategory?.textColor}
                                    shouldDisplayFlex={true}
                                  >
                                    {currentRequest?.Category?.type
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      currentRequest?.Category?.type?.slice(1)}
                                  </CategoryChip>
                                </div>
                              </div>
                            )}
                            <div className="info-item frame">
                              <div
                                className={`${
                                  currentRequest?.Category
                                    ? 'mini-titlebox mr-3'
                                    : 'titlebox'
                                }`}
                              >
                                <div>Priority</div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="item-value d-flex align-items-center">
                                  <div
                                    className={`mr-2 mt-1 priority-span icon-priority-${currentRequest?.priority?.toLowerCase()}`}
                                  ></div>
                                  {currentRequest?.priority}
                                </div>
                              </div>
                            </div>
                          </div>
                          {(currentRequest?.Location ||
                            currentRequest?.Asset?.Location) && (
                            <div className="info-item frame mb-4">
                              <div className="titlebox">
                                <div>Location</div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="item-value mr-3">
                                  {currentRequest?.Location?.name ||
                                    currentRequest?.Asset?.Location?.name}
                                </div>

                                {selectedLocationObj &&
                                  globalThis?.platform !== 'mobile' && (
                                    <HasPermission
                                      permissions={[
                                        PERMISSIONS.CAN_MANAGE_LOCATIONS,
                                      ]}
                                    >
                                      <Box
                                        sx={{
                                          fontSize: '13px',
                                          fontWeight: 500,
                                          color: '#6868FE',
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        }}
                                        className=""
                                        onClick={() =>
                                          handleViewLocationClick()
                                        }
                                      >
                                        {'View Location Detail'}
                                      </Box>
                                    </HasPermission>
                                  )}
                              </div>
                            </div>
                          )}
                          {currentRequest?.Asset && (
                            <div className="info-item frame mb-4">
                              <div className="titlebox">
                                <div>Asset</div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="item-value mr-3">
                                  {currentRequest?.Asset?.name}
                                </div>
                                {globalThis.platform !== 'mobile' && (
                                  <HasPermission
                                    permissions={[
                                      PERMISSIONS.CAN_MANAGE_ASSETS,
                                    ]}
                                  >
                                    <Box
                                      sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: '#6868FE',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      className=""
                                      onClick={() => handleViewAssetClick()}
                                    >
                                      {'View Asset Detail'}
                                    </Box>
                                  </HasPermission>
                                )}
                              </div>
                            </div>
                          )}

                          {!!currentRequest?.attachments?.length && (
                            <div className="info-item frame mb-4">
                              <div className="titlebox">
                                <div>Attachments</div>
                              </div>
                              <Stack
                                maxWidth={
                                  isMobileDeviceXs
                                    ? '300px'
                                    : isMobileDeviceMd
                                    ? '400px'
                                    : isMobileDeviceLg
                                    ? '400px'
                                    : isMobileDeviceXl
                                    ? '400px'
                                    : '600px'
                                }
                              >
                                <RenderAttachments
                                  style={{ flexWrap: 'wrap' }}
                                  attachments={currentRequest?.attachments}
                                />
                              </Stack>
                            </div>
                          )}
                        </div>
                      </div>
                    </Stack>
                    {hasRequestorInfo && (
                      <Stack mx={'40px'}>
                        <Divider
                          sx={{
                            my: '6px',
                          }}
                        />
                        <Stack gap={'24px'}>
                          <Typography
                            fontSize={16}
                            fontWeight={700}
                            color="#212121"
                            mt={'8px'}
                          >
                            Requester Information
                          </Typography>
                          <RequestorInfoField>
                            <RequestorInfoFieldHeading>
                              Name
                            </RequestorInfoFieldHeading>
                            <Stack
                              direction={'row'}
                              gap={'8px'}
                              alignItems={'center'}
                            >
                              {Creator?.fullName && (
                                <UserAvatar
                                  width={24}
                                  height={24}
                                  firstName={Creator.fullName}
                                  avatarSx={{
                                    backgroundColor: 'rgba(96,125,139,1)',
                                  }}
                                />
                              )}
                              {!Creator?.fullName && (
                                <AnonymousAvatar>
                                  <GlobeIcon
                                    style={{ color: '#fff', fontSize: '16px' }}
                                  />
                                </AnonymousAvatar>
                              )}

                              <RequestorInfoFieldValue bold={'true'}>
                                {Creator?.fullName || 'Anonymous'}
                              </RequestorInfoFieldValue>
                            </Stack>
                          </RequestorInfoField>
                          <RequestorInfoField>
                            <RequestorInfoFieldHeading>
                              Email Address
                            </RequestorInfoFieldHeading>
                            <RequestorInfoFieldValue>
                              {Creator?.emailId || 'N/A'}
                            </RequestorInfoFieldValue>
                          </RequestorInfoField>
                          <RequestorInfoField>
                            <RequestorInfoFieldHeading>
                              Phone Number
                            </RequestorInfoFieldHeading>
                            <RequestorInfoFieldValue>
                              {Creator?.phoneNo || 'N/A'}
                            </RequestorInfoFieldValue>
                          </RequestorInfoField>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                )}
                {selectedTab === 'activity' && (
                  <RequestActivityTimeline currentRequest={currentRequest} />
                )}
              </div>
            </div>
          </div>
        </TaskDetailCnt>
      );
    }
  };

  return <TaskDetailCnt>{getTaskDetailModel()} </TaskDetailCnt>;
}

export default RequestDetailView;
