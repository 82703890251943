/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import { Reducer } from 'redux';
import actionTypes from 'store/actionTypes';
import { Action, RequestsManagementStateModel } from 'models/index.m';
import { initialRequestsManagementStateData } from 'store/initialStateData';

type requestsAction = Action<
  | RequestsManagementStateModel
  | RequestsManagementStateModel[]
  | null
  | boolean
  | string
>;

export const requestsListReducer: Reducer<
  RequestsManagementStateModel,
  requestsAction
> = (
  requestsData = initialRequestsManagementStateData,
  action: requestsAction | null = null,
) => {
  if (!action) {
    return requestsData;
  }

  return produce(requestsData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_REQUESTS_LIST_FILTERS:
        draft.requestsListFilters = action.payload;
        break;
      case actionTypes.SET_IS_FILTERS_OR_SEARCH_IN_REQUESTS:
        draft.isFiltersORSearch = <boolean>action.payload;
        break;
      default:
        return draft;
    }
  });
};
