// Custom components
import ChecklistLogReportOverview from 'components/ChecklistReport/ChecklistLogReportOverview';
import HeaderItem from 'components/ChecklistReport/HeaderItem';
import ChecklistLogReportAppendix from 'components/ChecklistReport/ChecklistLogReportAppendix';
import CorrectiveTasks from 'components/ChecklistReport/correctiveTasks';
import FlaggedItems from 'components/ChecklistReport/flaggedItems';
import ScoreSummary from 'components/ChecklistReport/scoreSummary';
import GlobalNotes from 'components/ChecklistReport/GlobalNotes';

const Report = ({
  headerItems,
  checklistLog,
  checkListLogHashes,
  hotelName,
  sectionRefs,
  logItems,
  flaggedItems,
  correctiveTaskCount,
  appendixAttachmentsData,
  correctiveTaskLogItems,
  isPublic,
  conditions = null,
}) => {
  const isScoring = checklistLog?.Checklist?.isScoring;
  return (
    <>
      <ChecklistLogReportOverview
        checklistLog={checklistLog}
        hotelName={hotelName}
        reference={sectionRefs[0]}
        correctiveTaskCount={correctiveTaskCount}
        flaggedItemCount={flaggedItems.length}
        isPublic={isPublic}
      />

      {checklistLog?.Checklist?.isScoring ? (
        <ScoreSummary
          log={checklistLog}
          items={headerItems}
          reference={sectionRefs[1]}
          template={checklistLog.Checklist}
        />
      ) : null}

      {headerItems?.map((headerItem: any, headerIndex: any) => {
        return (
          <HeaderItem
            headerItem={headerItem}
            headerIndex={headerIndex}
            logItems={logItems}
            sectionRefs={sectionRefs}
            template={checklistLog.Checklist}
            checklistLog={checklistLog}
            isPublic={isPublic}
            isScoring={isScoring}
            flaggedCategories={checklistLog?.flagCategories}
            conditions={conditions}
            checkListLogHashes={checkListLogHashes}
          />
        );
      })}
      {!!checklistLog?.TaskChecklistNotes?.length && (
        <GlobalNotes globalNotes={checklistLog.TaskChecklistNotes} />
      )}
      <CorrectiveTasks
        items={correctiveTaskLogItems}
        reference={sectionRefs[headerItems.length + isScoring ? 2 : 1]}
        template={checklistLog.Checklist}
      />
      <FlaggedItems
        items={flaggedItems}
        reference={sectionRefs[headerItems.length + isScoring ? 3 : 2]}
        template={checklistLog.Checklist}
        flaggedCategories={checklistLog?.flagCategories}
      />
      <ChecklistLogReportAppendix
        appendixAttachmentsData={appendixAttachmentsData}
        reference={sectionRefs[headerItems.length + isScoring ? 4 : 3]}
      />
    </>
  );
};

export default Report;
