import moment from 'moment-timezone';

export const getLogDuration = (log: any) => {
  const startDate = moment(log?.createdAt);
  const endDate = moment(log?.lastItemUpdatedAt);

  const duration = moment.duration(endDate.diff(startDate));

  if (duration.asMilliseconds() === 0) {
    return null;
  }

  const parts: string[] = [];

  if (duration.years() > 0) {
    parts.push(
      `${duration.years()} ${duration.years() === 1 ? 'year' : 'years'}`,
    );
  }
  if (duration.months() % 12 > 0) {
    parts.push(
      `${duration.months() % 12} ${
        duration.months() % 12 === 1 ? 'month' : 'months'
      }`,
    );
  }
  if (duration.days() % 30 > 0) {
    parts.push(
      `${duration.days() % 30} ${duration.days() % 30 === 1 ? 'day' : 'days'}`,
    );
  }
  if (duration.hours() % 24 > 0) {
    parts.push(
      `${duration.hours() % 24} ${
        duration.hours() % 24 === 1 ? 'hour' : 'hours'
      }`,
    );
  }
  if (duration.minutes() % 60 > 0) {
    parts.push(
      `${duration.minutes() % 60} ${
        duration.minutes() % 60 === 1 ? 'minute' : 'minutes'
      }`,
    );
  }
  if (duration.seconds() % 60 > 0 && parts.length === 0) {
    parts.push(
      `${duration.seconds() % 60} ${
        duration.seconds() % 60 === 1 ? 'second' : 'seconds'
      }`,
    );
  }

  return parts.slice(0, 2).join(' ');
};

export const STEP_TYPES = {
  HEADER: 'header',
  PASS_FAIL: 'passFail',
  PROCEDURE: 'procedure',
  TAKE_PHOTO: 'takePhoto',
  TEXT_FIELD: 'textField',
  NUMBER: 'number',
  TEMPERATURE: 'temperature',
  COST: 'cost',
  MULTIPLE_CHOICE: 'multipleChoice',
  SIGNATURE: 'signature',
  DATE_TIME: 'dateTime',
  INSTRUCTION: 'instruction',
  DROPDOWN: 'dropdown',
  GEO_STAMP: 'geoStamp',
  LOCATION: 'location',
  PHOTO_ANNOTATE: 'photoAnnotate',
};

export const LEGAL_FILE_UPLOAD_TYPES = {
  'image/*': ['jpg', 'jpeg', 'png'],
  'application/*': ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  'video/*': ['mp4', 'mov', 'avi', 'flv', 'wmv'],
  'text/*': [
    'txt',
    'csv',
    'json',
    'xml',
    'html',
    'css',
    'js',
    'ts',
    'tsx',
    'jsx',
    'md',
  ],
  'audio/*': ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a'],
};
