import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { isNil } from 'lodash';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  panelProps?: any;
  onDemand?: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, panelProps, onDemand, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {onDemand ? (
        value === index && (
          <Box
            sx={{ px: 0, py: 2, display: value !== index ? 'none' : '' }}
            {...panelProps}
          >
            {children}
          </Box>
        )
      ) : (
        <Box
          sx={{ px: 0, py: 2, display: value !== index ? 'none' : '' }}
          {...panelProps}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ICTabs {
  data: {
    label: string;
    content: any;
    panelProps?: any;
    tabProps?: any;
    icon?: React.ReactElement | null;
  }[];
  labelProps?: any;
  activeTab?: number;
  onTabChangeCb?: (activeTab: number) => void;
  callBack?: any;
  variant?: 'scrollable' | 'fullWidth' | 'standard';
  scrollButtons?: 'auto' | false | true;
  allowScrollButtonsMobile?: boolean;
  rootSx?: any;
  onDemand?: boolean;
  centered?: boolean;
  component?: any;
}

const CTabs: React.FC<ICTabs> = ({
  data,
  labelProps,
  callBack,
  activeTab,
  onTabChangeCb,
  variant = 'scrollable',
  scrollButtons = 'auto',
  allowScrollButtonsMobile = true,
  rootSx = {},
  onDemand = false,
  centered = false,
  component = null,
}) => {
  const [value, setValue] = React.useState(0);
  const { isMobileDeviceSm } = useResponsive();
  React.useEffect(() => {
    !isNil(activeTab) && setValue(activeTab);
  }, [activeTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChangeCb && onTabChangeCb(newValue);
    if (callBack) callBack(newValue);
  };

  return (
    <Box sx={{ width: { sm: 'calc(100vw - 52px)', md: '100%' } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{
          textTransform: 'capitalize',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          ...rootSx,
        }}
        variant={variant}
        scrollButtons={scrollButtons}
        allowScrollButtonsMobile={allowScrollButtonsMobile}
        centered={centered}
      >
        {data.length > 0 &&
          data.map((tab, idx) => (
            <Tab
              key={tab.label}
              label={<span {...labelProps}>{tab.label}</span>}
              iconPosition="start"
              icon={tab?.icon}
              sx={{
                textTransform: 'capitalize',
              }}
              {...tab.tabProps}
              {...a11yProps(idx)}
            />
          ))}
        {component}
      </Tabs>

      {data.length > 0 &&
        data.map((tab, idx) => (
          <TabPanel
            key={idx}
            value={value}
            index={idx}
            panelProps={tab?.panelProps ?? {}}
            onDemand={onDemand}
          >
            {typeof tab.content == 'function' ? tab.content() : tab.content}
          </TabPanel>
        ))}
    </Box>
  );
};

export default CTabs;
