import { combineReducers } from 'redux';
import { authReducer } from 'store/reducers/authReducer';
import { tagReducer } from 'store/reducers/tagReducer';
import { serviceReducer } from 'store/reducers/serviceReducer';
import { userReducer } from 'store/reducers/userReducer';
import { taskListReducer } from 'store/reducers/taskListReducer';
import { requestsListReducer } from 'store/reducers/requestsListReducer';
import { taskReducer } from 'store/reducers/taskReducer';
import { propertyLayoutReducer } from 'store/reducers/propertyLayoutReducer';
import { checklistV2Reducer } from 'store/reducers/checklistV2Reducer';
import { messagesReducer } from 'store/reducers/messagesReducer';
import { notificationReducer } from 'store/reducers/notificationReducer';
import { loaderReducer } from 'store/reducers/loaderReducer';
import { teamsReducer } from 'store/reducers/teamsReducer';
import { commonReducer } from 'store/reducers/commonReducer';
import { dialogReducer } from 'store/reducers/dialogReducer';
import { timezoneReducer } from 'store/reducers/timezoneReducer';
import { workspaceReducer } from 'store/reducers/workspaceReducer';
import { appStateReducer } from 'store/reducers/appStateReducer';
import { locationsReducer } from 'store/reducers/locationsReducer';
import { topAlertReducer } from 'store/reducers/topAlertReducer';
import { billingReducer } from 'store/reducers/billingReducer';
import { calendlyReducer } from 'store/reducers/calendlyReducer';
import { paywallReducer } from './paywallReducer';
import { userConfigsReducer } from 'store/reducers/userConfigsReducer';
import { assetsReducer } from 'store/reducers/assetsReducer';
import { savedOptionsReducer } from 'store/reducers/savedOptionsReducer';
import { sidebarConfigReducer } from 'store/reducers/sidebarConfigReducer';
import { workspaceConfigReducer } from 'store/reducers/workspaceConfigReducer';
import { locationsLevelsReducer } from 'store/reducers/locationsLevelsReducer';
import { taskTemplatesReducer } from 'store/reducers/taskTemplatesReducer';
import { projectsReducer } from 'store/reducers/projectsReducer';
import { schedulesReducer } from 'store/reducers/schedulesReducer';
import { flaggedItemsCategoriesReducer } from 'store/reducers/flaggedItemsCategoriesReducer';
import { permissionsReducers } from 'store/reducers/permissionsReducers';
import { rolesReducer } from 'store/reducers/rolesReducer';
import { uploadReducer } from './uploadReducer';
import { locationGroupsReducer } from './locationGroupsReducer';
import { workflowsReducer } from 'store/reducers/workflowsReducers';
import { navigationReducer } from './navigationReducer';

export const rootReducer = combineReducers({
  authData: authReducer,
  userConfigs: userConfigsReducer,
  loaderData: loaderReducer,
  commonData: commonReducer,
  tagAdminData: tagReducer,
  serviceAdminData: serviceReducer,
  userAdminData: userReducer,
  propertyLayoutAdminData: propertyLayoutReducer,
  taskHotelData: taskListReducer,
  requestsData: requestsListReducer,
  currentTaskData: taskReducer,
  checklistV2Data: checklistV2Reducer,
  messagesData: messagesReducer,
  notificationData: notificationReducer,
  dialogData: dialogReducer,
  upladData: uploadReducer,
  topAlertData: topAlertReducer,
  teams: teamsReducer,
  timezones: timezoneReducer,
  workspace: workspaceReducer,
  appState: appStateReducer,
  locationsState: locationsReducer,
  locationGroups: locationGroupsReducer,
  billing: billingReducer,
  calendly: calendlyReducer,
  paywallUsage: paywallReducer,
  assetsState: assetsReducer,
  savedOptions: savedOptionsReducer,
  sidebarConfig: sidebarConfigReducer,
  workspaceConfig: workspaceConfigReducer,
  locationsLevels: locationsLevelsReducer,
  taskTemplates: taskTemplatesReducer,
  projects: projectsReducer,
  schedules: schedulesReducer,
  flaggedItemsCategories: flaggedItemsCategoriesReducer,
  permissions: permissionsReducers,
  roles: rolesReducer,
  workflows: workflowsReducer,
  navigation: navigationReducer,
});
