// React
import AssignToUsersAndTeams from 'components/TaskInputs/assignTo/usersAndTeams';
import useEmailTriggers from 'components/TemplateDashboard/TemplateSettingsModal/hooks/useEmailTriggers';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// Utils
import selectors from 'store/selectors';
import {
  EmailTriggerType,
  RecipientsType,
} from 'components/TemplateDashboard/TemplateSettingsModal/types';

interface EmailInputPropTypes {
  defaultUsers?: EmailTriggerType[];
  showSelectedLimit?: number;
}

const UsersInput = ({
  defaultUsers,
  showSelectedLimit,
}: EmailInputPropTypes) => {
  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const roles = useSelector(selectors.getRoles);
  const { hashRoles } = roles;
  const { addEmailTrigger, editEmailTrigger } = useEmailTriggers();
  const [users, setUser] = useState([]);

  const recipientsList = useMemo(() => {
    if (defaultUsers?.length) {
      return defaultUsers.find((u) => u.type === 'RECIPIENTS');
    }
    return null;
  }, [defaultUsers]);

  const handleRemoveUser = async (e, user) => {
    e.stopPropagation();
    const filteredUserList = users.filter((item) => item !== user);
    handleSelectAssignee(filteredUserList);
  };

  const handleSelectAssignee = async (user) => {
    setUser(user);
    if (recipientsList && recipientsList.id) {
      handleUpdateUser(user, recipientsList.id);
    } else {
      handleCreateUser(user);
    }
  };

  const transFormData = (data) => {
    const recipientsList: RecipientsType[] = [];
    if (data?.length > 0) {
      for (let index = 0; index <= data?.length - 1; index++) {
        const people = usersHash?.[data?.[index]];
        if (people) {
          recipientsList?.push({
            id: data?.[index],
            type: 'user',
          });
          continue;
        }

        const team = teamsHash?.[data?.[index]];
        if (team) {
          recipientsList?.push({
            id: data?.[index],
            type: 'team',
          });
          continue;
        }

        const role = hashRoles?.[data?.[index]];
        if (role) {
          recipientsList?.push({
            id: data?.[index],
            type: 'role',
          });
          continue;
        }
      }
    }
    return recipientsList ?? [];
  };
  const handleCreateUser = async (user) => {
    const usersList = transFormData(user);

    const added = await addEmailTrigger({
      recipients: usersList,
      type: 'RECIPIENTS',
    });
  };
  const handleUpdateUser = async (user, triggerId) => {
    const usersList = transFormData(user);
    const added = await editEmailTrigger(
      { recipients: usersList, type: 'RECIPIENTS' },
      triggerId,
    );
  };
  useEffect(() => {
    if (recipientsList && recipientsList?.recipients) {
      const list = recipientsList?.recipients?.map((recipient) => {
        return recipient.id;
      });
      setUser(list);
    }
  }, [recipientsList]);
  return (
    <>
      <AssignToUsersAndTeams
        handleChangeCallback={handleSelectAssignee}
        currentSelected={users}
        hideUsersAndTeamsLocations={true}
        hideRoles={false}
        hideAll={true}
        hideUsersAndTeamsIcon={true}
        hideActions={true}
        popperProps={{
          disablePortal: false,
          style: {
            zIndex: 1300,
            width: 590,
          },
        }}
        assigneesProps={{
          clearOption: true,
          removeHandler: (e, user) => handleRemoveUser(e, user),
          style: {
            background: '#EBEBEB',
            border: 'none',
            padding: 5,
          },
        }}
        placeholderUsersAndTeams="Select users, teams or roles"
        showSelectedLimit={showSelectedLimit}
      />
    </>
  );
};

export default UsersInput;
