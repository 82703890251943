import React, { FC } from 'react';
import { Typography } from '@mui/material';
import ProcedureIcon from 'components/Icons/templateStepIcons2/procedureIcon';
import PassFailIcon from 'components/Icons/templateStepIcons2/passFailIcon';
import TakePhotoIcon from 'components/Icons/templateStepIcons2/takePhotoIcon';
import NumberIcon from 'components/Icons/templateStepIcons2/numberIcon';
import TemperatureIcon from 'components/Icons/templateStepIcons2/temperatureIcon';
import CostIcon from 'components/Icons/templateStepIcons2/costIcon';
import McqIcon from 'components/Icons/templateStepIcons2/mcqIcon';
import SignatureIcon from 'components/Icons/templateStepIcons2/signatureIcon';
import DateTimeIcon from 'components/Icons/templateStepIcons2/dateTimeIcon';
import LongDescriptionIcon from 'components/Icons/templateStepIcons2/longDescriptionIcon';
import GeoStampIcon from 'components/Icons/templateStepIcons2/geoStampIcon';
import DropdownIcon from 'components/Icons/templateStepIcons2/dropdownIcon';
import LocationIcon from 'components/Icons/templateStepIcons2/locationIcon';
import PhotoAnnotateIcon from 'components/Icons/templateStepIcons2/photoAnnotateIcon';
import TextFieldIcon from 'components/Icons/templateStepIcons2/textFieldIcon';
import AutomationIconRounded from 'components/Icons/automationIconRounded';
import { ItemContainer as Container } from '../styled';
import { stepMap } from '../utils/getConditionInput';

interface IProps {
  title: string;
  type: string;
  index: number;
}

const Cover: FC<IProps> = (props) => {
  const { title, type, index } = props;

  return (
    <Container justifyContent="center" spacing={1} width="100%" mb="12px">
      <span className="stepCount">{index}</span>
      <div className="type">
        {stepMap(type)?.icon}{' '}
        <Typography variant="caption" fontWeight={600} fontSize="13px">
          {stepMap(type)?.label}
        </Typography>
      </div>
      <Typography
        variant="caption"
        fontWeight={500}
        fontSize="14px"
        sx={{ marginTop: 0 }}
      >
        {title}
      </Typography>
    </Container>
  );
};

export default Cover;
