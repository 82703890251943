import { RemoveIconBtn, SelectedAssigneeChip } from './style';
import UserAvatar from '../AvatarsList/userAvatar';
import CancelIcon from '@mui/icons-material/Cancel';
import TeamAvatar from '../AvatarsList/teamAvatar';
import ChildItemIcon from 'components/Icons/childItemIcon';
import { Stack } from '@mui/material';
import LocationIcon from 'components/Icons/locationIcon';

interface IProps {
  data: any;
  assigneesProps?: any;
}

const defaultProps = {
  width: 24,
  height: 24,
  clearOption: true,
};

const ChipsList = (props: IProps) => {
  const { data, assigneesProps } = props;

  const customAssigneesProps = {
    ...defaultProps,
    ...assigneesProps,
  };

  return (
    <Stack direction="row" alignItems="center" gap={'4px'}>
      {data?.map((item) => {
        if (item?.Assigned) {
          return (
            <SelectedAssigneeChip style={customAssigneesProps?.style}>
              <UserAvatar
                data={item?.Assigned}
                width={customAssigneesProps?.width}
                height={customAssigneesProps?.height}
              />
              <span className="selectedAssigneeLabel">
                {item?.Assigned?.firstName?.length > 8
                  ? `${item?.Assigned?.firstName?.slice(0, 8)}...`
                  : item?.Assigned?.firstName}
              </span>
              {customAssigneesProps?.clearOption && (
                <RemoveIconBtn
                  onClick={(e) =>
                    assigneesProps?.removeHandler(e, item?.Assigned?.id)
                  }
                >
                  <CancelIcon />
                </RemoveIconBtn>
              )}
            </SelectedAssigneeChip>
          );
        }

        if (item?.Team) {
          return (
            <SelectedAssigneeChip style={customAssigneesProps?.style}>
              <TeamAvatar
                data={item?.Team}
                width={customAssigneesProps?.width}
                height={customAssigneesProps?.height}
              />
              <span className="selectedAssigneeLabel">
                {item?.Team?.name?.length > 8
                  ? `${item?.Team?.name?.slice(0, 8)}...`
                  : item?.Team?.name}
              </span>
              {customAssigneesProps?.clearOption && (
                <RemoveIconBtn
                  onClick={(e) =>
                    assigneesProps?.removeHandler(e, item?.Team?.id)
                  }
                >
                  <CancelIcon />
                </RemoveIconBtn>
              )}
            </SelectedAssigneeChip>
          );
        }

        if (item?.Location) {
          return (
            <SelectedAssigneeChip style={customAssigneesProps?.style}>
              {item?.Location?.ParentId ? (
                <ChildItemIcon
                  sx={{ marginLeft: 1, fontSize: 15, color: '#757575' }}
                />
              ) : (
                <LocationIcon
                  sx={{ marginLeft: 1, fontSize: 15, color: '#757575' }}
                />
              )}

              <span className="selectedAssigneeLabel">
                {item?.Location?.name?.length > 8
                  ? `${item?.Location?.name?.slice(0, 8)}...`
                  : item?.Location?.name}
              </span>
              {customAssigneesProps?.clearOption && (
                <RemoveIconBtn
                  onClick={(e) =>
                    assigneesProps?.removeHandler(e, item?.Location?.id)
                  }
                >
                  <CancelIcon />
                </RemoveIconBtn>
              )}
            </SelectedAssigneeChip>
          );
        }

        if (item?.Role) {
          return (
            <SelectedAssigneeChip style={customAssigneesProps?.style}>
              <TeamAvatar
                data={item?.Role}
                width={customAssigneesProps?.width}
                height={customAssigneesProps?.height}
              />
              <span className="selectedAssigneeLabel">{item?.Role?.title}</span>
              {customAssigneesProps?.clearOption && (
                <RemoveIconBtn
                  onClick={(e) =>
                    assigneesProps?.removeHandler(e, item?.Role?.id)
                  }
                >
                  <CancelIcon />
                </RemoveIconBtn>
              )}
            </SelectedAssigneeChip>
          );
        }
      })}
    </Stack>
  );
};

export default ChipsList;
