// Custom components
import PassFail from 'components/TemplateSubmissionPreview/Fields/PassFail';
import MultipleChoice from 'components/TemplateSubmissionPreview/Fields/MultipleChoice';
import Procedure from 'components/TemplateSubmissionPreview/Fields/Procedure';
import TakePhoto from 'components/TemplateSubmissionPreview/Fields/TakePhoto';
import Number from 'components/TemplateSubmissionPreview/Fields/Number';
import Text from 'components/TemplateSubmissionPreview/Fields/Text';
import Signature from 'components/TemplateSubmissionPreview/Fields/Signature';
import DateTime from 'components/TemplateSubmissionPreview/Fields/DateTime';
import Instruction from 'components/TemplateSubmissionPreview/Fields/Instruction';
import Dropdown from 'components/TemplateSubmissionPreview/Fields/Dropdown';
import GeoStamp from 'components/TemplateSubmissionPreview/Fields/GeoStamp';
import Location from 'components/TemplateSubmissionPreview/Fields/Location';
import PhotoAnnotate from 'components/TemplateSubmissionPreview/Fields/PhotoAnnotate';
import TemplateNotes from 'components/TemplateSubmissionPreview/TemplateNotes';
import TemplateItemAttachments from 'components/TemplateSubmissionPreview/TemplateItemAttachments';
import StepErrors from 'components/TaskChecklist/Items/TemplateItem/StepErrors';

// Styled
import {
  NotesAndSectionWrapper,
  SectionWrapper,
  SectionName,
  NotesWrapper,
  AttachmentsWrapper,
} from './styled';

// Utils
import { groupBy } from 'lodash';
import TaskPreview from 'components/TemplateSubmissionPreview/TaskPreview';
import { validateItemAutomations } from 'xeniautils';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import { Box, Typography, Stack } from '@mui/material';
import { colors } from 'components/theme/constants';
import FeedbackIcon from 'components/Icons/feedbackIcon';
import { useState } from 'react';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface TemplateItemPropTypes {
  item: any;
  childItemsMap?: any;
  onChangeCb?: (item: any, value: any) => void;
  automations?: any;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (itemId: string, task: any) => void;
  correctiveTasks?: any[];
  feedbackActions?: any;
  error?: any;
  allErrors?: any;
  isPreview: boolean;
  isPublic?: boolean;
  isReviewMode?: boolean;
  canReview?: boolean;
}

const TemplateItem = ({
  item,
  automations,
  onChangeCb,
  childItemsMap,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  correctiveTasks,
  error,
  allErrors,
  isPreview,
  isPublic,
  feedbackActions,
  isReviewMode = false,
  canReview = false,
}: TemplateItemPropTypes) => {
  const { isMobileDeviceMd } = useResponsive();
  const [isEnableFeedback, setIsEnableFeedback] = useState(isMobileDeviceMd);

  const getItem = (
    type:
      | 'header'
      | 'number'
      | 'passFail'
      | 'multipleChoice'
      | 'procedure'
      | 'textField'
      | 'takePhoto'
      | 'temperature'
      | 'cost'
      | 'signature'
      | 'dateTime'
      | 'instruction'
      | 'dropdown'
      | 'geoStamp'
      | 'location'
      | 'photoAnnotate',
    item: any,
    error: any,
  ) => {
    const props = {
      item,
      onChangeCb: onChangeCb ? (value) => onChangeCb?.(item, value) : undefined,
      answers: item?.answers,
      templateLog,
      hideCorrectiveTask,
      onCorrectiveTaskCreationCb: (task) =>
        onCorrectiveTaskCreationCb?.(item.id, task),
      error,
      isPreview,
      isPublic,
    };

    switch (type) {
      case 'header':
        return <SectionName>{item.description}</SectionName>;
      case 'passFail':
        return <PassFail {...props} />;
      case 'multipleChoice':
        return <MultipleChoice {...props} />;
      case 'dropdown':
        return <Dropdown {...props} />;
      case 'procedure':
        return <Procedure {...props} />;
      case 'takePhoto':
        return <TakePhoto {...props} />;
      case 'number':
      case 'cost':
      case 'temperature':
        return <Number {...props} />;
      case 'textField':
        return <Text {...props} />;
      case 'signature':
        return <Signature {...props} />;
      case 'dateTime':
        return <DateTime {...props} />;
      case 'instruction':
        return <Instruction {...props} />;
      case 'geoStamp':
        return <GeoStamp {...props} />;
      case 'location':
        return <Location {...props} />;
      case 'photoAnnotate':
        return <PhotoAnnotate {...props} />;
      default:
        break;
    }
  };

  const getFollowupQuestions = (logItem) => {
    if (!logItem?.answers?.value) return null;

    const conditionData = {
      answer: logItem.answers.value,
      itemType: logItem.type,
      automations: automations?.[logItem.ChecklistItemId],
    };

    const actions = validateItemAutomations(conditionData);

    const followupAction: any = actions.find(
      (action: any) => action.type === AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
    );

    if (!followupAction || !childItemsMap?.[logItem?.ChecklistItemId]?.length)
      return null;

    const childs = childItemsMap[logItem.ChecklistItemId];
    const childItemsGroupByAutomation = groupBy(childs, 'RelatedAutomationId');

    return childItemsGroupByAutomation[followupAction?.automationId];
  };

  const followupQuestions = getFollowupQuestions(item);

  return (
    <Stack>
      <NotesAndSectionWrapper
        borderonhover={item.type !== 'header' ? 'true' : ''}
        procedure={item.type === 'procedure' ? 'true' : ''}
        error={error && error?.length ? 'true' : ''}
      >
        {item.type !== 'header' ? (
          <>
            <SectionWrapper
              border={!['procedure'].includes(item.type) ? 'true' : ''}
              rounded={item?.TaskChecklistItemNotes?.length === 0}
              className="section-wrapper"
              sx={{
                ...(item?.type === 'procedure' && {
                  position: 'relative',
                }),
                ...(isMobileDeviceMd && {
                  border: 'none',
                  padding: '10px 12px',
                  borderTop: '1px solid #E0E0E0',
                  background: '#fff',
                }),
              }}
            >
              {getItem(item.type, item, error)}
              {isReviewMode &&
                canReview &&
                !isEnableFeedback &&
                !(item?.Comments?.length > 0) && (
                  <Box
                    className="giveFeedback"
                    onClick={() => setIsEnableFeedback(true)}
                    sx={{
                      ...(!isMobileDeviceMd && { display: 'none' }),
                      ...(item?.type === 'procedure' && {
                        bottom: '7px !important',
                      }),
                    }}
                  >
                    <div className="innerCnt">
                      <FeedbackIcon className="feedbackIcon" />
                      <Typography className="title">Give Feedback</Typography>
                    </div>
                  </Box>
                )}
            </SectionWrapper>
            <Stack direction="row" alignItems="center" gap="10px">
              {!!(
                item?.TaskChecklistItemNotes?.length > 0 ||
                item?.Comments?.length > 0 ||
                isEnableFeedback
              ) && (
                <NotesWrapper className="notes-wrapper">
                  <TemplateNotes
                    notes={item.TaskChecklistItemNotes}
                    feedbacks={item.Comments}
                    id={item?.id}
                    isEnableFeedback={isEnableFeedback}
                    feedbackActions={feedbackActions}
                    isReviewMode={isReviewMode}
                    canReview={canReview}
                  />
                </NotesWrapper>
              )}
            </Stack>

            {correctiveTasks && correctiveTasks?.length > 0 && (
              <Stack className="corrective-tasks" m={'8px 16px'} gap={'4px'}>
                {correctiveTasks?.map((task) => (
                  <TaskPreview key={task.id} task={task} />
                ))}
              </Stack>
            )}
            {!!(item?.attachments.length > 0) && (
              <AttachmentsWrapper>
                <TemplateItemAttachments
                  template={item}
                  attachments={item.attachments}
                />
              </AttachmentsWrapper>
            )}
            {followupQuestions?.length ? (
              <Box
                bgcolor={colors.primary.main}
                borderRadius="6px"
                px="8px"
                py="10px"
                my="12px"
              >
                <Typography color="#fff" fontSize="16px" fontWeight={700}>
                  Follow Up Questions ({followupQuestions?.length})
                </Typography>
              </Box>
            ) : null}
            {followupQuestions?.map((childItem) => {
              return (
                <Box my="6px" key={childItem.id}>
                  <TemplateItem
                    feedbackActions={feedbackActions}
                    item={childItem}
                    childItemsMap={childItemsMap}
                    onChangeCb={onChangeCb}
                    automations={automations}
                    error={allErrors?.[childItem.id]}
                    allErrors={allErrors}
                    isPreview={isPreview}
                  />
                </Box>
              );
            })}
          </>
        ) : (
          <>{getItem(item.type, item, error)}</>
        )}
      </NotesAndSectionWrapper>
      {error?.length > 0 && <StepErrors errors={error} />}
    </Stack>
  );
};

export default TemplateItem;
