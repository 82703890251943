// MUI components
import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import SubmissionReviewModeHeader from './header';
import SubmissionReviewModeContent from './content';
import { useEffect, useMemo, useState } from 'react';
import { InfoBanner, LoaderCnt, ReviewModeContent } from './style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useReactRouter } from 'controller/useReactRouter';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useWorkflows from 'utils/CustomHooks/useWorkflows';

const SubmissionReviewMode = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.SUBMISSION_REVIEW_MODE_DIALOG];
  const userProfile = useSelector(selectors.getUserProfile);
  const { handleGetQueryParam } = useReactRouter();
  const queryParams = handleGetQueryParam();

  const action = queryParams.get('action');

  const [canReview, setCanReview] = useState(false);

  const { open, data } = modalState || {};
  const { submissionId, approvalId } = data || {};
  const [submissionDetails, setSubmissionDetails] = useState<any>({});
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { updateSubmissionApproval } = useWorkflows();

  const [forcedUpdate, setForcedUpdate] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    dispatch(closeDialog(DIALOGS.SUBMISSION_REVIEW_MODE_DIALOG));
  };

  const submissionDetailsSetter = (data) => {
    setSubmissionDetails(data);
  };

  const canReviewCallback = (data) => {
    setCanReview(data);
  };

  const forcedUpdateCallback = (data) => {
    setForcedUpdate(data);
  };

  const showInfoBanner = useMemo(() => {
    if (
      submissionDetails?.ApprovalSteps?.[0]?.status === 'Pending Approval' &&
      userProfile?.id === submissionDetails?.UpdatedBy &&
      !canReview
    ) {
      return true;
    }
    return false;
  }, [submissionDetails, userProfile, canReview]);

  const approveSubmission = async () => {
    await updateSubmissionApproval(
      {
        status: 'Approved',
      },
      approvalId,
      'Submission has been approved successfully',
    );
    handleClose();
    navigateWithWorkspaceUrl('/dashboard');
  };

  useEffect(() => {
    if (action === 'approve') {
      approveSubmission();
    }
  }, [action]);

  return (
    <CustomModal
      disableEnforceFocus
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0px',
          height: '100%',
          width: '100%',
          maxWidth: 'unset',
          margin: 0,
          maxHeight: 'unset',
        },
      }}
      fullWidth
      open={open}
      handleClose={handleClose}
    >
      {action !== 'approve' && (
        <>
          {!loading && (
            <SubmissionReviewModeHeader
              submissionId={submissionId}
              approvalId={approvalId}
              submissionDetails={submissionDetails}
              canReview={canReview}
              forcedUpdateCallback={forcedUpdateCallback}
            />
          )}

          <ReviewModeContent
            sx={{
              ...(!loading && {
                backgroundColor: '#F6F8FA',
                minHeight: '500px',
              }),
            }}
          >
            {!loading && showInfoBanner && (
              <InfoBanner direction="row" alignItems="center" gap="7px">
                <InfoOutlinedIcon className="icon" />
                <Typography className="content">
                  This Submission is Pending Approval. You will receive a
                  notification for any updates.
                </Typography>
              </InfoBanner>
            )}
            <SubmissionReviewModeContent
              submissionId={submissionId}
              loading={loading}
              setLoading={setLoading}
              submissionDetailsSetter={submissionDetailsSetter}
              canReviewCallback={canReviewCallback}
              setForcedUpdate={setForcedUpdate}
              forcedUpdate={forcedUpdate}
            />
          </ReviewModeContent>
        </>
      )}

      {action === 'approve' && (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      )}
    </CustomModal>
  );
};

export default SubmissionReviewMode;
