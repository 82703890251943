// Redux
import CTypography from 'components/CTypography';

// Utilities
import {
  PlannedTaskIdChip,
  ScheduleCnt,
} from 'pages/task/TaskListView/taskListView.style';
import {
  priorityDropdownData,
  statusDropdownData,
  logSubmissionStatuses,
} from 'helper/task/dropdownData';
import RecurringIcon from 'components/Icons/recurringIcon';
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import moment from 'moment-timezone';
import { getTimeZone } from 'utils/globalFunction';
import {
  AssigneeCnt,
  StatusCell,
  IconContainer,
  TemplateIconAndNameCnt,
  AnonymousAvatarCnt,
  AnonymousAvatar,
  ItemsCnt,
  BorderLinearProgress,
  TaskId,
  DateAndTimeCnt,
  Actions,
} from 'pages/Widgets/SubmissionsListing/SubmissionListView/styled';
import AvatarPopover from 'components/AvatarPopover';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import React from 'react';
import GlobeIcon from 'components/Icons/globeIcon';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { MoreVert } from '@mui/icons-material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import AdobeAcrobatIcon from 'components/Icons/adobeAcrobatIcon';
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';
import CustomButton from 'components/Button/CustomButton';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { getLogDuration } from 'utils/templates';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const ChecklistIcon = ({ icon, ...rest }) => {
  return (
    <IconContainer bgcolor={icon.color}>
      <MuiIcon name={icon.icon} {...rest} />
    </IconContainer>
  );
};

const ActionCell = ({ log }: { log: SubmissionReportType }) => {
  const { workspaceId } = useWorkspaceHook();
  const { downloading, handleDownloadReportApi } = useLogActions();
  const { id: logId, ChecklistId } = log;
  const handleViewReport = () => {
    window.open(`/ws/${workspaceId}/checklistlogs/${logId}/report`, '_blank');
  };

  const handleDownloadReport = async () => {
    if (downloading) return;
    await handleDownloadReportApi({
      logIds: [logId],
      option: 'pdf',
      templateId: ChecklistId,
    });
  };

  const handleOptionSelect = (option: any) => {
    switch (option.id) {
      case 'downloadAsPdf':
        handleDownloadReport();
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      id: 'downloadAsPdf',
      label: 'Download PDF',
      iconAlign: 'start',
      icon: <AdobeAcrobatIcon />,
    },
  ];

  return (
    <Actions data-attribute="cell-click">
      <CustomButton onClick={handleViewReport}>View Report</CustomButton>
      <ActionDropdown
        options={actions}
        buttonProps={{
          size: 'small',
        }}
        handleOptionSelect={handleOptionSelect}
        popperProps={{
          placement: 'bottom-end',
        }}
      >
        <MoreVert />
      </ActionDropdown>
    </Actions>
  );
};

const DateAndTime = ({ dateTime }) => {
  const { getUtcToTzTime, getDateInTz } = useDateTime();
  return (
    <DateAndTimeCnt>
      <p>{`${getDateInTz(dateTime, 'MMM DD YYYY')}`}</p>|
      <p>{`${getUtcToTzTime(dateTime)}`}</p>
    </DateAndTimeCnt>
  );
};

const TaskCell = ({ task, fetchSubmissionsList, label }) => {
  const dispatch = useDispatch();

  const handleClickTask = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.TASK_DETAIL,
        data: {
          taskId: task.id,
          successCB: () => fetchSubmissionsList(),
          onChecklistSubmitCb: () => fetchSubmissionsList(),
        },
      }),
    );
  };

  return task ? (
    <div
      data-attribute="cell-click"
      style={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <TaskId onClick={handleClickTask}>{label}</TaskId>
    </div>
  ) : (
    <>-</>
  );
};

const LocationsCell = ({ log }: { log: SubmissionReportType }) => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { locations } = log;
  const locationsHash = useSelector(selectors.getLocationsHash);

  const handleClickLocation = (locationId: string) => {
    const location = locationsHash[locationId];
    if (!location) return;
    if (location?.ParentId) {
      navigateWithWorkspaceUrl(
        `/locations/${location.ParentId}/sl/${location.id}`,
      );
      return;
    }
    navigateWithWorkspaceUrl(`/locations/${location.id}`);
  };

  return (
    <Stack
      direction="row"
      gap="4px"
      alignItems={'center'}
      data-attribute="cell-click"
      height="100%"
    >
      {locations?.map((locationId) => (
        <Stack
          borderRadius={'100px'}
          p="4px 10px"
          alignItems={'center'}
          justifyContent={'center'}
          color="#6868FE"
          fontSize={'13px'}
          fontWeight={'500'}
          letterSpacing={'0.17px'}
          border="1px solid #E0E0E0"
          height="27px"
          onClick={() => handleClickLocation(locationId)}
          sx={{
            cursor: 'pointer',
          }}
        >
          {locationsHash[locationId]?.name}
        </Stack>
      ))}
    </Stack>
  );
};

const tz: string = getTimeZone();

const scheduleList = [...weeks, ...months];

const getSubmitterName = (log: SubmissionReportType) => {
  if (log.submitterName) return log.submitterName;
  if (!log.LastItemUpdater || !log.Updater) return 'Anonymous';
  else {
    const updater = log.LastItemUpdater ?? log.Updater;
    return `${updater?.firstName} ${updater?.lastName}`;
  }
};

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  lockVisible: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};
export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskSummaryListGrid?.columnApi?.getRowGroupColumns()?.[0]
      ?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};
export const cellRenderer = {
  id: (params) => {
    if (!params?.data) return;
    const { taskNumber } = params.data;
    return taskNumber ? (
      <span className="taskTitle">{taskNumber}</span>
    ) : (
      <PlannedTaskIdChip>Planned</PlannedTaskIdChip>
    );
  },
  templateName: (params) => {
    if (!params?.data) return;
    const { name, icon } = params.data;
    return (
      <TemplateIconAndNameCnt className="template-name-cnt">
        <ChecklistIcon icon={icon} />
        <span className="name">{name}</span>
      </TemplateIconAndNameCnt>
    );
  },
  progress: (row) => {
    const { progress, status } = row.data;
    const selectedStatus = logSubmissionStatuses.find(
      (s) => s.label === status,
    );
    return (
      <ItemsCnt>
        <div
          style={{ height: '35px' }}
        >{`${progress?.completedCount} of ${progress?.count}`}</div>
        <BorderLinearProgress
          variant="determinate"
          value={progress?.progress}
          backgroundColor={selectedStatus?.backgroundColor}
          progressBarFg={selectedStatus?.color}
        />
      </ItemsCnt>
    );
  },
  score: (row) => {
    const { score, Checklist } = row.data;

    return Checklist?.isScoring ? (
      <Stack direction="row" alignItems="center" height="100%" spacing={1}>
        <Typography fontWeight={600} fontSize="13px">
          {score?.percentage}%
        </Typography>
        <Typography fontSize="13px">
          ({score?.earned} of {score?.total})
        </Typography>
      </Stack>
    ) : (
      'NA'
    );
  },
  taskId: (row, props) => {
    const { Task } = row.data;
    const { fetchSubmissionsList } = props;
    return (
      <TaskCell
        label={Task?.taskNumber}
        fetchSubmissionsList={fetchSubmissionsList}
        task={Task}
      />
    );
  },
  taskTitle: (row, props) => {
    const { Task } = row.data;
    const { fetchSubmissionsList } = props;
    return (
      <TaskCell
        label={Task?.title}
        fetchSubmissionsList={fetchSubmissionsList}
        task={Task}
      />
    );
  },
  status: (params) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }
    const { status } = params.data;
    const selectedStatus = logSubmissionStatuses.find(
      (s) => s.label === status,
    );
    return (
      <StatusCell color={selectedStatus?.color}>
        <div className={'statusDot'} />
        <span className={'statusText'}>{status}</span>
      </StatusCell>
    );
  },
  duration: (params) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }
    const duration = getLogDuration(params.data);
    return (
      <StatusCell>
        <AccessTimeRoundedIcon
          sx={{ color: 'rgba(66, 66, 66, 1)', fontSize: '20px' }}
        />
        <span className={'statusText'}>{duration || '-'}</span>
      </StatusCell>
    );
  },
  locations: (params) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }

    return <LocationsCell log={params.data} />;
  },
  description: (params) => {
    if (!params?.data) return;
    const { description } = params.data;
    return <span className="taskTitle">{description}</span>;
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    const schedule = scheduleList?.find(
      (item) => item.id === recurringByEvery,
    )?.displayTitle;
    return schedule ? (
      <ScheduleCnt>
        <div className="innerCnt">
          <RecurringIcon />
          <CTypography>{schedule}</CTypography>
        </div>
      </ScheduleCnt>
    ) : (
      '-'
    );
  },
  assignee: (row) => {
    if (!row?.data) return;
    const submitter = getSubmitterName(row.data);
    const { submitterName, LastItemUpdater, Updater, anonymousId } = row.data;

    const isUserDeleted = LastItemUpdater?.deletedAt || Updater?.deletedAt;

    return (
      <React.Fragment>
        {(submitterName || anonymousId) && (
          <AnonymousAvatarCnt>
            <AnonymousAvatar>
              <GlobeIcon style={{ color: '#fff', fontSize: '18px' }} />
            </AnonymousAvatar>
            <p className="submitter">{submitter}</p>
          </AnonymousAvatarCnt>
        )}
        {!submitterName &&
          (LastItemUpdater?.id || Updater?.id) &&
          !isUserDeleted && (
            <AssigneeCnt>
              <React.Fragment>
                <AvatarPopover userId={LastItemUpdater?.id || Updater?.id} />
              </React.Fragment>
            </AssigneeCnt>
          )}
        {isUserDeleted && (
          <AnonymousAvatarCnt>
            <p className="submitter">-</p>
          </AnonymousAvatarCnt>
        )}
      </React.Fragment>
    );
  },
  completionDateTime: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params.data;
    return updatedAt ? <DateAndTime dateTime={updatedAt} /> : '-';
  },
  completedBy: (row) => {
    if (!row?.data) return;
    const submitter = getSubmitterName(row.data);
    const { Submitter, submitterName, anonymousId } = row.data;

    return (
      <React.Fragment>
        {submitterName || anonymousId ? (
          <AnonymousAvatarCnt>
            <AnonymousAvatar>
              <GlobeIcon style={{ color: '#fff', fontSize: '18px' }} />
            </AnonymousAvatar>
            <p className="submitter">{submitter}</p>
          </AnonymousAvatarCnt>
        ) : !submitterName && Submitter ? (
          <AssigneeCnt>
            <React.Fragment>
              <AvatarPopover userId={Submitter.id} />
            </React.Fragment>
          </AssigneeCnt>
        ) : (
          '-'
        )}
      </React.Fragment>
    );
  },
  actions: (params) => {
    if (!params?.data) return;
    return <ActionCell log={params.data} />;
  },
};

//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  taskId: (params) => {
    const { Task } = params.data;
    return Task ? Task.taskNumber : null;
  },
  completionDateAndTime: (params) => {
    const { updatedAt } = params.data;
    return updatedAt
      ? moment.tz(updatedAt, tz).format('MMM DD YYYY hh:mm a')
      : '-';
  },
  createdAt: (params) => {
    const { createdAt } = params.data;
    return moment.tz(createdAt, tz).format('MMM DD YYYY hh:mm a');
  },
  startDate: (params) => {
    if (!params?.data) return;
    const { startTime } = params.data;
    const date = startTime;
    return moment.tz(date, tz).format('MMM DD YYYY hh:mm a');
  },
  assignee: (params) => {
    if (!params?.data) return;
    return getSubmitterName(params.data);
  },
  completedBy: (params) => {
    if (!params?.data) return;
    return params.data.Submitter?.fullName ?? null;
  },
};

export const columns: any = (props) => {
  const { fetchSubmissionsList } = props;
  return [
    {
      field: 'name',
      headerName: 'Template Name',
      colId: 'templateName',
      sortable: true,
      cellRenderer: cellRenderer.templateName,
    },
    {
      field: 'lastItemUpdater',
      headerName: 'Submitter/Assignee',
      colId: 'submitter',
      comparator: comparator.assignee,
      valueGetter: valueGetters.assignee,
      sortable: true,
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'status',
      headerName: 'Status',
      colId: 'status',
      sortable: true,
      comparator: comparator.status,
      cellRenderer: cellRenderer.status,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      colId: 'duration',
      // sortable: true,
      // comparator: comparator.status,
      cellRenderer: cellRenderer.duration,
    },
    {
      field: 'locations',
      headerName: 'Location',
      colId: 'locations',
      sortable: false,
      cellRenderer: cellRenderer.locations,
    },
    {
      field: 'progress',
      headerName: 'Items',
      colId: 'progress',
      cellRenderer: cellRenderer.progress,
      sortable: true,
    },
    {
      field: 'score',
      headerName: 'Score (pts)',
      colId: 'score',
      cellRenderer: cellRenderer.score,
      sortable: true,
    },
    {
      field: 'Task',
      headerName: 'Task ID',
      colId: 'TaskId',
      valueGetter: valueGetters.taskId,
      cellRenderer: (params) =>
        cellRenderer.taskId(params, { fetchSubmissionsList }),
      sortable: true,
    },
    {
      field: 'Task.title',
      headerName: 'Task Title',
      colId: 'workOrderTitle',
      cellRenderer: (params) =>
        cellRenderer.taskTitle(params, { fetchSubmissionsList }),
      sortable: true,
    },
    {
      field: 'lastItemUpdatedAt',
      headerName: 'Completion Date/Time',
      colId: 'createdAt',
      comparator: comparator.date,
      sortable: true,
      cellRenderer: cellRenderer.completionDateTime,
      valueGetter: (params) => valueGetters.completionDateAndTime(params),
    },
    {
      field: 'Submitter',
      headerName: 'Completed By',
      colId: 'completedBy',
      sortable: true,
      cellRenderer: cellRenderer.completedBy,
      comparator: comparator.assignee,
      valueGetter: valueGetters.completedBy,
    },
    {
      colId: 'actions',
      comparator: comparator.date,
      sortable: false,
      cellRenderer: cellRenderer.actions,
      pinned: 'right',
      cellStyle: {
        'padding-right': '6px',
        'padding-left': '12px',
        'box-shadow': '-4px 0px 4px rgba(0, 0, 0, 0.08)',
      },
      width: 140,
    },
  ];
};

const comparator = {
  date: (date1, date2) => {
    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  id: (id1, id2) => {
    return parseInt(id1, 10) - parseInt(id2, 10);
  },
  priority: (p1, p2) => {
    const p1Index = priorityDropdownData?.findIndex(
      (p) => p?.id?.toLowerCase() == p1?.toLowerCase(),
    );
    const p2Index = priorityDropdownData.findIndex(
      (p) => p?.id?.toLowerCase() == p2?.toLowerCase(),
    );

    return p1Index - p2Index;
  },
  assignee: (a1, a2) => {
    const assignee1 = a1 || '-';
    const assignee2 = a2 || '-';
    return assignee1?.toLowerCase() == assignee2?.toLowerCase()
      ? 0
      : assignee1?.toLowerCase() > assignee2?.toLowerCase()
      ? 1
      : -1;
  },
  status: (s1, s2) => {
    const s1Index = logSubmissionStatuses?.findIndex(
      (s) => s?.id?.toLowerCase() == s1?.toLowerCase(),
    );
    const s2Index = logSubmissionStatuses?.findIndex(
      (s) => s?.id?.toLowerCase() == s2?.toLowerCase(),
    );

    return s1Index - s2Index;
  },
};
