import { FC, useEffect, useState } from 'react';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { KeyboardArrowDownRounded } from '@mui/icons-material';

interface IProps {
  action: string;
  onChange(item: any): void;
}
const logicOptions = [
  { value: 'SHOW', label: 'Show' },
  { value: 'HIDE', label: 'Hide' },
  // not implemeted yet
  // { value: 'showMultiple', label: 'Show Multiple' },
  // { value: 'hideMultiple', label: 'Hide Multiple' },
];

const Action: FC<IProps> = (props) => {
  const { action, onChange } = props;
  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    setSelectedOption(logicOptions.find((item) => item.value === action));
  }, [action]);
  const onActionChange = (type) => {
    onChange({
      action: type,
    });
  };
  return (
    <>
      <CustomSelect
        grayInput={false}
        isSearchable={false}
        isMulti={false}
        closeMenuOnSelect
        selectedOption={selectedOption}
        options={logicOptions}
        onChange={(item) => {
          onActionChange(item.value);
          setSelectedOption(item);
        }}
        endIcon={
          <KeyboardArrowDownRounded
            sx={{ marginLeft: 'auto', color: '#616161' }}
          />
        }
      />
    </>
  );
};

export default Action;
