import { Box, styled } from '@mui/material';

export const StartIcon = styled(Box)({
  width: 30,
  height: 30,
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#78909C',
  border: '1px solid #E0E0E0',
  '& path': {
    stroke: 'currentColor',
    strokeWidth: 1.5,
    strokeOpacity: 1,
  },
});
export const TabsCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 20px',
  overflowX: 'auto',
  '& .tab': {
    fontWeight: 500,
    fontSize: 12,
    marginRight: 8,
    borderRadius: 100,
    cursor: 'pointer',
    color: '#212121',
    padding: '8px 12px 8px 12px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    lineHeight: '16.39px',
    transition: '0.4s ease all',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
  },
  '& .tab.selectedTab': {
    background: '#6868FE',
    padding: '8px 12px 8px 12px',
    color: '#fff',
    '& .tabCount': {
      color: '#FFCCBC',
    },
  },
});
