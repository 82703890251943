import React, { ReactNode, useContext } from 'react';

import { FieldWrapper, FieldName } from './styled';
import Stack from '@mui/material/Stack';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import { DashboardContext } from 'pages/TemplateDashboard/context';
import CreateCorrectiveTask from '../CreateCorrectiveTask';
import { Divider, Typography } from '@mui/material';
import EditIconPencilBox from 'components/Icons/editIconPencilBox';
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined';
import TaskIconWithPlus from 'components/Icons/taskIconWithPlus';
import { DiscriptionCtn } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/styled';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface FieldNameRendererPropTypes {
  fieldName: string;
  item: any;
  isAnswered?: boolean;
  children: ReactNode;
  templateLog?: any;
  preview?: boolean;
  hideCorrectiveTask?: boolean;
  isEmulatorView?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  template?: any;
}

const IconAndText = ({ icon, text }) => (
  <Stack
    sx={{
      cursor: 'pointer',
    }}
    direction={'row'}
    alignItems={'center'}
    gap="6px"
  >
    {icon}
    <Typography
      color="#000000"
      fontSize="12px"
      fontWeight="500"
      lineHeight="16.39px"
    >
      {text}
    </Typography>
  </Stack>
);

const FieldNameRenderer = ({
  isAnswered = true,
  fieldName,
  children,
  item,
  templateLog,
  preview,
  hideCorrectiveTask,
  isEmulatorView,
  onCorrectiveTaskCreationCb,
  template,
}: FieldNameRendererPropTypes) => {
  const { template: _template } = useContext(DashboardContext) || {};
  const { isMobileDeviceMd } = useResponsive();

  const finalTemplate = template || _template;

  return (
    <FieldWrapper
      sx={{
        ...(isEmulatorView && {
          gap: '5px',
        }),
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FieldName
          emulator={isEmulatorView ? 'true' : ''}
          hideCorrectiveTask={hideCorrectiveTask || preview ? 'true' : ''}
        >
          {fieldName}
        </FieldName>
        {!isMobileDeviceMd &&
          finalTemplate?.isShowTimestamp &&
          item.completedAt &&
          isAnswered && (
            <CompletedInfo user={item.Updater} time={item.completedAt} />
          )}
        {!preview && !hideCorrectiveTask && (
          <CreateCorrectiveTask
            item={item}
            answer={item?.answers?.value}
            templateLog={templateLog}
            visible={!(finalTemplate?.isShowTimestamp && item.completedAt)}
            onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
          />
        )}
      </Stack>
      {!isEmulatorView && item.info?.optionDescription && (
        <DiscriptionCtn sx={{ marginTop: '10px' }}>
          <p>
            <b>Instructions:</b>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: item.info?.optionDescription,
            }}
          />
        </DiscriptionCtn>
      )}
      {children}
      {preview && isEmulatorView && (
        <Stack mt="8px" gap="8px">
          <Divider />
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <IconAndText
              icon={
                <EditIconPencilBox
                  sx={{ color: '#616161', fontSize: '13px' }}
                />
              }
              text="Note"
            />
            <IconAndText
              icon={
                <CameraAltOutlined
                  sx={{ color: '#616161', fontSize: '13px' }}
                />
              }
              text="Photo"
            />
            <IconAndText
              icon={
                <TaskIconWithPlus
                  sx={{
                    color: '#616161',
                    fontSize: '13px',
                    fill: 'none',
                  }}
                />
              }
              text="Task"
            />
          </Stack>
        </Stack>
      )}
    </FieldWrapper>
  );
};

export default FieldNameRenderer;
