import React, { FC, useCallback, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { getEntityLogicalConditions } from '../utils/getEntityLogicalConditions';
import { getConditionInput } from '../utils/getConditionInput';
import { debounce } from 'lodash';

interface IProps {
  onChange(item: any): void;
  field: any;
  condition: any;
  context: string;
  checkListItems: any;
  selectedContext: any;
}

const ConditionItem: FC<IProps> = (props) => {
  const { context, condition, onChange, selectedContext } = props;

  const logicOptions = useMemo(() => {
    if (selectedContext) {
      return getEntityLogicalConditions(selectedContext.type) || null;
    }
    return null;
  }, [selectedContext]);

  const selectedLogicOption = useMemo(() => {
    return logicOptions?.find((option) => option.value === condition.logic);
  }, [logicOptions]);

  const changeHandler = useCallback(
    debounce((value) => {
      onChange({ condition: { ...condition, value } });
    }, 500),
    [condition],
  );

  return (
    <Box>
      <Stack mt={1}>
        {logicOptions && context !== 'isEmpty' && (
          <Stack
            display={'flex'}
            sx={{
              '&>div': { flex: 1 },
            }}
            direction={'row'}
            gap={1}
          >
            <CustomSelect
              style={{ maxWidth: 150 }}
              grayInput={false}
              isSearchable={false}
              isMulti={false}
              closeMenuOnSelect
              selectedOption={selectedLogicOption}
              options={logicOptions}
              onChange={(item) => {
                onChange({ condition: { ...condition, logic: item.value } });
              }}
            />
            {condition &&
              getConditionInput(
                'checklistItem',
                selectedContext.obj,
                condition.logic,
                context,
              )({
                value: condition.value,
                onChange: changeHandler,
              })}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ConditionItem;
