/* eslint-disable no-case-declarations */
import { useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import ChildItemIcon from 'components/Icons/childItemIcon';
import LocationIcon from 'components/Icons/locationIcon';
import CustomRolesIcon from 'components/Icons/customRolesIcon';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';

// Mui
import { Box } from '@mui/material';

// Styled
import { Chip } from './styled';

interface AssigneeChipPropTypes {
  assigneeId: string;
  type: 'user' | 'team' | 'all' | 'location' | 'role';
  renderCross?: boolean;
  onClickCross?: (assigneeId: string) => void;
  sx?: any;
  allText?: string;
}

const AssigneeChip = (props: AssigneeChipPropTypes) => {
  const {
    assigneeId,
    type,
    renderCross = false,
    onClickCross,
    sx = {},
    allText = 'All Users & Teams',
  } = props;
  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const rolesHash = useSelector(selectors.getRoles)?.hashRoles;

  const data: any = useMemo(() => {
    const data = {};
    switch (type) {
      case 'user':
        const user = usersHash?.[assigneeId];
        data['avatar'] = (
          <UserAvatar userId={assigneeId} width={18} height={18} />
        );
        data['label'] = user?.firstName ?? '-';
        break;
      case 'team':
        const team = teamsHash?.[assigneeId];
        data['avatar'] = (
          <TeamAvatar teamId={assigneeId as string} width={18} height={18} />
        );
        data['label'] = team?.name ?? '-';
        break;
      case 'location':
        const location = locationsHash?.[assigneeId];
        data['label'] = location?.name ?? '-';
        if (location?.ParentId) {
          data['avatar'] = <ChildItemIcon sx={{ fontSize: 18 }} />;
        } else {
          data['avatar'] = <LocationIcon sx={{ fontSize: 18 }} />;
        }
        break;
      case 'role':
        const role = rolesHash?.[assigneeId];
        data['avatar'] = (
          <CustomRolesIcon
            width={18}
            height={18}
            style={{ width: 18, height: 18, fill: 'none' }}
          />
        );
        data['label'] = role?.title ?? '-';
        break;
      case 'all':
        data['label'] = allText;
        data['avatar'] = <TeamAvatar width={18} height={18} teamId="all" />;
        break;
      default:
        data['avatar'] = <></>;
        data['label'] = '-';
        break;
    }
    return data;
  }, [props, usersHash, teamsHash, locationsHash, rolesHash]);

  return (
    <Chip sx={sx}>
      {data?.avatar}
      {data?.label}
      {renderCross && (
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => onClickCross?.(assigneeId)}
        >
          <CancelIcon
            sx={{ fontSize: '18px', color: 'rgba(239, 83, 80, 1)' }}
          />
        </Box>
      )}
    </Chip>
  );
};

export default AssigneeChip;
