// MUI components
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 550,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
    padding: '20px 32px 32px',
  },
}));

export const HeaderWrapper = styled(Stack)({
  '& .closeIcon': {
    padding: 0,
    '& svg': {
      fontSize: 24,
      '& path': {
        fill: '#616161',
      },
    },
  },
});

export const FooterWrapper = styled(Stack)({
  borderTop: '1px solid #E0E0E0',
  gap: '12px',
  '& button': {
    width: '100%',
  },
  '& .saveBtn': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '26px',
    color: '#FFFFFF',
    borderRadius: 8,
    '&:hover': {
      boxShadow: 'unset',
    },
  },
  '& .cancelBtn': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '26px',
    color: '#4E48FA',
    borderRadius: 8,
  },
});

export const ContentWrapper = styled(Stack)({
  overflow: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',

  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30.05px',
    color: '#000000',
  },

  '& .statement': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: '#212121',
    display: 'ruby',
  },

  '& .timezone': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: '#4E48FA',
  },
});
