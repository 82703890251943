import { useCallback, useEffect } from 'react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import selectors from 'store/selectors';
import actions from 'store/actions';
import xeniaApi from 'api/index';

export function useWorkspaceHook(selectedDashboardId?: string | null) {
  const params = useParams();
  const dashboardId = selectedDashboardId || params.dashboardId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userHotels: any = useSelector(selectors.getUserHotels);
  const workspace: any = useSelector(selectors.getActiveWorkspace);
  const channelList = useSelector(selectors.getStreamChannelList);
  const workspaceId = params.workspaceId;

  const navigateWithWorkspaceUrl = (url, id = '', params = {}) => {
    const targetUrl = '/ws/' + (workspaceId ? workspaceId : id) + url;
    navigate(targetUrl, params);
  };

  const navigateBackWithWorkspace = (depth = -1) => {
    navigate(depth);
  };

  const navigateToOtherWorkspace = ({
    id,
    url = '/dashboard',
    searchPrm = '',
    params = {},
  }) => {
    navigate(`/ws/${id}${url}${searchPrm}`, params);
  };

  const openWSChannel = ({
    channelId = null as string | undefined | null,
    wsId = null as string | undefined | null,
    channel: _channel = null as any,
  }) => {
    let channel = _channel;
    if (channel) {
      dispatch(actions.setActiveStreamChannel(channel));
    } else if (channelId) {
      channel = channelList.find((ch) => ch.id === channelId);
      dispatch(actions.setActiveStreamChannel(channel));
    }
    const spaceId = channel?.data?.hotelId || wsId || params.workspaceId;
    const chId = channel?.id || channelId || '';
    navigate(`/ws/${spaceId}/messages/${chId}`);
  };

  useEffect(() => {
    if (!params?.workspaceId) return;
    const ws =
      userHotels?.find((uh) => uh?.Hotel?.id === params.workspaceId) || null;
    dispatch(actions.setActiveWorkspace(ws));
  }, [params?.workspaceId, userHotels]);

  const deleteWorkspace = async (wsId) => {
    const hotelId = wsId;
    const resp = await xeniaApi.deleteWorkspace({
      hotelId,
    });
    if (resp) {
      if (wsId === workspaceId) {
        const openWS = find(userHotels, (uh) => uh?.Hotel?.id !== hotelId);
        if (openWS) {
          navigateToOtherWorkspace({ id: openWS?.Hotel?.id });
        } else {
          navigate('/');
        }
      }
      dispatch(actions.reloadUserContext());
    }
  };

  const selectedWorkspace = useCallback(() => {
    return userHotels?.find((uh) => uh?.Hotel?.id === workspaceId);
  }, [userHotels, workspaceId]);

  return {
    navigateWithWorkspaceUrl,
    workspace,
    workspaceId,
    navigateToOtherWorkspace,
    myWorkspaces: userHotels,
    deleteWorkspace,
    openWSChannel,
    selectedWorkspace,
    navigateBackWithWorkspace,
  };
}
