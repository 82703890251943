// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import LinkNotPublic from 'components/Icons/linkNotPublicIcon';

import {
  PageWrapper,
  Logo,
  ThankyouWrapper,
  BoldText,
  GreyText,
} from './styled';

interface PropTypes {
  logo?: string;
}

const LinkUnavailable = ({ logo }: PropTypes) => {
  return (
    <PageWrapper>
      <Logo src={logo && logo !== '' ? logo : XeniaLogo} />
      <ThankyouWrapper>
        <LinkNotPublic class="broken-icon" />
        <BoldText>Link Unavailable</BoldText>
        <GreyText>
          Workspace doesn't have Public Task Request feature activated
        </GreyText>
      </ThankyouWrapper>
    </PageWrapper>
  );
};

export default LinkUnavailable;
