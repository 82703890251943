import React, { FC, useCallback } from 'react';
import { Container } from './style';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import { Grid } from '@mui/material';
import Badge from '@mui/material/Badge';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import LockFreemiumIcon from 'components/Icons/lockfreemiumIcon';
import { useCurrency } from 'customhooks/index';

interface IProps {
  task: any;
  onChange(): void;
}

const TrackCost = (props) => {
  const { task, onChange, filters = null } = props;
  const dispatch = useDispatch();
  const { currency } = useCurrency();
  const { FEATURES, hasFeature } = useCustomPermission();

  const canTrackCost = hasFeature(FEATURES.WORK_ORDER_COST);

  const openDialog = useCallback(() => {
    if (canTrackCost) {
      dispatch(
        actions.setDialog({
          dialogId: DIALOGS.TASK_COST,
          open: true,
          data: {
            filters,
            task,
            onSave: onChange,
          },
        }),
      );
    }
  }, [task, dispatch, canTrackCost]);

  const costs = task?.WorkOrderCosts;

  return (
    <Container>
      <div className="title">Track Costs</div>

      {canTrackCost && costs?.length ? (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <p className="cost-count">
              {costs.length} {costs.length === 1 ? 'Cost' : 'Costs'} Added
            </p>
            <div className="total">
              Total:{' '}
              {`${currency?.symbol}${costs
                .reduce((acc, cost) => acc + cost.amount, 0)
                .toFixed(2)}`}
            </div>
          </Grid>
          <CustomButton
            variant="outlined"
            endIcon={<NavigateNextIcon />}
            onClick={openDialog}
          >
            View Cost Details
          </CustomButton>
        </Grid>
      ) : (
        <VerifyFeatureAccess featureId={FEATURES.WORK_ORDER_COST}>
          <Badge
            className="lock-badge"
            badgeContent={<LockFreemiumIcon />}
            color="primary"
            invisible={canTrackCost}
          >
            <CustomButton
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={openDialog}
              disabled={!task?.taskNumber}
            >
              Add Cost
            </CustomButton>
          </Badge>
        </VerifyFeatureAccess>
      )}
    </Container>
  );
};

export default TrackCost;
