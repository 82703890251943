/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from 'react';
import { IMultipleChoiceOption } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/option';

export interface PassFailOptionType {
  label: 'Pass' | 'Fail' | 'N/A';
  type: 'success' | 'error' | 'info';
  value: 'pass' | 'fail' | 'na';
}

export type TemplateNoteType = {
  id: string;
  attachments: string[];
  text: string;
  createdAt: string;
  updatedAt: string;
  CreatedBy: string;
  Creator: {
    id: string;
    photo: string;
    firstName: string;
    lastName: string;
  };
  TaskChecklistItemId: string;
};

export interface ChecklistItemType {
  id: string;
  description: string;
  type:
    | 'procedure'
    | 'header'
    | 'textField'
    | 'number'
    | 'passFail'
    | 'multipleChoice'
    | 'takePhoto'
    | 'temperature'
    | 'cost'
    | 'dropdown'
    | 'geoStamp';
  unit: string | null;
  isImageOrNoteRequired: boolean;
  options: null | PassFailOptionType[] | IMultipleChoiceOption[] | any;
  TaskChecklistItemNotes: TemplateNoteType[];
  createdAt: string;
  updatedAt: string;
  ChecklistId: string;
  CreatedBy: string;
  UpdatedBy: string;
  required: boolean;
  attachments: string[];
}

export interface TemplateType {
  ChecklistIndustry?: any;
  ChecklistType?: any;
  id: string;
  name: string;
  ChecklistItems: ChecklistItemType[];
  Creator: {
    id: string;
    firstName: string;
    lastName: string;
  };
  UpdatedBy: string;
  isPublished: boolean;
  isShowTimestamp: boolean;
  isShowCorrectiveActionInSteps?: boolean;
  isScoring: boolean;
  isShowScoreInProgress: boolean;
  isShowScoreInSteps: boolean;
  isAllowOnlyBluetoothTemperatureResponses: boolean;
  icon: {
    icon: string;
    color: string;
  };
  HotelChecklist?: {
    id?: string;
    HotelId?: string;
    ChecklistId?: string;
    isEditable?: boolean;
    logsCount?: number;
    isPubliclyAccessible?: boolean;
    isNameAndEmailRequired?: boolean;
    isQREnable?: boolean;
  };
  ChecklistAccess: { AccessorId: string; role: 'admin' | 'submitter' }[];
  attachments: string[];
  TaskChecklistNotes: TemplateNoteType[];
  logo?: string;
  access: { id: string; role: 'admin' | 'submitter' }[];
  logsCount: number;
  itemsCount: number;
  CreatedBy: string;
  createdAt: string;
  updatedAt: string;
  isPubliclyAccessible: boolean;
  isNameAndEmailRequired: boolean;
  templateAccess: {
    isAdmin: boolean;
    isSubmitter: boolean;
  };
  totalLogsCount: number;
}

type Updater = {
  id: string;
  photo: string;
  firstName: string;
  lastName: string;
};

export interface SubmissionReportType {
  id: string;
  LastItemUpdater: Updater;
  Updater: Updater;
  lastItemUpdatedAt: string;
  updatedAt: string;
  status: 'In Progress' | 'Submitted';
  submitterName?: string;
}

export interface ActivtyLogType {
  id: string;
  event: 'EDIT' | 'CREATE' | 'START' | 'SUBMIT';
  createdAt: string;
  User?: {
    id: string;
    fullName: string;
    photo: string;
    firstName: string;
    lastName: string;
  };
  Template: {
    name: string;
  };
  submitterName?: string;
}

interface DashboardContextType {
  templateId: string | undefined;
  template?: TemplateType;
  activeTab: number;
  submissionCount: number | undefined;
  shouldUpdate: number;
  setActiveTab: (activeTab: number) => void;
  setTemplate: (template: TemplateType) => void;
  setShouldUpdate: () => void;
}

export const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined,
);
