import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import useResponsive from 'utils/CustomHooks/useResponsive';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { statusDropdownData } from 'helper/task/dropdownData';
import useDateTime from 'utils/CustomHooks/useDateTime';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { COLORS_LIST } from 'utils/constants';
import Attachments from './Attachments';

const TableCellWrapper = ({ children, ...props }) => {
  return (
    <Stack
      direction="row"
      alignItems={'center'}
      gap="4px"
      width="100%"
      {...props}
    >
      {children}
    </Stack>
  );
};

const TitleText = ({ title }) => {
  return (
    <Typography color="#000" fontSize="13px" fontWeight={800}>
      {title}
    </Typography>
  );
};

const DescriptionText = ({ description, noWrap = true, ...props }) => {
  const wrapSx = noWrap
    ? {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'pre',
      }
    : {};
  return (
    <Typography
      color="#000"
      fontSize="13px"
      fontWeight={600}
      sx={wrapSx}
      {...props}
    >
      {description}
    </Typography>
  );
};

const TaskStatusChip = ({ status }) => {
  const statusData = statusDropdownData.find((item) => item.value === status);
  return (
    <Box
      p="4px 8px"
      borderRadius={'90px'}
      color={statusData?.color}
      bgcolor={statusData?.backgroundColor}
      fontWeight={'700'}
      fontSize={'11px'}
    >
      {status}
    </Box>
  );
};

const PreviewV2 = ({ task }) => {
  const { isMobileDeviceLg } = useResponsive();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { getUtcToTzTime, getDateInTz } = useDateTime();

  const selectedCategory = useMemo(() => {
    if (task?.ServiceType?.id) {
      return COLORS_LIST?.find(
        (item) => item?.color === task?.ServiceType?.color,
      );
    }
    return null;
  }, [task]);

  const tableData = useMemo(() => {
    if (!task) return [];
    const data = [
      [
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Title:" />
              <DescriptionText description={task.title} />
            </TableCellWrapper>
          ),
          width: '50%',
          colSpan: isMobileDeviceLg ? 1 : 2,
        },
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Task ID:" />
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flex={1}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() =>
                  navigateWithWorkspaceUrl(`/tasks/list?taskId=${task?.id}`)
                }
              >
                <DescriptionText description={task.taskNumber} />
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: '18px', color: 'rgba(97, 97, 97, 1)' }}
                />
              </Stack>
            </TableCellWrapper>
          ),
          width: '50%',
          colSpan: isMobileDeviceLg ? 1 : 2,
        },
      ],
      [
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Status:" />
              <TaskStatusChip status={task.taskStatus} />
            </TableCellWrapper>
          ),
          width: '25%',
        },
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Due:" />
              {task.dueTime && (
                <DescriptionText
                  description={`${getDateInTz(
                    task.dueTime,
                    'MMM DD YYYY',
                  )} • ${getUtcToTzTime(task.dueTime)}`}
                />
              )}
              {!task.dueTime && <DescriptionText description="-" />}
            </TableCellWrapper>
          ),
          width: '25%',
        },
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Priority:" />
              {task.priority && (
                <div
                  className={`icon-priority-${task?.priority?.toLowerCase()}`}
                  style={{ marginRight: '6px' }}
                />
              )}
              {task.priority && <DescriptionText description={task.priority} />}
              {!task.priority && <DescriptionText description="-" />}
            </TableCellWrapper>
          ),
          width: '25%',
        },
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Category:" />
              <Stack direction={'row'} alignItems={'center'} gap="6px">
                {selectedCategory && (
                  <Box
                    borderRadius={'34px'}
                    bgcolor={selectedCategory?.color}
                    border={`1px solid ${selectedCategory?.borderColor}`}
                    height={'14px'}
                    width={'14px'}
                  />
                )}
                {selectedCategory && (
                  <DescriptionText
                    width={isMobileDeviceLg ? '200px' : '100px'}
                    description={task.ServiceType.type}
                  />
                )}
                {!selectedCategory && <DescriptionText description={'-'} />}
              </Stack>
            </TableCellWrapper>
          ),
          width: '25%',
        },
      ],
      [
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Assignee:" />

              {task.TaskAssignees?.length === 1 && (
                <AssigneesView
                  type="single"
                  data={task.TaskAssignees}
                  singleAssigneeProps={{
                    width: 21,
                    height: 21,
                    textStyles: {
                      fontSize: 13,
                      color: '#00000',
                      fontWeight: 600,
                    },
                  }}
                />
              )}

              {task.TaskAssignees?.length > 1 && (
                <AssigneesView
                  type="avatars"
                  data={task.TaskAssignees}
                  assigneesProps={{
                    width: 23,
                    height: 23,
                  }}
                />
              )}
              {task.TaskAssignees?.length === 0 && (
                <DescriptionText description={`-`} />
              )}
            </TableCellWrapper>
          ),
          width: '50%',
          colSpan: isMobileDeviceLg ? 1 : 2,
        },
        {
          component: (
            <TableCellWrapper>
              <TitleText title="Created by:" />
              <DescriptionText
                description={task.Creator.fullName}
                maxWidth="270px"
              />
            </TableCellWrapper>
          ),
          width: '50%',
          colSpan: isMobileDeviceLg ? 1 : 2,
        },
      ],
      task.basePathAttachments?.length > 0
        ? [
            {
              component: (
                <TableCellWrapper
                  alignItems={'flex-start'}
                  direction={isMobileDeviceLg ? 'column' : 'row'}
                >
                  <TitleText title="Photo:" />
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap="4px"
                    flexWrap={'wrap'}
                  >
                    <Attachments attachments={task.basePathAttachments} />
                  </Stack>
                </TableCellWrapper>
              ),
              width: '50%',
              colSpan: isMobileDeviceLg ? 1 : 4,
            },
          ]
        : [],
    ];
    if (isMobileDeviceLg) {
      const newData: any = [];
      data.forEach((arr) => arr.forEach((item) => newData.push([item])));
      return newData;
    }
    return data;
  }, [isMobileDeviceLg, task]);

  return (
    <Stack bgcolor={'#fff'} border="1px solid #e0e0e0" borderRadius={'6px'}>
      <Table
        sx={{
          tableLayout: 'fixed',
          width: '100%',
          borderStyle: 'hidden',
          '& tr:first-of-type td:first-of-type': {
            borderTopLeftRadius: '6px',
          },
          '& tr:first-of-type td:last-of-type': {
            borderTopRightRadius: '6px',
          },
          '& tr:last-of-type td:first-of-type': {
            borderBottomLeftRadius: '6px',
          },
          '& tr:last-of-type td:last-of-type': {
            borderBottomRightRadius: '6px',
          },
        }}
      >
        <TableBody>
          {tableData.map((row, index) => {
            if (row.length === 0) return null;
            return (
              <TableRow key={index}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    colSpan={cell.colSpan || 1}
                    key={cellIndex}
                    sx={{
                      border: `1px solid #e0e0e0`,
                      padding: '10px 12px',
                      width: cell.width,
                    }}
                  >
                    {cell.component}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default PreviewV2;
