/* eslint-disable @typescript-eslint/no-var-requires */
import { FC, useMemo, useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
const DocViewer = require('@cyntler/react-doc-viewer').default;
const { DocViewerRenderers } = require('@cyntler/react-doc-viewer');

const ChecklistAttachmentPreview: FC<any> = ({
  attachments,
  hideAttachmentPreview,
  selectedAttachment,
}) => {
  const [currentDocument, setCurrentDocument] = useState();

  const allDocuments = useMemo(
    () =>
      attachments?.map((a) => ({
        uri: `${a.url}?timestamp=${new Date().getTime()}`,
      })),
    [attachments],
  );

  useEffect(() => {
    if (allDocuments) {
      setCurrentDocument(allDocuments[selectedAttachment]);
    }
  }, [allDocuments, selectedAttachment]);

  return (
    <Box
      sx={{
        '& div#react-doc-viewer': {
          height: '100%',
          bgcolor: 'white',
        },
        '& div#proxy-renderer': {
          backgroundColor: 'white',
        },
        '& div#no-renderer': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& a': {
            marginTop: '12px',
            borderRadius: '8px',
            bgcolor: 'rgba(104,104,254,1)',
            color: 'white',
            fontSize: '16px',
          },
        },
        '& div#header-bar': {
          width: 'calc(100% - 50px)',
          paddingRight: '0px',
          '& div#doc-nav': {
            marginRight: '4px',
          },
        },
        '& #doc-nav-prev': {
          bgcolor: 'rgba(104,104,254,1)',
        },
        '& #doc-nav-next': {
          bgcolor: 'rgba(104,104,254,1)',
        },
      }}
      height="100vh"
    >
      <Stack
        borderRadius={'100px'}
        width={'32px'}
        height="32px"
        sx={{
          position: 'fixed',
          right: '12px',
          top: '9px',
          cursor: 'pointer',
        }}
        onClick={hideAttachmentPreview}
        alignItems={'center'}
        justifyContent={'center'}
        bgcolor={'#eee'}
      >
        <CloseRoundedIcon sx={{ fontSize: '24px' }} />
      </Stack>
      <DocViewer
        documents={allDocuments}
        pluginRenderers={DocViewerRenderers}
        initialActiveDocument={currentDocument}
      />
    </Box>
  );
};

export default ChecklistAttachmentPreview;
