import { Box, Stack, Typography } from '@mui/material';
import { MainContainer } from './style';
import actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import { useMemo } from 'react';
import IosSwitch from 'components/IosSwitch';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import EnvelopeIcon from 'components/Icons/envelopeIcon';
import BellIcon from 'components/Icons/bellIcon';
import selectors from 'store/selectors';

const CompletionNotification = ({
  updateRecipients,
  updateCompletionNotificationToggle,
  data,
}) => {
  const { recipients } = data?.notifications || {};

  const dispatch = useDispatch();
  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const roles = useSelector(selectors.getRoles);
  const { hashRoles } = roles;

  const openSendNotificationModal = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.MANAGE_COMPLETION_NOTIFICATION_DIALOG,
        open: true,
        data: {
          recipients,
          confirmCallback: (data) => {
            updateRecipients(data);
          },
        },
      }),
    );
  };

  const assignees = useMemo(() => {
    return recipients
      ?.map((recipient) => {
        if (recipient?.type === 'user') {
          const Assigned = usersHash?.[recipient?.id];
          return Assigned ? { Assigned } : undefined;
        }
        if (recipient?.type === 'role') {
          const Role = hashRoles?.[recipient?.id];
          return Role ? { Role } : undefined;
        }
        if (recipient?.type === 'team') {
          const Team = teamsHash?.[recipient?.id];
          return Team ? { Team } : undefined;
        }
        return undefined;
      })
      .filter(Boolean);
  }, [recipients, usersHash, hashRoles, teamsHash]);

  return (
    <MainContainer>
      <Box className="selectionBoxCnt">
        <Box>
          <Typography className="title">Completion Notification</Typography>
          <Typography className="titleInfo">
            Completion email report will be sent to the selected recipients.
          </Typography>
        </Box>
        <IosSwitch
          height={20}
          width={34}
          checked={data?.isCompletionNotificationEnabled ?? false}
          onChange={(e) => {
            updateCompletionNotificationToggle(e.target.checked);
          }}
          thumbSize={16}
        />
      </Box>

      {data?.isCompletionNotificationEnabled && (
        <Box className="selectBox">
          <Stack direction="row" alignItems="start" gap="12px">
            <div className="iconCnt">
              <BellIcon />
            </div>
            <Stack direction="column" gap="6px">
              <Typography className="title">Send Notification</Typography>
              <Stack direction="row" alignItems="center" gap="10px">
                <EnvelopeIcon
                  sx={{
                    fill: 'none',
                    '& path': {
                      stroke: assignees?.length ? '#757575' : '#6868FE',
                      strokeWidth: '1.4',
                    },
                  }}
                  className="envelopIcon"
                />
                {assignees?.length ? (
                  <>
                    <Typography
                      fontWeight="600"
                      fontSize="12px"
                      lineHeight="16.39px"
                      color="#000000"
                    >
                      Recipient(s):
                    </Typography>
                    <div onClick={openSendNotificationModal}>
                      <AssigneesView
                        type="avatars"
                        data={assignees}
                        assigneesProps={{ isMenuToShow: false }}
                      />
                    </div>
                  </>
                ) : (
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    lineHeight="17.76px"
                    color="#4E48FA"
                    sx={{ cursor: 'pointer' }}
                    onClick={openSendNotificationModal}
                  >
                    Select Recipient(s)
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </MainContainer>
  );
};

export default CompletionNotification;
