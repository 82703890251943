import {
  RequestDetailCnt,
  RequestNotificationBar,
} from 'pages/Requests/RequestDetail/requestDetail.style';
import useRequests from 'pages/Requests/useRequests';
import CustomButton from 'components/Button/CustomButton';
import TaskDetail from 'components/TaskDetailDialog/TaskDetail';
import { setDialog } from 'store/actions/dialogActions';
import React, { useContext, useEffect, useRef } from 'react';
import { RequestsContext } from 'pages/Requests/context/context';
import xeniaApi from 'api/index';
import { useDispatch, useSelector } from 'react-redux';
import RequestDetailView from 'components/RequestDetailDialog/RequestDetail';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useNotification } from 'components/NotificationV2/useNotification';
import selectors from 'store/selectors';

function RequestDetail({ data }: { data?: any }) {
  const { selectedRequest, scrollToRequest }: any = useRequests();
  const { requestsState, updateRequestsState } = useContext(RequestsContext);
  const { PERMISSIONS, hasPermission } = useCustomPermission();
  const { setMarkAllAsRead } = useNotification();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const collapsed = requestsState?.collapsed ?? [];
  const selectedRequestObj = data ? data : selectedRequest?.() || {};
  const {
    title,
    status,
    id,
    AssetId,
    CategoryId,
    LocationId,
    priority,
    description,
    attachments,
    TaskId,
  } = selectedRequestObj;
  const dispatch = useDispatch();
  const handleDeclineRequest = async () => {
    // Update local first to have write first experience
    const declinedStatus = 'Declined';
    const updatedRequests = requestsState?.requests?.map((request) => {
      if (request.id === id) {
        return { ...request, status: declinedStatus };
      }
      return request;
    });
    // Call API
    const declineRequestCall = await xeniaApi.declineRequest(
      selectedRequestObj.id,
    );

    if (data?.getRequestsData) {
      dispatch(
        setDialog({
          open: false,
          dialogId: 'requestDetails',
        }),
      );
      await data?.getRequestsData();
    } else {
      updateRequestsState({ requests: updatedRequests });
    }

    //Revert if call failed
    if (!declineRequestCall) {
      const updatedRequests = requestsState.requests.map((request) => {
        if (request.id === selectedRequestObj.id) {
          return { ...request, status: 'Pending' };
        }
        return request;
      });
      updateRequestsState({
        requests: updatedRequests,
        ...(collapsed?.includes(declinedStatus) && {
          collapsed: collapsed?.filter((item) => item !== declinedStatus),
        }),
      });
      setTimeout(() => {
        scrollToRequest();
      }, 100);
    }
  };
  const markNotificationsAsRead = async () => {
    try {
      // const postData: any = {
      //   currentUserId: userProfileData?.id,
      // };
      const postData: any = {
        TaskRequestId: id,
        currentUserId: userProfileData?.id,
      };

      await setMarkAllAsRead(postData);
    } catch (error) {
      /***/
    }
  };
  useEffect(() => {
    markNotificationsAsRead();
  }, [selectedRequest]);
  const handleAcceptRequest = () => {
    const createTaskData = {
      title,
      priority,
      ServiceId: CategoryId,
      serviceTypeId: CategoryId,
      locationIds: [LocationId],
      AssetId,
      description,
      attachment: attachments,
      requestId: id,
    };
    // Send data to mobile app web view (only if it exists) //
    if (globalThis.ReactNativeWebView) {
      globalThis.ReactNativeWebView.postMessage(JSON.stringify(createTaskData));
      return;
    }
    const acceptedStatus = 'Accepted';
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: async (res) => {
            if (data?.getRequestsData) {
              dispatch(
                setDialog({
                  open: false,
                  dialogId: 'requestDetails',
                }),
              );
              await data?.getRequestsData();
            } else {
              updateRequestsState({
                requests: requestsState?.requests?.map((request) => {
                  if (request.id === id) {
                    return {
                      ...request,
                      status: acceptedStatus,
                      TaskId: res?.[0]?.id,
                    };
                  }
                  return request;
                }),
                ...(collapsed?.includes(acceptedStatus) && {
                  collapsed: collapsed?.filter(
                    (item) => item !== acceptedStatus,
                  ),
                }),
              });
              setTimeout(() => {
                scrollToRequest();
              }, 100);
            }
          },
          dialogState: {
            viewLess: false,
          },
          taskData: createTaskData,
        },
      }),
    );
  };

  const formattedLogDataRef = useRef<any>(null);
  const currentLogDataRef = useRef<any>(null);
  const currentLogIdRef = useRef<any>(null);

  useEffect(() => {
    window.addEventListener('beforeunload', handleSaveChecklist);
    window.addEventListener('blur', handleSaveChecklist);
    return () => {
      window.removeEventListener('beforeunload', handleSaveChecklist);
      window.removeEventListener('blur', handleSaveChecklist);
      formattedLogDataRef.current = null;
      currentLogDataRef.current = null;
      currentLogIdRef.current = null;
    };
  }, []);

  useEffect(() => {
    return () => {
      handleSaveChecklist();
    };
  }, []);

  const handleSaveChecklist = async () => {
    if (
      !currentLogDataRef ||
      !formattedLogDataRef.current ||
      currentLogDataRef.current?.status === 'Submitted'
    )
      return;
    await xeniaApi.saveChecklistState({
      logId: currentLogIdRef.current,
      data: formattedLogDataRef.current,
    });
  };

  const handleChangeLogCb = (log) => {
    currentLogDataRef.current = log;
  };

  const handleChangeFormattedLogCb = (formattedData) => {
    formattedLogDataRef.current = formattedData;
  };

  const handleSetCurrentLogId = (logId) => {
    currentLogIdRef.current = logId;
  };

  const onChecklistSubmitCb: any = async () => {
    await handleSaveChecklist();
  };

  return (
    <RequestDetailCnt
      status={status}
      canCreateTask={hasPermission(PERMISSIONS.CAN_MANAGE_TASKS)}
      showMargin={!data}
    >
      <RequestNotificationBar>
        <div className={'requestTitleCnt'}>
          <span className="requestTitle" title={title}>
            {title}
          </span>
        </div>
        <HasPermission
          permissions={[PERMISSIONS.CAN_ACCEPT_DECLINE_TASK_REQUEST]}
        >
          {status !== 'Accepted' && (
            <div className={'requestActionBtnCnt'}>
              <CustomButton
                variant="outlined"
                buttonType="warning"
                onClick={handleDeclineRequest}
                disabled={status === 'Declined'}
              >
                Decline
              </CustomButton>
              <CustomButton
                variant="contained"
                buttonType="success"
                onClick={handleAcceptRequest}
              >
                Accept
              </CustomButton>
            </div>
          )}
        </HasPermission>
      </RequestNotificationBar>
      <div className={'requestDetailInfoCnt'}>
        {TaskId ? (
          <TaskDetail
            taskId={TaskId}
            request={true}
            hideHeader={true}
            successCB={null}
            options={{
              inline: data ? false : true,
            }}
            sizing={data ? 53 : 0}
            handleChangeFormattedLogCb={handleChangeFormattedLogCb}
            handleChangeLogCb={handleChangeLogCb}
            handleSetCurrentLogId={handleSetCurrentLogId}
            onChecklistSubmitCb={onChecklistSubmitCb}
            handleSaveChecklist={handleSaveChecklist}
          />
        ) : (
          <RequestDetailView
            paddingTop={data ? 100 : 0}
            requestId={id}
            request={selectedRequestObj}
          />
        )}
      </div>
      <HasPermission
        permissions={[PERMISSIONS.CAN_ACCEPT_DECLINE_TASK_REQUEST]}
      >
        {status !== 'Accepted' && (
          <div className={'requestActionBtnCntBottom'}>
            <CustomButton
              variant="outlined"
              buttonType="warning"
              onClick={handleDeclineRequest}
              disabled={status === 'Declined'}
            >
              Decline
            </CustomButton>
            <CustomButton
              variant="contained"
              buttonType="success"
              onClick={handleAcceptRequest}
            >
              Accept
            </CustomButton>
          </div>
        )}
      </HasPermission>
    </RequestDetailCnt>
  );
}

export default RequestDetail;
