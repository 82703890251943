// React
import { FC, useEffect, useMemo, useState } from 'react';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

// Redux

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';

// Hooks
import { StartIcon, TabsCnt } from './styles';

// Utils
import classNames from 'classnames';
import ComplianceIcon from 'components/Icons/sidebarIcons/complianceIcon';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import TaskIcon from 'components/Icons/taskIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import MarkCompletedIcon from 'components/Icons/markCompletedIcon';
import TickInsideSquareIcon from 'components/Icons/tickInsideSquareIcon';
import CustomDashboardIcon from 'components/Icons/sidebarIcons/customdashboardIcon';
import ApprovalReportIcon from 'components/Icons/sidebarIcons/approvalsReportIcon';

const tabs = [
  { title: 'Xenia Preset', id: 'PRESET_DASHBOARD' },
  { title: 'Custom', id: 'CUSTOM_DASHBOARD' },
];
function ConditionItems(props) {
  const { options, tab, selectedOption, setSelectedOption, onChangeCallback } =
    props;
  const [selectedTab, setSelectedTab] = useState(tab || 'PRESET_DASHBOARD');

  const handleClickItem = (values: any[]) => {
    setSelectedOption(values);
    onChangeCallback?.(
      `${selectedTab}::${values[0]?.slug || values[0]?.id}`,
      values[0],
    );
  };

  const handleSelectTab = (option) => {
    setSelectedTab(option);
  };
  const selectedItem = useMemo(() => {
    return selectedOption;
  }, [selectedOption]);

  return (
    <>
      <TabsCnt>
        {tabs.map((t) => (
          <div
            className={classNames(
              { tab: true },
              { selectedTab: t.id === selectedTab },
            )}
            onClick={() => handleSelectTab(t.id)}
          >
            {t.title}
          </div>
        ))}
      </TabsCnt>
      <SelectItemsList
        searchFieldProps={{
          autoFocus: false,
          sx: {
            background: 'rgba(242, 245, 247, 1)',
          },
        }}
        isMulti={false}
        selectedOptions={selectedItem}
        controlled={true}
        checkbox={false}
        canSearch={true}
        options={options[selectedTab] || []}
        canSelectParent={true}
        checkboxProps={{ sx: { pr: '16px' } }}
        handleChangeCallback={handleClickItem}
        listProps={{
          sx: {
            maxHeight: 250,
          },
        }}
      />
    </>
  );
}

const DashboardDropdown: FC<any> = ({
  data,
  selectedOption, // patern ==> tab::id
  onChangeCallback,
  buttonProps,
  popperProps,
}) => {
  const [itemDropdownRef, setItemDropdownRef] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<any>(null);

  const handleSelectOption = (selected, obj) => {
    onChangeCallback(selected, obj);
  };

  const dashboardImages = {
    'daily-compliance-report': <ComplianceIcon />,
    'approvals-report': <ApprovalReportIcon />,
    'task-summary': <TaskIcon />,
    'schedule-report': <ScheduledIcon />,
    'employee-productivity': <UsersAndTeamsIcon />,
    'completed-work-report': (
      <TickInsideSquareIcon
        sx={{
          '& path': {
            stroke: 'none !important',
            fill: 'currentColor !important',
          },
        }}
      />
    ),
    'template-submission': <MarkCompletedIcon />,
    'template-submissions': <MarkCompletedIcon />,
    'scheduled-work-summary-table': <ScheduledIcon />,
  };

  const transformOption = (option) => {
    return {
      id: option.id,
      value: option.name || option.title,
      label: option.name || option.title,
      slug: option.slug || null,
      startIcon: option.slug ? (
        <StartIcon className={'endIcon'}>
          {dashboardImages?.[option?.slug]}
        </StartIcon>
      ) : (
        <StartIcon className={'endIcon'}>
          <CustomDashboardIcon />
        </StartIcon>
      ),
      obj: option,
      listItemProps: {
        styleSelected: true,
        selectedStyles: {
          padding: '7px 16px',
          background: 'rgb(227, 227, 255)',
          color: '#4E48FA',
          '& .endIcon': {
            background: '#6868FE',
            borderColor: '#6868FE',
            color: '#ffffff',
          },
        },
        style: { borderRadius: 5, margin: '0px 24px' },
      },
    };
  };
  const optionList = useMemo(() => {
    if (data) {
      return {
        PRESET_DASHBOARD: data.PRESET_DASHBOARD.map((option) => ({
          ...transformOption(option),
        })),
        CUSTOM_DASHBOARD: data.CUSTOM_DASHBOARD.reduce(
          (acc, option) =>
            option.publish ? [...acc, transformOption(option)] : acc,
          [],
        ),
      };
    } else {
      return { CUSTOM_DASHBOARD: [], PRESET_DASHBOARD: [] };
    }
  }, [data]);
  useEffect(() => {
    if (selectedOption) {
      const [tab, option] = selectedOption.split('::');
      const selected =
        tab === 'CUSTOM_DASHBOARD'
          ? optionList[tab]?.filter((x) => x.id === option)
          : optionList[tab]?.filter((x) => x.slug === option) || [];
      setSelectedItem(selected);
      setSelectedTab(tab);
    }
  }, [selectedOption]);

  return (
    <>
      <CustomDropdown
        clickaway
        popperProps={{
          placement: 'bottom-start',
          open: Boolean(itemDropdownRef),
          content: (
            <ConditionItems
              options={optionList}
              tab={selectedTab}
              onChangeCallback={handleSelectOption}
              setSelectedOption={setSelectedItem}
              selectedOption={selectedItem}
            />
          ),
          anchorEl: itemDropdownRef,
          // ...popperProps,
        }}
        onClickawayCallback={() => {
          if (itemDropdownRef) {
            setItemDropdownRef(null);
          }
        }}
        buttonRenderer={
          <Stack
            p="12px 12px 12px 15px"
            width="100%"
            alignItems={'center'}
            gap="8px"
            direction={'row'}
            borderRadius={'6px'}
            height="40px"
            border={'1px solid #E0E0E0'}
            sx={{
              cursor: 'pointer',
              '& p': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              setItemDropdownRef(itemDropdownRef ? null : e.currentTarget);
            }}
            {...buttonProps}
          >
            <CustomDashboardIcon
              sx={{
                fontSize: 19,
                marginRight: '5px',
                color: '#37474F',
                '& path': {
                  stroke: 'currentColor',
                  fill: 'none',
                },
              }}
            />
            <Typography color="#212121" fontSize={'13px'} fontWeight={'500'}>
              {selectedItem?.length
                ? selectedItem[0]?.label
                : 'Select Default Dashboard'}
            </Typography>
            <KeyboardArrowDownRounded
              sx={{ marginLeft: 'auto', color: '#616161' }}
            />
          </Stack>
        }
      />
    </>
  );
};

export default DashboardDropdown;
