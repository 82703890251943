import React, { FC, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import AssigneeDropdownList from 'components/Dropdown/AssigneeDropdown/listItems/listItems';
import { Stack } from '@mui/material';

interface ListObjectProps {
  allText?: string;
  showInlineSelection?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  hideActions?: boolean;
  selected?: string[];
  placeholder?: string;
  selectedListPlaceholder?: string;
  unselectedListPlaceholder?: string;
  partition?: boolean;
  showMeOnTop?: boolean;
  isSelectAll?: boolean;
  footer?: any;
}

interface LocationsListObjectProps extends ListObjectProps {
  autoTagLocation?: any;
  addLocationByTag?: boolean;
  disableUnAuth?: boolean;
  selectOnly?: boolean;
}

interface ListProps {
  all?: ListObjectProps;
  people?: ListObjectProps;
  teams?: ListObjectProps;
  locations?: LocationsListObjectProps;
  roles?: ListObjectProps;
}

interface IProps {
  listProps?: ListProps;
  handleChangeCallback?: (data: {
    currentSelected: string | null;
    selectedList: {
      people?: string[];
      teams?: string[];
      roles?: string[];
      locations?: string[];
    };
    all: boolean;
  }) => void;
  popperProps?: any;
  buttonRenderer?: any;
  buttonRendererProps?: any;
  rootProps?: any;
  allSelected?: boolean;
  displayOptions?: any;
  showTabs?: boolean;
  blacklistedIds?: string[];
  customHeaderRenderer?: React.ReactNode;
  customFooterRenderer?: React.ReactNode;
  closeOnSelect?: boolean;
}

const AssigneeDropdown: FC<IProps> = (props) => {
  const {
    listProps = {},
    handleChangeCallback,
    popperProps = {},
    buttonRenderer,
    buttonRendererProps = {},
    rootProps = {},
    allSelected = false,
    displayOptions,
    showTabs = true,
    blacklistedIds = [],
    customHeaderRenderer = <></>,
    customFooterRenderer = <></>,
    closeOnSelect = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  //Assignee Dropdown Close
  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (data: any) => {
    handleChangeCallback?.(data);
    if (closeOnSelect) {
      onDropdownClose();
    }
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRenderer={
          <div
            onClick={handleDropDownOpen}
            style={{ width: '100%' }}
            {...rootProps}
          >
            {buttonRenderer}
          </div>
        }
        buttonRendererProps={buttonRendererProps}
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <Stack {...(popperProps.contentRootProps || {})}>
              {customHeaderRenderer}
              <AssigneeDropdownList
                listProps={listProps}
                allSelected={allSelected}
                onSelectionCallback={handleChange}
                displayOptions={displayOptions}
                showTabs={showTabs}
                blacklistedIds={blacklistedIds}
              />
              {customFooterRenderer}
            </Stack>
          ),
          disablePortal: true,
          placement: 'bottom-start',

          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 415,
          },
          ...popperProps,
        }}
      />
    </ClickAwayListener>
  );
};

export default AssigneeDropdown;
