import styled from '@mui/system/styled';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const FiltersCmp = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginBottom: 16,
});

export const BoxContainer = styled('div')({
  borderRadius: 12,
  height: 184,
  cursor: 'pointer',
  '& .imgContainer': {
    height: 140,
    backgroundColor: '#ECEFF1',
    borderRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  '& .contentContainer': {
    paddingTop: 10,
    '& .title': {
      fontSize: 13,
      lineHeight: '17.76px',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      textAlign: 'left',
      marginBottom: 0,
      color: 'black',
    },

    '& .titleContainer': {
      display: 'flex',
      alignItems: 'center',
      '& .colorIcon': {
        marginRight: 7,
      },
      '& .childIcon': {
        height: 17,
        marginRight: 3,
        color: '#757575',
      },
    },
  },
});

export const SubContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& .subAssetsCount': {
    gap: '8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',

    '& .childIcon': {
      color: '#616161',
      height: 10,
    },

    '& .count': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16.39px',
      letterSpacing: '0.37px',
      color: '#212121',
    },
  },
});

export const LocationContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'addLocation',
})(({ addLocation }) => ({
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: addLocation ? '#F5F5FF' : 'unset',
  },

  '& .icon': {
    height: 12.5,
    width: 12.5,
    '& path': {
      stroke: '#616161',
    },
  },

  '& .name': {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '15.03px',
    letterSpacing: '0.37px',
    color: '#212121',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const ArrowsContainer = styled('div')({
  background: '#616161',
  borderRadius: '3px',
  height: 16,
  width: 20,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  svg: { width: 12, height: 12 },
});

export const ImageContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  padding: '24px 40px',
  borderRight: '1px solid #E0E0E0',
  height: 'fit-content',
  '.slick-prev': {
    left: 16,
  },
  '.slick-next': {
    right: 16,
  },
  '.slick-prev, .slick-next': {
    zIndex: 1,
    borderRadius: '50%',
    width: 42,
    height: 42,
    background: 'white',
  },
  '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus':
    {
      background: 'white',
    },
  '.slick-prev:before, .slick-next:before': {
    color: 'black',
    fontFamily: 'Manrope',
    fontSize: 27,
  },
  '.slick-prev:before': {
    content: '"\\2039"',
  },
  '.slick-next:before': {
    content: '"\\203A"',
  },
  '.slick-slide': {
    height: 550,
  },
  [theme.breakpoints.down('md')]: {
    padding: '15px',
  },
}));

export const HeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
  height: 42,
});

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  height: 42,
  button: {
    color: '#212121',
    height: 42,
    width: 42,
    background: '#EEEEEE',
    '&:hover': {
      background: '#EEEEEE',
    },
  },
  [theme.breakpoints.down('md')]: {
    button: {
      height: 25,
      width: 25,
      '& svg': {
        fontSize: 18,
      },
    },
  },
}));

export const ImageBox = styled('div')({
  justifyContent: 'center',
  display: 'flex !important',
  background: 'lightgray',
  borderRadius: 16,
  overflow: 'hidden',
  img: {
    width: 'auto',
    height: 550,
    transition: 'transform 0.3s ease-in-out',
    borderRadius: 8,
  },
});

export const IconContainer = styled('div', {
  shouldForwardProp: (prop) => !['bgcolor'].includes(prop),
})(({ bgcolor }) => ({
  width: 24.5,
  height: 24.12,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const FeedbackContainer = styled(Box)(({ theme }) => ({
  margin: '0px 20px',
  padding: '20px 0px',
  position: 'relative',
  height: '100%',
  '& .separator': {
    margin: '20px 0px 10px 0px',
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: 75,
  },
}));

export const FeedbackInputContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  '& > div:first-of-type': {
    padding: '10px 0px',
  },
});

export const UserInfoCnt = styled(Stack)({
  gap: 8,
  padding: '6px 10px',
  marginLeft: -10,
});

export const UserName = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: 'rgba(0, 0, 0, 1)',
}));

export const FeedbackDateTime = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '15.03px',
  color: '#616161',
  paddingTop: 3,
}));

export const MessageBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(239, 242, 244, 1)',
  padding: '8px 12px',
  borderRadius: '12px',
  margin: '0px 0px 5px 25px',
  width: 'fit-content',

  '& .message': {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '19.12px',
    color: '#000000',
    'word-break': 'break-word',
  },

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    backgroundColor: 'transparent',
  },
}));

export const ResolveBox = styled(Box)(() => ({
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .label': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    color: 'rgba(76, 175, 80, 1)',
    paddingTop: 2,
  },

  '& .icon': {
    fontSize: 19,
    marginRight: 5,
    color: 'rgba(76, 175, 80, 1)',
  },
}));

export const UpdaterWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 3,
  alignItems: 'center',
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '13.66px',
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  marginTop: 4,
  '& > div': {
    backgroundColor: 'white',
  },
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const FeedbacksList = styled(Stack)(() => ({
  maxHeight: 500,
  overflowY: 'auto',
  padding: '0px 10px',
}));

export const FeedbackTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '10px',
  margin: '12px 0px 20px 5px',

  '& .feedbackIcon': {
    '& path:nth-of-type(1)': {
      stroke: 'black',
      fill: 'white',
    },
    '& path:nth-of-type(2)': {
      stroke: 'black',
    },
  },

  '& .feedbackLabel': {
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '27.32px',
    color: 'rgba(0, 0, 0, 1)',
  },
}));

export const FeedbackEmptyCnt = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 470,
  flexDirection: 'column',
  '& .feedbackIcon': {
    fontSize: 37,
    marginBottom: 10,
    '& path:nth-of-type(1)': {
      strokeWidth: 1,
      stroke: 'rgba(120, 144, 156, 1)',
      fill: 'white',
    },
    '& path:nth-of-type(2)': {
      stroke: 'rgba(120, 144, 156, 1)',
    },
  },
  '& .title': {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: '21.86px',
    color: 'rgba(84, 110, 122, 1)',
    marginBottom: 5,
  },
  '& .subTitle': {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '19.12px',
    color: 'rgba(84, 110, 122, 1)',
  },
}));
