/* eslint-disable @typescript-eslint/no-explicit-any */
import * as authActions from 'store/actions/authActions';
import * as departmentActions from 'store/actions/tagActions';
import * as serviceActions from 'store/actions/serviceActions';
import * as userActions from 'store/actions/userActions';
import * as propertyLayoutActions from 'store/actions/propertyLayoutActions';
import * as taskActions from 'store/actions/taskActions';
import * as requestsActions from 'store/actions/requestsActions';
import * as checklistV2Actions from 'store/actions/checklistV2Actions';
import * as messagingActions from 'store/actions/messagingActions';
import * as loaderActions from 'store/actions/loaderActions';
import * as timezoneActions from 'store/actions/timezoneActions';
import * as workspaceActions from 'store/actions/workspaceActions';
import * as dialogActions from 'store/actions/dialogActions';
import * as locationActions from 'store/actions/locationActions';
import * as topAlertActions from 'store/actions/topAlertActions';
import * as billingActions from 'store/actions/billingActions';
import * as calendlyActions from 'store/actions/calendlyActions';
import * as userConfigActions from 'store/actions/userConfigActions';
import * as assetActions from 'store/actions/assetActions';
import * as savedOptionActions from 'store/actions/savedOptionActions';
import * as sidebarConfigActions from 'store/actions/sidebarConfigActions';
import * as workspaceConfigActions from 'store/actions/workspaceConfigActions';
import * as locationsLevelsActions from 'store/actions/locationsLevelsActions';
import * as locationGroupsAction from 'store/actions/locationGroupsActions';
import * as taskTemplatesActions from 'store/actions/taskTemplatesActions';
import * as projectsActions from 'store/actions/projectsActions';
import * as schedulesActions from 'store/actions/schedulesActions';
import * as flaggedItemsCategories from 'store/actions/flaggedItemsCategoriesActions';
import * as permissionsActions from 'store/actions/permissionsActions';
import * as rolesActions from 'store/actions/rolesActions';
import * as navigationActions from 'store/actions/navigationActions';

import actionsTypes from 'store/actionTypes';
import { Action } from 'models/index.m';
import { setAppState } from 'store/actions/appStateActions';
export const setSearchText = (data: string): Action<string> => ({
  type: actionsTypes.SET_SEARCH_TEXT,
  payload: data,
});

export const setAttachmentsPreviewData = (data: string): Action<string> => ({
  type: actionsTypes.SET_ATTACHMENTS_PREVIEW_DATA,
  payload: data,
});

export const setPageTitle = (data: any): Action<any> => ({
  type: actionsTypes.SET_PAGE_TITLE,
  payload: data,
});

export const setMenuState = (data: any): Action<any> => ({
  type: actionsTypes.SET_MENU_STATE,
  payload: data,
});

export const setIsFiltersORSearch = (data: any): Action<any> => ({
  type: actionsTypes.SET_IS_FILTERS_OR_SEARCH,
  payload: data,
});

const actions = {
  ...authActions,
  ...departmentActions,
  ...serviceActions,
  ...userActions,
  ...propertyLayoutActions,
  ...taskActions,
  ...requestsActions,
  ...checklistV2Actions,
  ...messagingActions,
  ...loaderActions,
  ...timezoneActions,
  ...workspaceActions,
  ...dialogActions,
  ...locationActions,
  ...calendlyActions,
  ...topAlertActions,
  ...billingActions,
  ...userConfigActions,
  ...assetActions,
  ...setAppState,
  ...savedOptionActions,
  setSearchText,
  setIsFiltersORSearch,
  setAttachmentsPreviewData,
  setPageTitle,
  setMenuState,
  ...sidebarConfigActions,
  ...workspaceConfigActions,
  ...locationsLevelsActions,
  ...locationGroupsAction,
  ...taskTemplatesActions,
  ...projectsActions,
  ...schedulesActions,
  ...flaggedItemsCategories,
  ...permissionsActions,
  ...rolesActions,
};

export default actions;
