// MUI
import { Box, Typography, Stack } from '@mui/material';

// Custom components
import Unanswered from 'components/ChecklistReport/ChecklistLogItem/Unanswered';

// Styled Components
import {
  DetailsCnt,
  TitleCnt,
} from 'components/ChecklistReport/ChecklistLogItem/styled';
import { StepCounter } from 'components/AllDialogs/checklistVisibility/styled';
import EyeBallIcon from 'components/Icons/eyeBallIcon';
import EyeballIconSlashed from 'components/Icons/eyeballIconSlashed';

const CheckListHiddenItem = ({ info, title = '', type = 'Step' }) => {
  const { contextOrder, logic, contextTitle, action, answer } = info;
  return (
    <>
      <Box
        className="w-100"
        style={{
          borderRadius: type === 'Step' ? 8 : 16,
          border: '1px dashed #BDBDBD',
          padding: '10px 15px',
          background: '#F7F7F7',
        }}
      >
        <TitleCnt id="titleCnt" className="d-flex">
          <Box flex={1} id={'logDescription'}>
            <Box
              sx={{
                fontWeight: type === 'Step' ? 500 : 700,
                fontSize: type === 'Step' ? '14px' : '16px',
                color: 'rgba(33, 33, 33, 1)',
                letterSpacing: '0.15px',
              }}
            >
              {title}
            </Box>
          </Box>
          <DetailsCnt>
            <Unanswered
              styles={{ fontStyle: 'italic' }}
              text={`Hidden ${type}`}
            />
          </DetailsCnt>
        </TitleCnt>
        {/* condition text here */}

        <Stack
          sx={{ background: '#EBEAFF' }}
          paddingY={'5px'}
          paddingX={'16px'}
          borderRadius={'8px'}
          alignItems="center"
          direction={'row'}
          marginTop={'10px'}
          flex={1}
          justifyContent={'space-between'}
        >
          <Stack
            direction="row"
            alignItems="center"
            borderRadius="5px"
            paddingY="2px"
            paddingX="2px"
            spacing={1}
            border="1px solid transparent"
          >
            <Typography
              fontWeight={600}
              fontSize="12px"
              color="#424242"
              sx={{
                '& svg': {
                  fontSize: '18px',
                  fill: 'none',
                  transform: 'translateY(-1px)',
                  marginRight: '4px',
                  color: '#4E48FA',
                },
              }}
            >
              {action === 'Hide' ? <EyeBallIcon /> : <EyeballIconSlashed />}
              {action} this {type} If{' '}
              <span style={{ color: '#4E48FA', fontWeight: 700 }}>
                "{contextTitle}"
              </span>{' '}
              {logic}
            </Typography>
            {answer}
          </Stack>
          <Stack gap="8px" alignItems="center" direction={'row'}>
            <Typography
              fontWeight={600}
              fontSize="12px"
              color="#424242"
              whiteSpace={'nowrap'}
            >
              Conditional to
              <StepCounter style={{ marginLeft: '4px' }}>
                {contextOrder}
              </StepCounter>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default CheckListHiddenItem;
