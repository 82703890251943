// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const NotesAndSectionWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['borderonhover', 'procedure', 'error'].includes(prop),
})(({ borderonhover, procedure, error, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: error && !procedure ? '1px solid rgba(239, 83, 80, 1)' : 'none',
  borderRadius: 8,

  '&:hover': {
    '& .giveFeedback': {
      display: 'block',
    },
    ...(borderonhover &&
      !error && {
        border: `1px solid ${
          error && !procedure ? 'rgba(239, 83, 80, 1)' : '#4E48FA'
        }`,
        ...(!procedure && {
          '& .section-wrapper': {
            padding: '7px 11px',
            position: 'relative',
          },
        }),
        '& .notes-wrapper': {
          padding: '8px 11px 7px 11px',
        },
        '& .procedure-item-type': {
          padding: '9px',
        },
        '& .corrective-tasks': {
          margin: '8px 15px',
        },
      }),

    '& div.completed-info': {
      display: 'none',
    },
    '& div.corrective-task': {
      display: 'flex',
    },
  },

  [theme.breakpoints.down('md')]: {
    border: 'none',
    background: '#fff',
  },
}));

export const SectionWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['rounded', 'border'].includes(prop),
})(({ rounded, border, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: rounded ? '6px' : '6px 6px 0px 0px',
  gap: 8,

  '& .giveFeedback': {
    position: 'absolute',
    right: 10,
    bottom: 15,
    '& .innerCnt': {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 4,
      alignItems: 'center',
      background: 'rgba(236, 239, 241, 1)',
      width: 'fit-content',
      padding: '4px 8px',
      color: 'white',
      cursor: 'pointer',
      borderRadius: 10,
      backgroundColor: '#6868FE',
      height: 34,
      '& .feedbackIcon': {
        fontSize: 19,
        fill: 'none',
        marginRight: 1,
      },

      '& .title': {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '17.76px',
        color: 'white',
      },
    },
  },
  ...(border && {
    padding: '8px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  }),

  [theme.breakpoints.down('md')]: {
    '& .giveFeedback': {
      position: 'relative',
      right: 'auto',
      bottom: 'auto',
      '& .innerCnt': {
        margin: 'auto',
        background: 'transparent',
        '& .feedbackIcon': {
          fill: '#4E48FA',
        },
        '& .title': {
          color: '#000000',
        },
      },
    },
  },
}));

export const AttachmentsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 8,
  [theme.breakpoints.down('md')]: {
    padding: '8px',
  },
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const NotesWrapper = styled(Box)(({ theme }) => ({
  padding: '8px 12px',
  borderRadius: '0px 0px 6px 6px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderTop: 'none',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    background: '#fff',
    border: 'none',
  },
}));
