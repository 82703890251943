// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/Button/CustomButton';

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 650,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const HeaderWrapper = styled(Stack)({
  borderBottom: '1px solid #d3d3d35c',
  padding: '15px 20px',
  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '27.32px',
    letterSpacing: '-0.25px',
    color: '#000000DE',
  },

  '& .closeIcon': {
    '& svg': {
      fontSize: 20,
      '& path': {
        fill: '#616161',
      },
    },
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  borderTop: '1px solid #d3d3d35c',
  '& .resetToDefault': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
  },
  '& .saveBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    borderRadius: 9,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
    marginRight: 12,
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '15px 20px',
  maxHeight: 400,
  overflow: 'auto',
  '& .parentIcon': {
    color: '#9a9a9a',
    fontSize: 28,
    margin: '7px 8px 0px -6px',
  },
  '& .childIcon': {
    color: '#9a9a9a',
    fontSize: 18,
    margin: '10px 12px 0px 0px',
  },
});

export const InfoWrapperCnt = styled(Stack)({
  padding: '15px 20px',
});

export const AddNewLocationLevel = styled(CustomButton)({
  marginLeft: 45,
  width: 'fit-content',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '0.4px',
  color: '#6868FE',
});

export const InfoWrapper = styled(Stack)({
  backgroundColor: '#E1F5FE',
  padding: '8px 6px 8px 6px',
  borderRadius: '6px',
  gap: '8px',

  '& .infoIcon': {
    color: '#0288D1',
    height: 20,
    width: 20,
  },

  '& .infoText': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },
});

export const SiteLocationBox = styled(Stack)({
  borderRadius: 6,
  padding: '0px 12px 0px 12px',
  border: '1px solid rgba(0,0,0,0.23)',
  height: 40,
  width: 115,
  marginLeft: 8,
  cursor: 'pointer',

  '& .radioIcon': {
    border: '1px solid rgba(189, 189, 189, 1)',
    borderRadius: '50%',
    height: 16,
    width: 16,
  },

  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17.76px',
  },
});
