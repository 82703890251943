import { FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import selectors from 'store/selectors';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import useResponsive from 'utils/CustomHooks/useResponsive';
import {
  ContentWrapper,
  CustomModalWrapper,
  FooterWrapper,
  HeaderWrapper,
} from './style';
import { updateProfileApi } from 'api/userApi';
import CloseIcon from 'components/Icons/closeIcon';
import MapIcon from 'components/Icons/mapIcon';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

const UpdateLocationDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.UPDATE_LOCATION_DIALOG];

  const { open, data } = modalState || {};
  const { user, userId } = data || {};

  const [isSaving, setIsSaving] = useState(false);
  const [askAgain, setAskAgain] = useState(false);

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.UPDATE_LOCATION_DIALOG));
  };

  // Save Handler
  const saveHandler = async () => {
    setIsSaving(true);
    const postObj = {
      ...user,
      askAgain,
    };
    const response = await updateProfileApi(userId, postObj, '');

    if (response) {
      handleClose();
      window.location.reload();
    }

    setIsSaving(false);
  };

  const askAgainHandler = (event) => {
    setAskAgain(event?.target?.checked);
  };

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={open}
      fullScreen={isMobileDeviceSm}
    >
      <HeaderWrapper direction="row" alignItems="center" justifyContent="end">
        <IconButton className={'closeIcon'} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </HeaderWrapper>

      <ContentWrapper gap="16px">
        <MapIcon style={{ width: 172, height: 172, fill: 'none' }} />
        <Stack gap="8px">
          <Typography className="title">New time Zone detected</Typography>
          <Stack marginBottom={3}>
            <Typography className="statement">
              Looks like your current location is in a different time zone.
            </Typography>
            <Typography className="statement">
              Would you like to update your current time zone to&nbsp;
              <Typography className="timezone">{user?.timezone}</Typography>
              &nbsp;?
            </Typography>
          </Stack>
        </Stack>
      </ContentWrapper>

      <FooterWrapper>
        <FormControlLabel
          control={
            <CustomCheckbox
              sx={{ padding: '0 6px 0 0' }}
              disableRipple
              checked={askAgain}
              onChange={askAgainHandler}
            />
          }
          sx={{
            margin: '12px 0 0',
            '& .MuiFormControlLabel-label': {
              fontWeight: 600,
              fontSize: 14,
              lineHeight: '24px',
              color: '#212121',
            },
          }}
          label={'Don’t ask again'}
        />
        <CustomButton
          variant={'contained'}
          color={'primary'}
          onClick={saveHandler}
          buttonLoader={isSaving}
          className="saveBtn"
        >
          Update Time Zone
        </CustomButton>

        <CustomButton
          variant={'outlined'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Keep Current Time Zone
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default UpdateLocationDialog;
