const DIALOGS = {
  DOWNLOAD_MOBILE_APP_DIALOG: 'DOWNLOAD_MOBILE_APP_DIALOG',
  UPSELL_MODAL: 'UPSELL_MODAL',
  PLAY_VIDEO: 'PLAY_VIDEO',
  SEARCH_TEMPLATES: 'SEARCH_TEMPLATES',
  TEMPLATE_LIBRARY: 'TEMPLATE_LIBRARY',
  SEARCH_DOCUMENTS: 'SEARCH_DOCUMENTS',
  TEMPLATE_PREVIEW: 'TEMPLATE_PREVIEW',
  CREATE_WIDGET_DIALOG: 'CREATE_WIDGET_DIALOG',
  CONFIRMATION: 'CONFIRMATION',
  CREATE_TASK: 'createEditTask',
  INVITE_USERS: 'inviteUserDialog',
  ATTACH_CHECKLIST: 'ATTACH_CHECKLIST',
  CHECKLIST_CREATE_OPTIONS_MODAL: 'CHECKLIST_CREATE_OPTIONS_MODAL',
  DOWNGRADE_MODAL: 'DOWNGRADE_MODAL',
  SHOW_MORE_TASKS: 'SHOW_MORE_TASKS',
  TASK_TABLE_DIALOG: 'TASK_TABLE_DIALOG',
  BILLING: {
    COUNTER: 'BILLING_COUNTER',
    PAYMENT_SUCCESS: 'BILLING_PAYMENT_SUCCESS',
    PLANS_MODAL: 'PLANS_MODAL',
  },
  TEMPLATE_SETTINGS: 'TEMPLATE_SETTINGS',
  GALLERY_DETAILS_MODAL: 'GALLERY_DETAILS_MODAL',
  GALLERY_DETAILS_MODAL_REVIEW_MODE: 'GALLERY_DETAILS_MODAL_REVIEW_MODE',
  SCHEDULE_WORK_ORDER_ALERT: 'SCHEDULE_WORK_ORDER_ALERT',
  TASK_COST: 'TASK_COST',
  QR_CODE: 'QR_CODE',
  CREATE_EDIT_REQUEST: 'CREATE_EDIT_REQUEST',
  TASK_SCHEDULES_DRAWER: 'TASK_SCHEDULES_DRAWER',
  SAVED_OPTIONS: 'SAVED_OPTIONS',
  TEMPLATE_SHARING: 'TEMPLATE_SHARING',
  CHECKLIST_SUBMIT_CHECK: 'CHECKLIST_SUBMIT_CHECK',
  MARK_AS_COMPLETE: 'MARK_AS_COMPLETE',
  CREATE_FOLDER_DIALOG: 'CREATE_FOLDER_DIALOG',
  CREATE_WIDGET_SELECT_TYPE_DIALOG: 'CREATE_WIDGET_SELECT_TYPE_DIALOG',
  EDIT_WIDGET_DIALOG: 'EDIT_WIDGET_DIALOG',
  TEMPLATE_SUBMISSIONS_DIALOG: 'TEMPLATE_SUBMISSIONS_DIALOG',
  RENAME_FOLDER_DIALOG: 'RENAME_FOLDER_DIALOG',
  FOLDER_COLOR_DIALOG: 'FOLDER_COLOR_DIALOG',
  MOVE_TO_FOLDER_DIALOG: 'MOVE_TO_FOLDER_DIALOG',
  START_TEMPLATE_CHECK: 'START_TEMPLATE_CHECK',
  ARCHIEVE_CHECK: 'ARCHIEVE_CHECK',
  UNARCHIVE_CHECK: 'UNARCHIVE_CHECK',
  SERIES_ARCHIVE_CHECK: 'SERIES_ARCHIVE_CHECK',
  ASSIGNEES: 'ASSIGNEES',
  AUTOMATION: 'AUTOMATION',
  VISIBILITY: 'VISIBILITY',
  NOTIFICATION_AUTOMATION: 'NOTIFICATION_AUTOMATION',
  TEMPLATE_COPY: 'TEMPLATE_COPY',
  ADD_TO_FOLDER_DIALOG: 'ADD_TO_FOLDER_DIALOG',
  CREATE_TEMPLATE_DIALOG: 'CREATE_TEMPLATE_DIALOG',
  ARCHIVE_FOLDER_DIALOG: 'ARCHIVE_FOLDER_DIALOG',

  CREATE_DOCUMENT_DIALOG: 'CREATE_DOCUMENT_DIALOG',
  CREATE_DOCUMENT_FOLDER_DIALOG: 'CREATE_DOCUMENT_FOLDER_DIALOG',
  RENAME_DOCUMENT_FOLDER_DIALOG: 'RENAME_DOCUMENT_FOLDER_DIALOG',
  ARCHIVE_DOCUMENT_FOLDER_DIALOG: 'ARCHIVE_DOCUMENT_FOLDER_DIALOG',
  ACCESS_DOCUMENT_DIALOG: 'ACCESS_DOCUMENT_DIALOG',
  ACCESS_DASHBOARD_DIALOG: 'ACCESS_DASHBOARD_DIALOG',
  DOCUMENT_ADD_TO_FOLDER_DIALOG: 'DOCUMENT_ADD_TO_FOLDER_DIALOG',
  RENAME_DOCUMENT_DIALOG: 'RENAME_DOCUMENT_DIALOG',
  DOCUMENT_COPY: 'DOCUMENT_COPY',
  DOCUMENT_FOLDER_COLOR_DIALOG: 'DOCUMENT_FOLDER_COLOR_DIALOG',
  DOCUMENT_MOVE_TO_FOLDER_DIALOG: 'DOCUMENT_MOVE_TO_FOLDER_DIALOG',

  SUBMISSION_SHARE_DIALOG: 'SUBMISSION_SHARE_DIALOG',
  CUSTOMIZE_SIDEBAR_DIALOG: 'CUSTOMIZE_SIDEBAR_DIALOG',
  INSTRUCTION_STEP: 'INSTRUCTION_STEP_DIALOG',

  ADD_SELECT_USERS_DIALOG: 'ADD_SELECT_USERS_DIALOG',
  TASK_DETAIL: 'taskDetail',

  CREATE_ASSET: 'createAsset',

  FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG:
    'FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG',

  CUSTOMIZE_LOCATION_HIERARCY: 'CUSTOMIZE_LOCATION_HIERARCY',

  FILE_UPLOAD_TEMPLATE_MOB: 'FILE_UPLOAD_TEMPLATE_MOB',
  CREATE_EDIT_ASSET_SERVICE: 'CREATE_EDIT_ASSET_SERVICE',

  ASSET_SERVICE_DETAIL: 'ASSET_SERVICE_DETAIL',
  ASSET_SERVICE_MARK_COMPLETE: 'ASSET_SERVICE_MARK_COMPLETE',
  ASSET_SERVICE_COMPLETED: 'ASSET_SERVICE_COMPLETED',

  CREATE_EDIT_TASKS_TEMPLATE_DIALOG: 'CREATE_EDIT_TASKS_TEMPLATE_DIALOG',
  ATTACH_TASK_TEMPLATE_MODAL: 'ATTACH_TASK_TEMPLATE_MODAL',
  TASK_TEMPLATE_DETAILS: 'TASK_TEMPLATE_DETAILS',

  REQUEST_FORM_FIELDS: 'REQUEST_FORM_FIELDS',
  CREATE_EDIT_PIN_CODE: 'CREATE_EDIT_PIN_CODE',
  KIOSK_MODE_CONFIGURATIONS: 'KIOSK_MODE_CONFIGURATIONS',

  CREATE_PROJECT: 'CREATE_PROJECT',
  PROJECT_ACCESS_CONTROL: 'PROJECT_ACCESS_CONTROL',

  FLAGGED_ITEMS_REPORT_DIALOG: 'FLAGGED_ITEMS_REPORT_DIALOG',
  FLAGGED_ITEMS_REPORT_CHART_BASED_DIALOG:
    'FLAGGED_ITEMS_REPORT_CHART_BASED_DIALOG',
  FLAGGED_CATEGORY_SELECTION_AUTOMATION:
    'FLAGGED_CATEGORY_SELECTION_AUTOMATION',

  CUSTOM_ROLES_SET_DETAILS: 'CUSTOM_ROLES_SET_DETAILS',
  CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE: 'CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE',
  CUSTOM_ROLES_DELETE_ROLE: 'CUSTOM_ROLES_DELETE_ROLE',
  CUSTOM_ROLES_DELETE_USER: 'CUSTOM_ROLES_DELETE_USER',

  CREATE_TEMPLATE_WITH_AI: 'CREATE_TEMPLATE_WITH_AI',
  CONVERT_DOCUMENT_WITH_AI: 'CONVERT_DOCUMENT_WITH_AI',

  WORKFLOW_BUILDER_DIALOG: 'WORKFLOW_BUILDER_DIALOG',
  MANAGE_BUILDER_STEP_DIALOG: 'MANAGE_BUILDER_STEP_DIALOG',
  MANAGE_COMPLETION_NOTIFICATION_DIALOG:
    'MANAGE_COMPLETION_NOTIFICATION_DIALOG',
  SUBMISSION_REVIEW_MODE_DIALOG: 'SUBMISSION_REVIEW_MODE_DIALOG',
  SUBMISSION_APPROVAL_DIALOG: 'SUBMISSION_APPROVAL_DIALOG',

  SUBMISSION_APPROVAL_SUBMITTED_DIALOG: 'SUBMISSION_APPROVAL_SUBMITTED_DIALOG',

  UPDATE_LOCATION_DIALOG: 'UPDATE_LOCATION_DIALOG',
};

export default DIALOGS;
