import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const ComplianceIcon = (props: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.875 11.9166V8.58764C17.875 5.40359 17.875 3.81157 16.9353 2.82241C15.9955 1.83325 14.4831 1.83325 11.4583 1.83325H8.70829C5.68345 1.83325 4.17102 1.83325 3.23133 2.82241C2.29163 3.81157 2.29163 5.40359 2.29163 8.58764V13.4122C2.29163 16.5963 2.29163 18.1882 3.23133 19.1774C4.17102 20.1666 5.68345 20.1666 8.70829 20.1666H10.0833"
        stroke="#F4511E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.375 18.3334C12.375 18.3334 13.2917 18.3334 14.2083 20.1667C14.2083 20.1667 17.1201 15.5834 19.7083 14.6667"
        stroke="#F4511E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.41663 1.83325L6.49198 2.28535C6.67489 3.38282 6.76634 3.93156 7.15099 4.2574C7.53565 4.58325 8.09194 4.58325 9.20458 4.58325H10.962C12.0747 4.58325 12.631 4.58325 13.0156 4.2574C13.4003 3.93156 13.4917 3.38282 13.6746 2.28535L13.75 1.83325"
        stroke="#F4511E"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M6.41663 14.6666H10.0833M6.41663 10.0833H13.75"
        stroke="#F4511E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </>,
    'ComplianceIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default ComplianceIcon;
