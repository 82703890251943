// React
import React from 'react';

// MUI
import { Divider, Stack, Typography } from '@mui/material';

// Styled
import { Content } from './styled';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import usePatchTemplate from 'components/TemplateDashboard/TemplateSettingsModal/hooks/usePatchTemplate';
import { RepeatTaskSwitch as CustomSwitch } from 'components/AllDialogs/tasks/createTask/createTask.style';

interface TemplateInfoPropTypes {
  template: TemplateType;
  updateTemplateCb: (template: TemplateType) => void;
}

const Reporting = ({ template, updateTemplateCb }: TemplateInfoPropTypes) => {
  const { FEATURES, hasFeature } = useCustomPermission();

  const {
    toggleShowTimestamp,
    toggleScoring,
    toggleShowScoreInProgress,
    toggleShowScoreInSteps,
    toggleShowStepAction,
    toggleAllowOnlyBluetoothTemperatureResponses,
  } = usePatchTemplate({
    updateTemplateCb,
  });

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Configurations</RightHeaderTextBold>
          <RightHeaderTextNormal>
            Configure your submission reports
          </RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        <Content>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            marginTop="8px"
          >
            <CustomSwitch
              defaultChecked={template?.isShowTimestamp}
              onChange={(e) => toggleShowTimestamp(e.target.checked)}
            />
            <Stack>
              <Typography fontSize="13px" fontWeight={500} color="#212121">
                Show Timestamp
              </Typography>
              <Typography fontSize="13px" color="#616161" fontWeight={400}>
                Timestamp will appear against every completed step in the inbox
                and report view
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          {/*<Stack*/}
          {/*  direction="row"*/}
          {/*  alignItems="center"*/}
          {/*  spacing={2}*/}
          {/*  marginTop="8px"*/}
          {/*>*/}
          {/*  <CustomSwitch*/}
          {/*    defaultChecked={template?.isScoring}*/}
          {/*    onChange={(e) => toggleScoring(e.target.checked)}*/}
          {/*  />*/}
          {/*  <Stack>*/}
          {/*    <Typography fontSize="13px" fontWeight={500} color="#212121">*/}
          {/*      Weighted Scoring*/}
          {/*    </Typography>*/}
          {/*    <Typography fontSize="13px" color="#616161" fontWeight={400}>*/}
          {/*      Allocate a weighted value to each step based on its significance*/}
          {/*    </Typography>*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}
          {template?.isScoring && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              marginTop="8px"
            >
              <CustomSwitch
                defaultChecked={template?.isShowScoreInProgress}
                onChange={(e) => toggleShowScoreInProgress(e.target.checked)}
              />
              <Stack>
                <Typography fontSize="13px" fontWeight={500} color="#212121">
                  Show Score in Submission In Progress View
                </Typography>
                <Typography fontSize="13px" color="#616161" fontWeight={400}>
                  Allow users working on a submission to see the score as they
                  complete each section
                </Typography>
              </Stack>
            </Stack>
          )}

          {template?.isScoring && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              marginTop="8px"
            >
              <CustomSwitch
                defaultChecked={template?.isShowScoreInSteps}
                onChange={(e) => toggleShowScoreInSteps(e.target.checked)}
              />
              <Stack>
                <Typography fontSize="13px" fontWeight={500} color="#212121">
                  Show Scoring by Step
                </Typography>
                <Typography fontSize="13px" color="#616161" fontWeight={400}>
                  Score will appear against each step
                </Typography>
              </Stack>

              <Divider />
            </Stack>
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            marginTop="8px"
          >
            <CustomSwitch
              defaultChecked={template?.isShowCorrectiveActionInSteps}
              onChange={(e) => toggleShowStepAction(e.target.checked)}
            />
            <Stack>
              <Typography fontSize="13px" fontWeight={500} color="#212121">
                Show Step Actions
              </Typography>
              <Typography fontSize="13px" color="#616161" fontWeight={400}>
                “Add Note”, “Attach File”, and “Create Corrective Task” buttons
                will appear for each step.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            marginTop="8px"
          >
            <CustomSwitch
              defaultChecked={
                template?.isAllowOnlyBluetoothTemperatureResponses
              }
              onChange={(e) =>
                toggleAllowOnlyBluetoothTemperatureResponses(e.target.checked)
              }
            />
            <Stack>
              <Typography fontSize="13px" fontWeight={500} color="#212121">
                Allow Only Bluetooth Temperature Responses
              </Typography>
              <Typography fontSize="13px" color="#616161" fontWeight={400}>
                This will only allow bluetooth-based temperature responses and
                will disable manual entry of recording values
              </Typography>
            </Stack>
          </Stack>
        </Content>
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default Reporting;
